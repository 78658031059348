import { Button, Grid, Header, Icon, Input, Segment, Popup } from "semantic-ui-react";
import classNames from "classnames";
import styles from "./RecommendationSidebar.module.css";
import React, { useState } from "react";
import { getMachineryInformationData } from "../../../utils";
import { Formik, Field } from "formik";
import { chunk } from "lodash";

const MachineryInformationForm = ({ currentTask, onEditTask }) => {
  const [isEditing, setIsEditing] = useState(false);
  const machineryInformationData = getMachineryInformationData(currentTask);
  const chunkedData = chunk(
    machineryInformationData,
    machineryInformationData.length / 2
  );
  const onSubmit = values => {
    onEditTask(values).then(() => {
      setIsEditing(false);
    });
  };

  return (
    <Segment>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header
              size="big"
              className={classNames("bold", styles.editableHeader)}
            >
              Machinery
              {!isEditing && (
                <Popup
                  inverted
                  position="bottom center"
                  content={"Edit"}
                  size="tiny"
                  trigger={
                    <div className={styles.editButtonWrapper}>
                      <Button
                        onClick={() => {
                          setIsEditing(true);
                        }}
                        className={styles.editButton}
                        floated="right"
                        icon={<Icon name="edit" />}
                        size="small"
                      />
                    </div>
                  }
                />
              )}
            </Header>
          </Grid.Column>
        </Grid.Row>
        {!isEditing ? (
          <Grid.Row columns={2}>
            {chunkedData.map(chunk => (
              <Grid.Column>
                {chunk.map(item => (
                  <Segment fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Header size="small" className="bold" floated="left">
                      {item.title}
                    </Header>
                    <Header size="small" floated="right">
                      {item.value || "—"}
                    </Header>
                  </Segment>
                ))}
              </Grid.Column>
            ))}
          </Grid.Row>
        ) : (
          <Formik initialValues={currentTask} onSubmit={onSubmit}>
            {props => (
              <>
                {machineryInformationData.map(
                  ({ title, value, key, options, ...rest }) => (
                    <Grid.Row className="py-10" verticalAlign="middle">
                      <Grid.Column width={4} className="bold">
                        {title}
                      </Grid.Column>
                      <Grid.Column width={12}>
                        <Field
                          fluid
                          as={rest.as || Input}
                          action={rest.action}
                          type={rest.type}
                          name={key}
                          disabled={rest.disabled}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  )
                )}
                <Grid.Row className={styles.btnWrapper}>
                    <Button
                      onClick={() => {
                        setIsEditing(false);
                      }}
                      disabled={props.isSubmitting}
                      className={styles.cancelBtn}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={!props.dirty || props.isSubmitting}
                      onClick={props.handleSubmit}
                      primary
                    >
                      Save
                    </Button>
                </Grid.Row>
              </>
            )}
          </Formik>
        )}
      </Grid>
    </Segment>
  );
};

export default MachineryInformationForm;
