import React, { useState } from "react";
import classNames from "classnames";
import {
  Button,
  Container,
  Dropdown,
  Grid,
  Header,
  Icon,
  Input,
  Message,
  Modal
} from "semantic-ui-react";
import { getDropdownStylesByTaskStatus } from "../../../utils";
import { RECOMMENDATION_STATUSES } from "constants/Recommendations/types";
import { Link } from "react-router-dom";
import { printRecommendationsList } from "utils/recommendations";
import styles from "./RecommendationSidebar.module.css";

const shownStatusesList = Object.entries(RECOMMENDATION_STATUSES)
  .filter(([_, value]) => value !== RECOMMENDATION_STATUSES.DRAFT)
  .map(([key, value], index) => ({
    key: key,
    text: value,
    value: key,
    className: index
  }));

const SidebarHeader = ({ currentTask, onClose, onEditTask }) => {
  const [rejectReasonModalShown, setIsRejectModalShown] = useState(false);
  const [rejectReason, setRejectReason] = useState("");

  const changeTaskStatus = (_, { value }) => {
    if (RECOMMENDATION_STATUSES[value] === RECOMMENDATION_STATUSES.REJECTED) {
      setIsRejectModalShown(true);
    } else {
      onEditTask({ ...currentTask, status: value });
    }
  };

  const onNoClick = () => {
    onEditTask({ ...currentTask, status: "REJECTED", rejectReason: null });
    setRejectReason("");
    setIsRejectModalShown(false);
  };

  const onSubmitClick = () => {
    onEditTask({ ...currentTask, status: "REJECTED", rejectReason });
    setRejectReason("");
    setIsRejectModalShown(false);
  };

  const onPrintClick = () => {
    printRecommendationsList([currentTask]);
  };

  return (
    <>
      <Modal size={"mini"} open={rejectReasonModalShown} closeIcon>
        <Modal.Header>Suggest a reason?</Modal.Header>
        <Modal.Content>
          <p>Comment</p>
          <Input fluid onChange={(_, { value }) => setRejectReason(value)} />
          <Container fluid className={styles.buttonsContainer}>
            <Button className={styles.cancelBtn} onClick={onNoClick}>
              No
            </Button>
            <Button primary positive onClick={onSubmitClick}>
              Submit
            </Button>
          </Container>
        </Modal.Content>
      </Modal>

      <Grid.Row verticalAlign="middle" className="py-0">
        <Grid.Column width={3}>
          <Header as="h1">RT-{currentTask.id}</Header>
        </Grid.Column>
        <Grid.Column width={13} className={styles.buttonsWrapper}>
          <Dropdown
            icon={null}
            options={shownStatusesList}
            value={currentTask.status}
            onChange={changeTaskStatus}
            trigger={
              <Button
                style={getDropdownStylesByTaskStatus(currentTask.status)}
                className={classNames(styles.dropDownButton)}
              >
                {RECOMMENDATION_STATUSES[currentTask.status]}
                <Icon name="chevron down" />
              </Button>
            }
          />
          <Button
            onClick={onPrintClick}
           primary
            className={styles.button}
          >
            <Icon name="print" /> Print
          </Button>
          <Button
            className={styles.button}
            primary
            as={Link}
            to={`/recommendations/edit/${currentTask.id}`}
          >
            <Icon name="edit" /> Edit
          </Button>
          <Icon
            name="close"
            size="large"
            className={styles.closeIcon}
            color="grey"
            onClick={onClose}
            link
          />
        </Grid.Column>
      </Grid.Row>
      {RECOMMENDATION_STATUSES[currentTask.status] ===
        RECOMMENDATION_STATUSES.REJECTED && (
        <Grid.Row verticalAlign="middle" className="py-0">
          <Grid.Column>
            <Message color="red">
              The recommendation has been rejected
              {currentTask.rejectReason ? (
                <>
                  {" "}
                  because of <b>"{currentTask.rejectReason}"</b>
                </>
              ) : (
                <>
                  <br />
                  <span
                    className={styles.typeReasonButton}
                    onClick={() => setIsRejectModalShown(true)}
                  >
                    Type The reason
                  </span>
                </>
              )}
            </Message>
          </Grid.Column>
        </Grid.Row>
      )}
    </>
  );
};

export default SidebarHeader;
