import PropTypes from "prop-types";
import React from "react";
import { Grid, Header } from "semantic-ui-react";
import Logo from "../../Logo";
import moment from "moment";
import _ from "lodash";

const collectPickedBinsRows = pickedBins => {
  const pickedBinsRows = [];

  Object.values(pickedBins).forEach(farmStat => {
    const { farmName } = farmStat;
    const temp = {
      farmName,
      blockName: "-",
      patchName: "-",
      numberOfBins: 0,
      cost: 0,
      costRatioToBins: 0
    };

    Object.values(farmStat.blocksStat).forEach(blockStat => {
      const areas = Object.values(blockStat.areasStat);

      if (areas.length === 0) {
        pickedBinsRows.push({
          ...temp,
          blockName: blockStat.blockName,
          numberOfBins: blockStat.bins,
          cost: _.round(blockStat.cost, 2),
          costRatioToBins: _.round(blockStat.cost / blockStat.bins, 2)
        });
      }

      areas.forEach(areaStat => {
        pickedBinsRows.push({
          ...temp,
          blockName: blockStat.blockName,
          areaName: areaStat.areaName,
          numberOfBins: areaStat.bins,
          cost: _.round(areaStat.cost, 2),
          costRatioToBins: _.round(areaStat.cost / areaStat.bins, 2)
        });
      });
    });
  });

  return pickedBinsRows;
};

const PrintReport = ({
  pickedBins,
  totalBinsCount,
  totalCostCount,
  totalCostRatioToBinsCount,
  startDate,
  endDate
}) => {
  const pickedBinsRows = collectPickedBinsRows(pickedBins);

  const dateString = `${
    startDate ? moment(startDate).format("DD/MM/YYYY") : "..."
  } - ${endDate ? moment(endDate).format("DD/MM/YYYY") : "..."}`;

  return (
    <Grid>
      <Grid.Column>
        <Grid.Row className={"print-page-header-row"}>
          <div>
            <Header style={{color:'#FFF'}} as="h1">Bins Picked</Header>
            <strong>{dateString}</strong>
          </div>
          <Logo />
        </Grid.Row>
        <Grid.Row>
          <table style={{ marginTop: 20 }} className={"print-page-table"}>
            <thead>
              <tr className="header-left">
                <th>Farm</th>
                <th>Block</th>
                <th>Patch</th>
                <th>Number of bins</th>
                <th>Cost($)</th>
                <th>Cost/Bin($/Bin)</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(pickedBinsRows).map((row, i) => (
                <tr key={`block-stat-${i}`} style={{ marginTop: 10 }}>
                  <td>{row.farmName}</td>
                  <td>{row.blockName}</td>
                  <td>{row.patchName}</td>
                  <td>{row.numberOfBins}</td>
                  <td>{row.cost}</td>
                  <td>{row.costRatioToBins}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td />
                <td />
                <td />
                <td>{totalBinsCount}</td>
                <td>{_.round(totalCostCount, 2)}</td>
                <td>{_.round(totalCostRatioToBinsCount, 2)}</td>
              </tr>
            </tfoot>
          </table>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  );
};

PrintReport.propTypes = {
  endDate: PropTypes.any,
  pickedBins: PropTypes.object,
  startDate: PropTypes.any,
  totalBinsCount: PropTypes.number,
  totalCostCount: PropTypes.number,
  totalCostRatioToBins: PropTypes.number
};

PrintReport.defaultProps = {
  pickedBins: {},
  totalBinsCount: 0,
  totalCostCount: 0,
  totalCostRatioToBins: 0
};

export default PrintReport;
