import React, { Fragment } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Logo from "../../../../../../components/Logo";
import classNames from "classnames";
import { Header, Table, TableBody, TableRow } from "semantic-ui-react";
import Numeric from "../../../../../../components/Numeric/Numeric";
import { pickBy } from "lodash";
import {
  minutesToHM,
  dateRangeToString
} from "../../../../../../utils/timeUtils";
import styles from "./PrintReports.module.css";

const headerColumn = {
  contractors: "Contractors",
  numberOfUnits: "No. units",
  totalPrice: "Total price ($)"
};

const secondHeaderColumn = {
  contractors: "Date",
  empty: "Farm",
  empty1: "Workers",
  empty5: "Crop",
  totalPrice: "Variety"
};
const thirdHeaderColumn = {
  empty2: "Block",
  numberOfUnits: "Patch",
  empty3: "Row",
  empty4: "Quantity",
  empty6: "Barcode Id",
  empty7: "Bin unit",
  empty8: "Price ($)"
};
class PrintReports extends React.Component {
  render() {
    const {
      tableData,
      data,
      employeeIds,
      farmIds,
      contractorIds,
      blockIds,
      patchIds,
      cropIds,
      varietyIds,
      binUnitsIds,
      selectedItem,
      employees,
      from,
      to,
      workersList,
      contractorsList,
      farmsList,
      blockList,
      patchList,
      cropsList,
      varietiesList,
      binUnitsList
    } = this.props;

    const totalNumberOfBins = tableData?.reduce(
      (prev, curr) => prev + +curr.numberOfUnits,
      0
    );
    const allTotalPrice = tableData?.reduce(
      (prev, curr) => prev + +curr.totalPrice,
      0
    );

    const headerNewData = pickBy(headerColumn, function (value, key) {
      return (
        selectedItem &&
        selectedItem.find(item => {
          return value === item;
        })
      );
    });
    const secondHeaderNewData = pickBy(secondHeaderColumn, function (
      value,
      key
    ) {
      return (
        selectedItem &&
        selectedItem.find(item => {
          return value === item;
        })
      );
    });
    const thirdHeaderNewData = pickBy(thirdHeaderColumn, function (value, key) {
      return (
        selectedItem &&
        selectedItem.find(item => {
          return value === item;
        })
      );
    });

    const workers = [];
    const contractors = [];

    if (workersList && employeeIds && employeeIds.length) {
      const workersIdsList = employeeIds && employeeIds.map(Number);
      workers.push(
        workersList.options &&
        workersList.options
          .filter(({ value }) => workersIdsList.includes(value))
          .map(({ label }) => label)
      );
    }
    if (contractorsList && contractorIds && contractorIds.length) {
      const contracorsList = contractorIds && contractorIds.map(Number);
      contractors.push(
        contractorsList.options &&
        contractorsList.options
          .filter(({ value }) => contracorsList.includes(value))
          .map(({ label }) => label)
      );
    }

    return (
      <div className={styles.reportWrapper}>
        <div>
          <div className={styles.reportHeaderRow}>
            <div className={styles.reportHeader}>
              Pick to pack - Picker report
            </div>
            <div className={styles.logoContainet}>
              <Logo width={140} height={60} />
            </div>
          </div>
          <Table columns={3} basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className={styles.reportSubHeader}>
                  Date Range
                </Table.HeaderCell>

                <Table.HeaderCell className={styles.reportSubHeader}>
                  Workers
                </Table.HeaderCell>

                <Table.HeaderCell className={styles.reportSubHeader}>
                  Contractors
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell className={styles.reportSubBody}>
                  <div className={styles.dateRow}>
                    {from && to
                      ? `${moment(from).format("DD/MM/YY")} - ${moment(
                        to
                      ).format("DD/MM/YY")}`
                      : from && !to
                        ? `${moment(from).format("DD/MM/YY")}`
                        : to && !from
                          ? `${moment(to).format("DD/MM/YY")}`
                          : "-"}
                  </div>
                </Table.Cell>
                <Table.Cell className={styles.reportSubBody}>
                  {workers.length ? workers.join(",") : "-"}
                </Table.Cell>
                <Table.Cell className={styles.reportSubBody}>
                  {!!contractors.length ? contractors.join(",") : "-"}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <div className={styles.totalRowTitle}>Total</div>
        <Table columns={2} basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="totalHeaderCell">
                No. units
              </Table.HeaderCell>
              <Table.HeaderCell className="totalHeaderCell">
                Total price ($)
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                {totalNumberOfBins ? totalNumberOfBins.toFixed(2) : "-"}
              </Table.Cell>
              <Table.Cell>{allTotalPrice ? allTotalPrice : "-"}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        {tableData.length > 0 ? (
          tableData.map(item => (
            <>
              <Table
                className={styles.firstTable}
                columns={Object.keys(headerNewData).length}
                basic="very"
              >
                <Table.Header
                  fullWidth={true}
                  className={styles.blockHeaderTable}
                >
                  <Table.Row>
                    {headerNewData &&
                      Object.keys(headerNewData).map(head => {
                        return (
                          <Table.HeaderCell
                            as="td"
                            className={styles.blockHeaderCellTable}
                          >
                            <div className={styles.tableHeader}>
                              {" "}
                              {headerNewData[`${head}`]}
                            </div>
                          </Table.HeaderCell>
                        );
                      })}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    {headerNewData &&
                      Object.keys(headerNewData).map(head => {
                        return (
                          <>
                            <Table.Cell
                              as="td"
                              className={styles.blockHeaderValueCellTable}
                            >
                              <div className={styles.tableValue}>
                                {item[`${head}`] || "-"}
                              </div>
                            </Table.Cell>
                          </>
                        );
                      })}
                  </Table.Row>
                </Table.Body>
              </Table>

              {item.subRows.length &&
                item.subRows.map(
                  (block, index) =>
                    index !== 0 && (
                      <>
                        <Table
                          columns={Object.keys(secondHeaderNewData).length}
                          className={styles.secondTable}
                          basic="very"
                        >
                          <Table.Header
                            fullWidth={true}
                            className={styles.blockHeaderTable}
                          >
                            <Table.Row>
                              {secondHeaderNewData &&
                                Object.keys(secondHeaderNewData).map(head => {
                                  return (
                                    <Table.HeaderCell
                                      as="td"
                                      className={styles.secondHeaderCellTable}
                                    >
                                      <div className={styles.secondTableHeader}>
                                        {" "}
                                        {secondHeaderNewData[`${head}`]}
                                      </div>
                                    </Table.HeaderCell>
                                  );
                                })}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row>
                              {secondHeaderNewData &&
                                Object.keys(secondHeaderNewData).map(head => {
                                  return (
                                    <>
                                      {head !== "empty1" ? (
                                        <Table.Cell
                                          as="td"
                                          className={
                                            styles.secondHeaderValueCellTable
                                          }
                                        >
                                          <div
                                            className={styles.secondTableValue}
                                          >
                                            {block[`${head}`] || "-"}
                                          </div>
                                        </Table.Cell>
                                      ) : (
                                        <Table.Cell
                                          as="td"
                                          className={
                                            styles.secondHeaderValueCellTable
                                          }
                                        >
                                          <div
                                            className={styles.secondTableValue}
                                          >
                                            {employees
                                              .filter(employee =>
                                                block[`${head}`].some(
                                                  i => i === employee.id
                                                )
                                              )
                                              .map(
                                                e =>
                                                  `${e.firstName}${e.lastName}`
                                              )
                                              .join(",") || "-"}
                                          </div>
                                        </Table.Cell>
                                      )}
                                    </>
                                  );
                                })}
                            </Table.Row>
                          </Table.Body>
                        </Table>

                        <Table
                          columns={Object.keys(thirdHeaderNewData).length}
                          className={styles.thirdTable}
                          basic="very"
                        >
                          <Table.Header
                            fullWidth={true}
                            className={styles.blockHeaderTable}
                          >
                            <Table.Row className={styles.tirdTableHeaderRow}>
                              {thirdHeaderNewData &&
                                Object.keys(thirdHeaderNewData).map(head => {
                                  return (
                                    <Table.HeaderCell
                                      as="td"
                                      className={styles.tirdHeaderCellTable}
                                    >
                                      <div className={styles.tirdTableHeader}>
                                        {" "}
                                        {thirdHeaderNewData[`${head}`]}
                                      </div>
                                    </Table.HeaderCell>
                                  );
                                })}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row className={styles.tirdTableValueRow}>
                              {thirdHeaderNewData &&
                                Object.keys(thirdHeaderNewData).map(head => {
                                  return (
                                    <>
                                      <Table.Cell
                                        as="td"
                                        className={
                                          styles.tirdHeaderValueCellTable
                                        }
                                      >
                                        <div className={styles.tirdTableValue}>
                                          {block[`${head}`] || "-"}
                                        </div>
                                      </Table.Cell>
                                    </>
                                  );
                                })}
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </>
                    )
                )}
            </>
          ))
        ) : (
          <Header as="h4">Nothing found</Header>
        )}
      </div>
    );
  }
}

PrintReports.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  showExpandedData: PropTypes.bool,
  startDate: PropTypes.any,
  endDate: PropTypes.any
};

export default PrintReports;
