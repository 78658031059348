import React, { useState } from "react";
import { get, isEmpty } from "lodash";
import { Modal, Button, Confirm } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import GeneralInformation from "./GeneralInformation";
import {
  createPurchase,
  setActiveItemId,
  getPurchaseHistory,
  updatePurchase
} from "actions/PurchaseHistory";
import { PURCHASE_INITIAL_VALUES } from "constants/PurchaseHistory";
import styles from "./PurchaseHistoryModal.module.css";
import Product from "./NewProduct";
import moment from "moment";

const PurchaseModal = ({ open, onClose, purchaseHistory, filtersList }) => {
  const [secondOpen, setSecondOpen] = useState(false);
  const dispatch = useDispatch();

  const REQUIRED_FIELDS = [
    "orderDate",
    "deliveryDate",
    "supplier",
    "products",
    "orderNumber"
  ];

  const validateValues = async values => {
    const errors = {};
    const { products } = values;
    REQUIRED_FIELDS.forEach(filedName => {
      if (!get(values, filedName)) {
        errors[filedName] = "Required field";
      }
    });
    if (isEmpty(products)) {
      errors.products = true;
    }
    return errors;
  };
  const onSubmit = values => {
    if (purchaseHistory) {
      onClose();
      dispatch(updatePurchase(values.id, values, filtersList));
      dispatch(setActiveItemId(null));
    } else {
      dispatch(createPurchase(values)).then(onClose);
    }
  };
  return (
    <Modal
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      size="large"
      onClick={e => e.stopPropagation()}
      className={styles.root}
    >
      <Modal.Header>
        {!!purchaseHistory
          ? `Edit the purchase receipt`
          : " Add a purchase receipt"}
      </Modal.Header>
      <Formik
        enableReinitialize
        validate={validateValues}
        validateOnChange={true}
        onSubmit={onSubmit}
        initialValues={purchaseHistory || PURCHASE_INITIAL_VALUES}
      >
        {props => {
          const checker =
            !!props.values.orderNumber &&
            !!props.values.supplier &&
            !!props.values.deliveryDate &&
            !!props.values.orderDate &&
            !!props.values.products?.length &&
              props.values.products?.every(item => {
                return (
                  !!item.quantities.length &&
                  !!item.chemicalId &&
                  !!item.totalCost
                );
              });
          return (
            <>
              <Modal.Content scrolling className={styles.modalContentContainer}>
                <GeneralInformation />
                <Product />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content="Cancel"
                  color="green"
                  basic
                  onClick={() => {
                    props.dirty ? setSecondOpen(true) : onClose();
                  }}
                />
                <Button
                  disabled={!checker}
                  content="Save"
                  color="green"
                  onClick={props.submitForm}
                />
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
      <Confirm
        className={styles.confirmModal}
        content="Are you sure you want to close modal window"
        open={secondOpen}
        onCancel={() => setSecondOpen(false)}
        onConfirm={() => {
          onClose();
          dispatch(setActiveItemId(null));
          setSecondOpen(false);
        }}
      />
    </Modal>
  );
};

export default PurchaseModal;
