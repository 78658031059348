import * as React from "react";
import { Icon } from "semantic-ui-react";

export const LogoIcon = props => {
  return (
    <Icon {...props}>
      <svg width="136" height="131" viewBox="0 0 136 131" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M63.2224 54.6558L35.4199 27.2981L20.2129 42.2619L63.2224 84.5834V54.6558Z" fill="url(#paint0_radial_20777_354223)" />
        <path d="M93.5801 20.4505L74.8221 1.99243C74.5519 1.72658 74.2432 1.52402 73.9473 1.29614V39.7568L93.5801 20.4505Z" fill="url(#paint1_radial_20777_354223)" />
        <path d="M63.2305 0.5C62.4971 0.905111 61.7895 1.38618 61.1591 1.99385L43.0059 19.8694L63.2305 39.7705V0.5Z" fill="url(#paint2_radial_20777_354223)" />
        <path d="M123.93 50.304L73.9473 99.4876V129.707C74.2432 129.492 74.5519 129.276 74.8221 129.011L132.55 72.2182C136.307 68.5215 136.307 62.4701 132.55 58.7861L123.93 50.304Z" fill="url(#paint3_radial_20777_354223)" />
        <path d="M12.6524 49.7183L3.44072 58.7826C-0.303155 62.4793 -0.303155 68.518 3.44072 72.2146L61.1684 129.006C61.7859 129.627 62.5064 130.095 63.2397 130.5V99.4837L12.6524 49.7183Z" fill="url(#paint4_radial_20777_354223)" />
        <path d="M73.9473 84.5733L116.352 42.8461L101.158 27.8821L73.9473 54.6579V84.5733Z" fill="url(#paint5_radial_20777_354223)" />
        <defs>
          <radialGradient id="paint0_radial_20777_354223" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(68.1203 132.452) scale(74.9618 73.7627)">
            <stop stop-color="#6EC179" />
            <stop offset="1" stop-color="#00A8B5" />
          </radialGradient>
          <radialGradient id="paint1_radial_20777_354223" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(68.1396 132.467) scale(74.9619 73.7635)">
            <stop stop-color="#6EC179" />
            <stop offset="1" stop-color="#00A8B5" />
          </radialGradient>
          <radialGradient id="paint2_radial_20777_354223" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(68.1284 132.468) scale(74.9624 73.7633)">
            <stop stop-color="#6EC179" />
            <stop offset="1" stop-color="#00A8B5" />
          </radialGradient>
          <radialGradient id="paint3_radial_20777_354223" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(68.1398 132.455) scale(74.9634 73.7651)">
            <stop stop-color="#6EC179" />
            <stop offset="1" stop-color="#00A8B5" />
          </radialGradient>
          <radialGradient id="paint4_radial_20777_354223" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(68.1248 132.452) scale(74.9648 73.7657)">
            <stop stop-color="#6EC179" />
            <stop offset="1" stop-color="#00A8B5" />
          </radialGradient>
          <radialGradient id="paint5_radial_20777_354223" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(68.1398 132.454) scale(74.962 73.7642)">
            <stop stop-color="#6EC179" />
            <stop offset="1" stop-color="#00A8B5" />
          </radialGradient>
        </defs>
      </svg>
    </Icon>
  );
};
