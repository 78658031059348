import React, { useEffect, useState, useCallback } from "react";
import QRCode from "qrcode";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

import { generateBarcodeSeries } from "../../../utils/employeesBarcodes";
import {
  infoMessageType,
  messagesIds,
  putMessage
} from "../../../actions/InfoMessages";

const EmployeesBarcodesPDF = ({
  employeeBarcodes,
  onClick,
  onPDFshow,
  actions: { putMessage }
}) => {
  const [generatePdfInProgress, setGeneratePdfInProgress] = useState(false);

  const generateBarcodesWithQr = useCallback(async () => {
    const barcodes = generateBarcodeSeries(
      employeeBarcodes.employee,
      employeeBarcodes.firstBarcode,
      employeeBarcodes.lastBarcode
    );

    const batchSize = 50;
    const barcodesWithQr = [];

    for (let i = 0; i < barcodes.length; i += batchSize) {
      const batch = barcodes.slice(i, i + batchSize);
      const qrBatch = await Promise.all(
        batch.map(async barcode => ({
          ...barcode,
          qr: await QRCode.toDataURL(barcode.barcode, { type: "png" })
        }))
      );
      barcodesWithQr.push(...qrBatch);

      // Даем основному потоку обработать другие события
      await new Promise(resolve => setTimeout(resolve, 0));
    }

    return barcodesWithQr;
  }, [employeeBarcodes]);

  const generatePDF = useCallback(
    async barcodesWithQr => {
      try {
        const pdfDoc = await PDFDocument.create();
        const pageWidth = 284;
        const pageHeight = 113;
        const qrSize = 57;
        const sectionWidth = 71;

        for (
          let pageOffset = 0;
          pageOffset < barcodesWithQr.length;
          pageOffset += 4
        ) {
          const page = pdfDoc.addPage([pageWidth, pageHeight]);

          for (let itemOffset = 0; itemOffset < 4; itemOffset++) {
            const i = pageOffset + itemOffset;
            if (i < barcodesWithQr.length) {
              const x = sectionWidth * itemOffset;
              const y = pageHeight - qrSize - 20;

              const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
              const qrData = barcodesWithQr[i]?.qr?.split(",")[1];
              if (!qrData) continue;

              const qrImageBytes = Uint8Array.from(atob(qrData), c =>
                c.charCodeAt(0)
              );

              const qrImageEmbed = await pdfDoc.embedPng(qrImageBytes);

              page.drawImage(qrImageEmbed, {
                x: x + (sectionWidth - qrSize) / 2,
                y,
                width: qrSize,
                height: qrSize
              });

              const textX = x + sectionWidth / 2;
              const fullName = `${barcodesWithQr[i].employee.firstName} ${
                barcodesWithQr[i].employee.lastName
                  ? barcodesWithQr[i].employee.lastName[0]
                  : ""
              }`;

              page.drawText(barcodesWithQr[i].numberPart, {
                x:
                  textX -
                  font.widthOfTextAtSize(barcodesWithQr[i].numberPart, 6) / 2,
                y: pageHeight - 80,
                size: 6,
                font,
                color: rgb(0, 0, 0)
              });

              page.drawText(barcodesWithQr[i].employeePart, {
                x:
                  textX -
                  font.widthOfTextAtSize(barcodesWithQr[i].employeePart, 6) / 2,
                y: pageHeight - 90,
                size: 6,
                font,
                color: rgb(0, 0, 0)
              });

              page.drawText(fullName, {
                x: textX - font.widthOfTextAtSize(fullName, 6) / 2,
                y: pageHeight - 100,
                size: 6,
                font,
                color: rgb(0, 0, 0)
              });
            }
          }
        }

        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);

        // Открываем PDF только один раз
        window.open(url, "_blank", "noopener,noreferrer");
        onPDFshow();
      } catch (error) {
        putMessage({
          type: infoMessageType.ERROR,
          messageId: messagesIds.GENERIC,
          extraData: {
            message: "Error generating PDF"
          }
        });
      } finally {
        setGeneratePdfInProgress(false);
      }
    },
    [onPDFshow, putMessage]
  );

  const handleGeneratePdf = useCallback(async () => {
    setGeneratePdfInProgress(true);

    try {
      const barcodesWithQr = await generateBarcodesWithQr();
      await generatePDF(barcodesWithQr);
    } catch (error) {
      putMessage({
        type: infoMessageType.ERROR,
        messageId: messagesIds.GENERIC,
        extraData: {
          message: "Error generating barcodes"
        }
      });
    }
  }, [generateBarcodesWithQr, generatePDF, putMessage]);

  return (
    <Button
      primary
      size="large"
      type="button"
      loading={generatePdfInProgress}
      disabled={generatePdfInProgress}
      onClick={handleGeneratePdf}
    >
      PDF
    </Button>
  );
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      putMessage
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeesBarcodesPDF);
