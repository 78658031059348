import React, { useState, useCallback, useEffect } from "react";
import { get, omit, xor, isEmpty } from "lodash";
import {
  Grid,
  Checkbox,
  Form,
  Input,
  Table,
  Segment,
  Icon,
  Button
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, useFormikContext } from "formik";
import DatePicker from "components/DatePicker/DatePicker";
import moment from "moment";
import {
  blockSelector,
  cropsOptionSelector,
  varietiesOptionSelector
} from "selectors/areas";
import { farmsOptionsFilterSelector, farmsWithAreas } from "selectors/farms";
import filterTypes from "components/Table/filterTypes";
import Filters from "components/Table/components/Filters";
import styles from "./FirstStep.module.css";

const filtersOptions = [
  {
    filterId: "farmIds",
    title: "Farm",
    type: filterTypes.MultiSelect,
    selector: farmsOptionsFilterSelector
  },
  {
    filterId: "blockIds",
    title: "Block",
    type: filterTypes.MultiSelect,
    selector: state => blockSelector({})(state)
  },
  {
    filterId: "cropIds",
    title: "Crop",
    type: filterTypes.MultiSelect,
    selector: cropsOptionSelector
  },
  {
    filterId: "varietyIds",
    title: "Variety",
    type: filterTypes.MultiSelect,
    selector: state => varietiesOptionSelector({})(state)
  }
];

const AddModalFirstStep = ({
  open,
  onClose,
  shed,
  areas,
  setValues,
  changeFieldValue,
  changeBlockNumbers,
  formValues,
  setSelectedBlock,
  setSelectedPatch
}) => {
  const dispatch = useDispatch();
  const [scanDate, setScanDate] = useState();
  const [search, setSearch] = useState("");
  const [selectedFilters, setSelectedFilters] = useState({});
  const [openTable, toggleOpenTable] = useState(false);
  const [newData, setNewData] = useState(areas);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [expandedItem, setExpandedItem] = useState([]);

  const onFilterChange = useCallback(
    (filterKey, value) => {
      const currentFilterElement = filtersOptions.find(
        ({ filterId }) => filterId === filterKey
      );
      if (value === null) {
        setSelectedFilters(filters => omit(filters, filterKey));
      } else if (currentFilterElement.type === filterTypes.MultiSelect) {
        setSelectedFilters(filters => {
          const newValue = xor(filters[filterKey], [value]);
          if (!newValue.length) {
            delete filters[filterKey];
            return { ...filters };
          } else {
            return { ...filters, [filterKey]: newValue };
          }
        });
      } else {
        setSelectedFilters(filters => ({ ...filters, [filterKey]: value }));
      }
    },
    [filtersOptions, setSelectedFilters]
  );

  const clearFilters = useCallback(() => {
    setSelectedFilters({});
  }, [selectedFilters]);

  useEffect(() => {
    const { farmIds, blockIds, cropIds, varietyIds } = selectedFilters;
    const filteredData = !isEmpty(selectedFilters)
      ? areas.filter((item, index) => {
        return (
          (!farmIds?.length || farmIds.includes(item.farm.id)) &&
          (!blockIds?.length || blockIds.includes(item.id)) &&
          (!cropIds?.length ||
            item.varieties.some(v => {
              return cropIds.some(id => id === v.variety.crop.id);
            })) &&
          (!varietyIds?.length ||
            item.varieties.some(v =>
              varietyIds.some(id => id === v.variety.id)
            ))
        );
      })
      : areas;
    setNewData(filteredData);
  }, [selectedFilters]);

  const onSearchChange = useCallback((_, { value: search }) => {
    setSearch(search);
  }, []);

  const searchData = search
    ? areas.filter(item =>
      item.name
        .toUpperCase()
        .replace(/\s/g, "")
        .includes(search.toUpperCase().replace(/\s/g, ""))
    )
    : areas;
  const dataList = searchData.map(item => ({
    ...item,
    checked: !!selectedItem.find(block => block.id === item.id),
    expanded: !!expandedItem.find(block => block.id === item.id),
    children:
      item.children.map(patch => ({
        ...patch,
        checked: !!selectedItem.find(item => patch.id === item.id),
        expanded: !!expandedItem.find(block => patch.id === item.id)
      })) || []
  }));
  const onSelectAll = () => {
    setSelectAll(!selectAll);
    const newData = !selectAll
      ? dataList
        .map(item => {
          return [
            {
              id: item.id,
              type: item.type,
              hasSubAreas: !!item.children.length
            },
            ...item.children
              .map(ch => [
                {
                  id: ch.id,
                  type: ch.type
                }
              ])
              .flat()
          ];
        })
        .flat()
      : [];
    let uniqueObjArray = [
      ...new Map(newData.map(item => [item["id"], item])).values()
    ];
    setSelectedItem(uniqueObjArray);
    return !selectAll
      ? changeFieldValue(
        "areaIds",
        areas
          .map(item =>
            item.children.length
              ? item.children.map(patch => patch.id)
              : item.id
          )
          .flat()
      )
      : changeFieldValue("areaIds", []);
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={{
        areasIds: [],
        scanDate: ""
      }}
    >
      {({ values, setFieldValue }) => (
        <>
          <Form className={styles.firstStepForm}>
            <Form.Group className={styles.dateRow}>
              <Form.Field className={styles.scanDateField} width={8} required>
                <h2>Select date </h2>
                <label>Scan date</label>
                <DatePicker
                  name="scanDate"
                  trigger={false}
                  focused={false}
                  value={formValues.scanDate ? moment(formValues.scanDate) : ""}
                  onChange={scanDate => {
                    return (
                      setScanDate(scanDate),
                      setFieldValue("scanDate", scanDate),
                      changeFieldValue(
                        "scanDate",
                        moment(scanDate)
                          .add(1, "days")
                          .utc()
                          .format("YYYY-MM-DDTHH:mm:ss")
                      )
                    );
                  }}
                  disableClear
                />
              </Form.Field>
              <div className={styles.horizontalLine} />
            </Form.Group>
            <Form.Group className={styles.tableGroup}>
              <h2>Select areas </h2>
              <div className={styles.tableWrapper}>
                <div className={styles.filtersContainer}>
                  <div className={styles.searchAndFiltersContainer}>
                    <Input
                      value={search}
                      className={styles.searchInput}
                      placeholder="Search"
                      onChange={onSearchChange}
                    />

                    <div className={styles.filterContainer}>
                      <Filters
                        filterChange={() => { }}
                        options={filtersOptions}
                        selectedFilters={selectedFilters}
                        onFilterChange={onFilterChange}
                        clearFilters={clearFilters}
                      />
                      <Button
                        primary
                        onClick={() => onSelectAll()}
                      >
                        Select All
                      </Button>
                    </div>
                  </div>
                  {(search.length > 0
                    ? dataList.map(i => {
                      return {
                        ...i,
                        expanded: true,
                        children: i.children.map(block => {
                          return {
                            ...block,
                            expanded: true
                          };
                        })
                      };
                    })
                    : dataList
                  )?.map((block, index) => {
                    return (
                      <Grid
                        className={styles.rowContainer}
                        container
                        as={Segment}
                      >
                        <div className={styles.icomNameContainer}>
                          <Grid.Column className={styles.chevronContainer}>
                            <div
                              className={`${block.expanded
                                  ? styles.openTable
                                  : styles.toggleIcon
                                }`}
                            >
                              <div className={styles.iconNameWrapper}>
                                <Checkbox
                                  indeterminate={
                                    !!block.children.some(c => c.checked) &&
                                    !block.children.every(c => c.checked)
                                  }
                                  checked={
                                    block.checked ||
                                    (block.children.length &&
                                      block.children.every(
                                        block => block.checked
                                      ))
                                  }
                                  onChange={() => {
                                    let selectedItemInBlock = [...selectedItem];
                                    const blockIndex = selectedItemInBlock.findIndex(
                                      i =>
                                        i.type === "BLOCK" && i.id === block.id
                                    );

                                    if (blockIndex > -1) {
                                      selectedItemInBlock = selectedItemInBlock.filter(
                                        i =>
                                          i.type === "BLOCK"
                                            ? i.id !== block.id
                                            : block.children.every(
                                              patch => patch.id !== i.id
                                            )
                                      );
                                    } else {
                                      selectedItemInBlock.push({
                                        id: block.id,
                                        type: "BLOCK",
                                        hasSubAreas: !!block.children.length
                                      });
                                    }

                                    block.children.map((patch, patchIndex) => {
                                      if (!block.checked) {
                                        selectedItemInBlock.push({
                                          type: "PATCH",
                                          id: patch.id
                                        });
                                      }
                                    });
                                    setSelectedItem(selectedItemInBlock);

                                    let uniqueObjArray = [
                                      ...new Map(
                                        selectedItemInBlock.map(item => [
                                          item["id"],
                                          item
                                        ])
                                      ).values()
                                    ]
                                      .filter(i => !i.hasSubAreas)
                                      .map(item => item.id);

                                    changeFieldValue("areaIds", uniqueObjArray);
                                    changeBlockNumbers(uniqueObjArray);
                                  }}
                                />
                                <span className={styles.blockName}>
                                  {" "}
                                  {block.name}
                                </span>
                              </div>
                              <Icon
                                className={
                                  block.expanded
                                    ? "tuf-chevron-down"
                                    : "tuf-chevron-right"
                                }
                                onClick={() => {
                                  let expandedItemInBlock = [...expandedItem];
                                  const blockIndex = expandedItemInBlock.findIndex(
                                    i => i.type === "BLOCK" && i.id === block.id
                                  );
                                  if (blockIndex > -1) {
                                    expandedItemInBlock = expandedItemInBlock.filter(
                                      i =>
                                        i.type === "BLOCK"
                                          ? i.id !== block.id
                                          : i
                                    );
                                  } else {
                                    expandedItemInBlock.push({
                                      type: "BLOCK",
                                      id: block.id
                                    });
                                  }
                                  return setExpandedItem(expandedItemInBlock);
                                }}
                              />
                            </div>
                          </Grid.Column>
                        </div>
                        {block.expanded &&
                          block.children.map(patch => {
                            return (
                              patch.parent &&
                              patch.parent?.id === block.id && (
                                <Table
                                  size="large"
                                  basic="very"
                                  className={`${openTable
                                      ? styles.openTable
                                      : styles.closeTable
                                    }`}
                                >
                                  <Table.Body className={styles.tbody}>
                                    <Table.Row className={styles.patchRow}>
                                      <Table.Cell className={styles.patchCell}>
                                        <div>
                                          <Checkbox
                                            checked={patch.checked}
                                            onChange={() => {
                                              let selectedItemInPatch = [
                                                ...selectedItem
                                              ];
                                              const patchIndex = selectedItemInPatch.findIndex(
                                                i =>
                                                  i.type === "PATCH" &&
                                                  i.id === patch.id
                                              );
                                              if (patchIndex > -1) {
                                                selectedItemInPatch = selectedItemInPatch.filter(
                                                  i =>
                                                    i.type === "PATCH"
                                                      ? i.id !== patch.id
                                                      : i.id !== block.id
                                                );
                                              } else {
                                                selectedItemInPatch.push({
                                                  id: patch.id,
                                                  type: "PATCH"
                                                });
                                              }

                                              if (
                                                block.children.every(item =>
                                                  selectedItemInPatch.some(
                                                    i => i.id === item.id
                                                  )
                                                )
                                              ) {
                                                selectedItemInPatch.push({
                                                  id: block.id,
                                                  type: "BLOCK",
                                                  hasSubAreas: true
                                                });
                                              }

                                              setSelectedItem(
                                                selectedItemInPatch
                                              );

                                              let uniqueObjArray = [
                                                ...new Map(
                                                  selectedItemInPatch.map(
                                                    item => [item["id"], item]
                                                  )
                                                ).values()
                                              ]
                                                .filter(i => !i.hasSubAreas)
                                                .map(item => item.id);

                                              changeFieldValue(
                                                "areaIds",
                                                uniqueObjArray
                                              );
                                              changeBlockNumbers(
                                                uniqueObjArray
                                              );

                                              const filteredValues = block.children
                                                .map((pt, areaIndex) => {
                                                  const newDataFormater = [
                                                    pt.id === patch.id
                                                      ? {
                                                        ...pt,
                                                        checked: pt.checked
                                                          ? false
                                                          : true
                                                      }
                                                      : pt
                                                  ];
                                                  return newDataFormater;
                                                })
                                                .flat();
                                              const areasList = newData.map(
                                                (area, areaIndex) => {
                                                  return area.id === block.id
                                                    ? patch.checked !== true
                                                      ? {
                                                        ...area,
                                                        children: filteredValues
                                                      }
                                                      : {
                                                        ...area,
                                                        checked: false,
                                                        children: filteredValues
                                                      }
                                                    : area;
                                                }
                                              );
                                              return setNewData(areasList);
                                            }}
                                          />
                                          <span className={styles.patchName}>
                                            {" "}
                                            {patch.name}
                                          </span>
                                        </div>
                                      </Table.Cell>
                                    </Table.Row>
                                  </Table.Body>
                                </Table>
                              )
                            );
                          })}
                      </Grid>
                    );
                  })}
                </div>
                <div className={styles.tableContainer}></div>
              </div>
            </Form.Group>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default AddModalFirstStep;
