import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from "react";
import { useDispatch } from "react-redux";
import styles from "./SignatureGenerator.module.css";

const SignatureGenerator = forwardRef(
  ({ signatureUrl, signatureDelete, onSignatureChange, activeTab }, ref) => {
    const dispatch = useDispatch();
    const [text, setText] = useState("");

    const canvasRef = useRef(null);

    const renderTextOnCanvas = inputText => {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.font = "48px 'Pacifico', cursive";
      context.fillStyle = "black";
      context.textAlign = "center";
      context.fillText(inputText, canvas.width / 2, canvas.height / 2);
    };

    const clearSignature = () => {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
      dispatch(signatureDelete());
      setText("");
      onSignatureChange(true);
    };

    const getSignatureData = () => {
      const canvas = canvasRef.current;
      return canvas ? canvas.toDataURL("image/png") : null;
    };
    useImperativeHandle(ref, () => ({
      clearSignature,
      getSignatureData
    }));

    useEffect(() => {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      if (signatureUrl) {
        const image = new Image();
        image.src = signatureUrl;
        image.crossOrigin = "anonymous";
        image.onload = () => {
          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
        };
      } else {
        context.clearRect(0, 0, canvas.width, canvas.height);
      }
    }, [signatureUrl]);

    const handleTextChange = e => {
      const inputValue = e.target.value;
      if (inputValue) {
        onSignatureChange(false);
      }
      setText(inputValue);
      renderTextOnCanvas(inputValue);
    };

    return (
      <div>
        <div className={styles.wrapper}>
          <input
            className={styles.input}
            type="text"
            value={text}
            onChange={handleTextChange}
            placeholder="Enter your signature here"
          />
          <button
            disabled={!signatureUrl && !text}
            className={styles.padClearButton}
            onClick={clearSignature}
          >
            Clear
          </button>
        </div>
        <canvas
          ref={canvasRef}
          className={styles.pad}
          width={398}
          height={200}
        ></canvas>
      </div>
    );
  }
);

export default SignatureGenerator;
