import React, { useState } from "react";
import { Button, Form, Modal } from "semantic-ui-react";
import { Formik, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { updateQC } from "actions/QualityControl";
import { employeeList } from "selectors/employee";
import { getEmployeeList } from "actions/SprayDiary/employee";
import { workersFilterOptions } from "selectors/harvestUnits";
import CustomSlider from "../../../components/Tabs/Workers/CustoSlider/CustomSLider";
import SingleSelect from "components/Table/components/Filters/SingleSelect";
import styles from "./Workers.module.css";

const WorkersAdd = ({
  onChangeField,
  activeItem,
  setAddCheck,
  activeRounds
}) => {
  const employees = useSelector(employeeList);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [activeValue, setActiveValue] = useState(null);

  const [sliderValue, setSliderValue] = useState(0);

  const onAddWorker = (activeItem, values, activeRounds) => {
    onChangeField(
      "qcRounds",
      activeItem?.qcRounds.map(item => {
        return item.roundNumber === activeRounds?.roundNumber
          ? {
              ...item,
              employeeResults: [...activeRounds?.employeeResults, values]
            }
          : item;
      })
    );
  };

  const onAddAllWorker = (activeItem, values, activeRoundsd) => {
    onChangeField(
      "qcRounds",
      activeItem?.qcRounds.map(item => {
        return activeRounds?.roundNumber === item.roundNumber
          ? {
              ...item,
              employeeResults: [...activeRounds?.employeeResults, values]
            }
          : {
              ...item,
              employeeResults: [...item.employeeResults, values]
            };
      })
    );
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={{
        id: null,
        employeeId: null,
        rate: 0
      }}
    >
      {({ values, dirty, isValid, errors, setFieldValue, setFieldError }) => {
        const allUser = activeItem?.qcRounds
          .filter(r => r.roundNumber !== activeRounds?.roundNumber)
          .map(i => i.employeeResults)
          .flat();
        return (
          <>
            <div className={styles.root}>
              <Form>
                <div>
                  <div className={styles.container}>
                    <Form.Field required className={styles.formField}>
                      <label>Worker name</label>
                      <Field
                        fluid
                        className={styles.selectWorkersField}
                        required
                        as={SingleSelect}
                        search={true}
                        selector={workersFilterOptions}
                        value={values?.employeeId}
                        text={
                          values?.employeeId
                            ? employees.find(i => {
                                return i.key === values?.employeeId;
                              })?.text
                            : ""
                        }
                        name="employeeResults"
                        onChange={(_, value) => {
                          setActiveValue(value);
                          if (
                            activeRounds?.employeeResults.some(
                              i => i?.employeeId === value
                            )
                          ) {
                            setFieldError(
                              "workers",
                              `Тhis user has already been added`
                            );
                          } else {
                            setFieldError("workers", undefined);
                          }
                          /*    if (!allUser.some((i) => i?.employeeId === value)) {
                            setFieldError("workersToAll", undefined);
                          } */

                          setFieldValue("employeeId", value);
                        }}
                        loaderAction={getEmployeeList}
                      />
                    </Form.Field>
                    <CustomSlider
                      setSliderValue={setSliderValue}
                      onChangeValue={setFieldValue}
                      values={values}
                      sliderValue={sliderValue}
                    />
                  </div>
                  {errors["workers"] && (
                    <div style={{ color: "#D92D20" }}>{errors["workers"]}</div>
                  )}

                  <Modal
                    className={styles.messageModal}
                    onClose={() => {
                      return (
                        setOpenAddModal(false),
                        setFieldError("workersToAll", undefined)
                      );
                    }}
                    open={openAddModal}
                    size="tiny"
                  >
                    <Modal.Content>
                      {` Add worker  ${
                        employees.find(i => {
                          return i.key === values?.employeeId;
                        })?.text
                      } to all rounds?`}
                      {errors["workersToAll"] && (
                        <div style={{ color: "#D92D20" }}>
                          {errors["workersToAll"]}
                        </div>
                      )}
                    </Modal.Content>
                    <Modal.Actions>
                      <Button
                        content="Add to current"
                        className={styles.addCurrButton}
                        onClick={() => {
                          return (
                            onAddWorker(activeItem, values, activeRounds),
                            setOpenAddModal(false),
                            setAddCheck(false)
                          );
                        }}
                      />
                      <Button
                        content="Add to all"
                        className={styles.addAllButton}
                        onClick={() => {
                          if (
                            allUser.some(i => i?.employeeId === activeValue)
                          ) {
                            setFieldError(
                              "workersToAll",
                              `Тhis user has already been added in another round`
                            );
                          } else {
                            setFieldError("workersToAll", undefined);
                            return (
                              onAddAllWorker(activeItem, values, activeRounds),
                              setOpenAddModal(false),
                              setAddCheck(false)
                            );
                          }
                        }}
                        positive
                      />
                    </Modal.Actions>
                  </Modal>
                </div>
              </Form>
              <div className={styles.btnWrapper}>
                <Button
                  content="Cancel"
                 type="text"
                  onClick={() => {
                    setAddCheck(false);
                  }}
                />
                <Button
                  content={"Save"}
                  primary
                  onClick={() => {
                    return setOpenAddModal(true);
                  }}
                  disabled={
                    !isValid ||
                    !dirty ||
                    !values?.employeeId ||
                    values.rate === 0
                  }
                />
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default WorkersAdd;
