import React, { useState } from "react";
import { get } from "lodash";
import { Modal, Button, Form, Radio, Icon } from "semantic-ui-react";
import { Formik } from "formik";
import styles from "./AddModal.module.css";
import AddModalFirstStep from "./firstStep/FirstStep";
import AddModalSecondStep from "./secondStep/SecondStep";
import { createHarvestUnits } from "actions/HarvestUnits/harvestUnits";
import { useDispatch } from "react-redux";

const REQUIRED_FIELDS = ["name"];

const HarvestUnitsAddModal = ({ open, onClose, shed, areas, updateTable }) => {
  const [scanDate, setScanDate] = useState();
  const [step, setStep] = useState(1);
  const [selectedBlock, setSelectedBlock] = useState([]);
  const [selectedPatch, setSelectedPatch] = useState([]);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const dataFormater = areas.content
    .map(item => {
      const children = areas.content
        .filter(
          a =>
            a.archived === false &&
            a.type === "PATCH" &&
            areas.content.some(area => area.parent?.id === item.id)
        )
        .filter(ch => ch.parent.id === item.id);
      return {
        ...item,
        children
      };
    })
    .filter(a => a.archived === false && a.type !== "PATCH");
  const onSubmit = newData => {
    step === 1
      ? setStep(2)
      : dispatch(createHarvestUnits(newData)).then(
        onClose(),
        updateTable(),
        setStep(1)
      );
  };
  return (
    <Modal
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      size="large"
      onClick={e => e.stopPropagation()}
      className={styles.root}
    >
      <Modal.Header className={styles.modalHeader}>
        Add bulk entries
      </Modal.Header>
      <Formik
        enableReinitialize
        validateOnChange={false}
        onSubmit={onSubmit}
        initialValues={{
          areaIds: [],
          scanDate: "",
          blocks: [
            {
              numberOfBins: null,
              harvestUnitIds: null,
              bins: []
            }
          ]
        }}
      >
        {({
          values,
          dirty,
          isValid,
          setValues,
          setFieldValue,
          handleSubmit
        }) => {
          const changeBlockNumbers = value => {
            return setFieldValue(
              "blocks",
              value.map(() => {
                return {
                  numberOfBins: null,
                  harvestUnitIds: null,
                  bins: []
                };
              })
            );
          };
          const newData = values.blocks
            ?.map((item, index) => {
              return item.bins
                ?.map((bin, binIndex) => {
                  return {
                    scannedAt: values.scanDate,
                    areaId: values.areaIds[index],
                    harvestUnitId: item.harvestUnitIds,
                    employees: bin.workersId,
                    supervisors: bin.supervisorsIds,
                    row: bin.row,
                    generalTaskId: bin.generalTaskIds,
                    internalBinId: bin.internalBinIds
                  };
                })
                .flat();
            })
            .flat();
          return (
            <>
              <Modal.Content scrolling className={styles.modalContentContainer}>
                <div className={styles.stepLoading}>
                  {step === 1 ? (
                    <>
                      <div className={styles.iconAreasLabelWrapper}>
                        <Icon
                          className={styles.dotIcon}
                          size="large"
                          name="dot circle outline"
                        />
                        <span className={styles.dotIconLabel}>Areas</span>
                      </div>
                      <hr />
                      <div className={styles.iconLabelWrapper}>
                        <Icon
                          className={styles.outLineCircel}
                          size="large"
                          name="circle outline"
                        />
                        <span className={styles.outLineCircelLabel}>
                          Bin details
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={styles.iconAreasLabelWrapper}>
                        <Icon
                          className={styles.dotIcon}
                          size="large"
                          name="check circle outline"
                        />
                        <span className={styles.dotIconLabel}>Areas</span>
                      </div>
                      <div className={styles.coplitedLine} />
                      <div className={styles.iconLabelWrapper}>
                        <Icon
                          className={styles.dotIcon}
                          size="large"
                          name="dot circle outline"
                        />
                        <span className={styles.dotIconLabel}>Bin details</span>
                      </div>
                    </>
                  )}
                </div>
                <Form className={styles.stepForm}>
                  <Form.Group>
                    {step === 1 ? (
                      <AddModalFirstStep
                        setValues={setValues}
                        changeFieldValue={setFieldValue}
                        formValues={values}
                        changeBlockNumbers={changeBlockNumbers}
                        areas={dataFormater}
                        setSelectedBlock={setSelectedBlock}
                        setSelectedPatch={setSelectedPatch}
                      />
                    ) : (
                      <AddModalSecondStep
                        setValues={setValues}
                        formValues={values}
                        changeFieldValue={setFieldValue}
                        areas={areas}
                      />
                    )}
                  </Form.Group>
                </Form>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content={
                    <span className={styles.backButton}>
                      <Icon name="angle left" />
                      Back
                    </span>
                  }
                  type="text"
                  onClick={() => {
                    return step === 1 ? (onClose(), setStep(1)) : setStep(1);
                  }}
                />
                <Button
                  content={step === 1 ? "Next" : "Save"}
                  type="text"
                  onClick={() => onSubmit(newData)}
                  disabled={
                    step === 1
                      ? values.areaIds.length && values.scanDate
                        ? false
                        : true
                      : !values.blocks.every(
                        item => item.harvestUnitIds && item.numberOfBins
                      )
                  }
                />
              </Modal.Actions>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default HarvestUnitsAddModal;
