import React, { Fragment } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Logo from "../../../../../components/Logo";
import classNames from "classnames";
import { Header, Tab, Table, TableBody, TableRow } from "semantic-ui-react";
import Numeric from "../../../../../components/Numeric/Numeric";
import { get, pickBy } from "lodash";
import { minutesToHM, dateRangeToString } from "../../../../../utils/timeUtils";
import styles from "./PrintReports.module.css";
import { matterToUnits } from "utils/constToUnits";
import { uppercaseFirstLetter } from "utils/string";

class PrintReports extends React.Component {
  render() {
    const {
      farmsList,
      blockList,
      patchList,
      farms,
      from,
      to,
      data,
      ref
    } = this.props;
    return (
      <div className={styles.printTableWrapper}>
        <style type="text/css" media="print">
          {"\
          @page { size: landscape; }\
          "}
        </style>
        <div className={styles.printTableRow}>
          <div className={styles.printTableHeader}>Nutrient breakdown</div>
          <div className={styles.printTableLogo}>
            <Logo width={140} height={60} />
          </div>
        </div>
        <Table columns="3" basic="very" className={styles.totalRowWrapper}>
          <Table.Header className={styles.totalRowHeaderContainer}>
            <Table.HeaderCell className={styles.cellHeader}>
              Date range
            </Table.HeaderCell>
          </Table.Header>
          <Table.Body className={styles.totalRowValueWrapper}>
            <Table.Cell className={styles.cellValue}>
              <div className={styles.dateRow}>
                {from ? `${moment(from).format("DD/MM/YY")}` : ""}-
                {to ? `${moment(to).format("DD/MM/YY")}` : ""}
              </div>
            </Table.Cell>
          </Table.Body>
        </Table>

        <div className={styles.totalRowcontainer}>
          <Table basic="very" columns="14" className={styles.totalRowWrapper}>
            <div className={styles.totalHeader}>Farm Total</div>
            <Table.Header className={styles.totalRowHeaderWrapper}>
              <Table.Cell className={styles.cellTotalHeader}>
                Nitrogen (N)
              </Table.Cell>
              <Table.Cell className={styles.cellTotalHeader}>
                Phosphorus (P)
              </Table.Cell>
              <Table.Cell className={styles.cellTotalHeader}>
                Potassium (K)
              </Table.Cell>
              <Table.Cell className={styles.cellTotalHeader}>
                Calcium (Ca)
              </Table.Cell>
              <Table.Cell className={styles.cellTotalHeader}>
                Magnesium (Mg)
              </Table.Cell>
              <Table.Cell className={styles.cellTotalHeader}>
                Sulphur (S)
              </Table.Cell>
              <Table.Cell className={styles.cellTotalHeader}>
                Iron (Fe)
              </Table.Cell>
              <Table.Cell className={styles.cellTotalHeader}>
                Boron (Bo)
              </Table.Cell>
              <Table.Cell className={styles.cellTotalHeader}>
                Manganese (Mn)
              </Table.Cell>
              <Table.Cell className={styles.cellTotalHeader}>
                Zinc (Zn)
              </Table.Cell>
              <Table.Cell className={styles.cellTotalHeader}>
                Copper (Cu)
              </Table.Cell>
              <Table.Cell className={styles.cellTotalHeader}>
                Molybdenum (Mo)
              </Table.Cell>
              <Table.Cell className={styles.cellTotalHeader}>
                Chlorine (Cl)
              </Table.Cell>
              <Table.Cell className={styles.cellTotalHeader}>
                Sodium (Na)
              </Table.Cell>
            </Table.Header>
            <Table.Body className={styles.totalRowValueWrapper}>
              <Table.Cell className={styles.cellTotalValue}>
                {(data.content &&
                  data.content
                    .reduce((prev, curr) => prev + curr.nitrogen, 0)
                    ?.toFixed(2)) ||
                  "-"}
              </Table.Cell>
              <Table.Cell className={styles.cellTotalValue}>
                {(data.content &&
                  data.content
                    .reduce((prev, curr) => prev + curr.phosphorus, 0)
                    ?.toFixed(2)) ||
                  "-"}
              </Table.Cell>
              <Table.Cell className={styles.cellTotalValue}>
                {(data.content &&
                  data.content
                    .reduce((prev, curr) => prev + curr.potassium, 0)
                    ?.toFixed(2)) ||
                  "-"}
              </Table.Cell>
              <Table.Cell className={styles.cellTotalValue}>
                {(data.content &&
                  data.content
                    .reduce((prev, curr) => prev + curr.calcium, 0)
                    ?.toFixed(2)) ||
                  "-"}
              </Table.Cell>
              <Table.Cell className={styles.cellTotalValue}>
                {(data.content &&
                  data.content
                    .reduce((prev, curr) => prev + curr.magnesium, 0)
                    ?.toFixed(2)) ||
                  "-"}
              </Table.Cell>
              <Table.Cell className={styles.cellTotalValue}>
                {(data.content &&
                  data.content
                    .reduce((prev, curr) => prev + curr.sulphur, 0)
                    ?.toFixed(2)) ||
                  "-"}
              </Table.Cell>
              <Table.Cell className={styles.cellTotalValue}>
                {(data.content &&
                  data.content
                    .reduce((prev, curr) => prev + curr.iron, 0)
                    ?.toFixed(2)) ||
                  "-"}
              </Table.Cell>
              <Table.Cell className={styles.cellTotalValue}>
                {(data.content &&
                  data.content
                    .reduce((prev, curr) => prev + curr.boron, 0)
                    ?.toFixed(2)) ||
                  "-"}
              </Table.Cell>
              <Table.Cell className={styles.cellTotalValue}>
                {(data.content &&
                  data.content
                    .reduce((prev, curr) => prev + curr.manganese, 0)
                    ?.toFixed(2)) ||
                  "-"}
              </Table.Cell>
              <Table.Cell className={styles.cellTotalValue}>
                {(data.content &&
                  data.content
                    .reduce((prev, curr) => prev + curr.zinc, 0)
                    ?.toFixed(2)) ||
                  "-"}
              </Table.Cell>
              <Table.Cell className={styles.cellTotalValue}>
                {(data.content &&
                  data.content
                    .reduce((prev, curr) => prev + curr.copper, 0)
                    ?.toFixed(2)) ||
                  "-"}
              </Table.Cell>
              <Table.Cell className={styles.cellTotalValue}>
                {(data.content &&
                  data.content
                    .reduce((prev, curr) => prev + curr.molybdenum, 0)
                    ?.toFixed(2)) ||
                  "-"}
              </Table.Cell>
              <Table.Cell className={styles.cellTotalValue}>
                {(data.content &&
                  data.content
                    .reduce((prev, curr) => prev + curr.chlorine, 0)
                    ?.toFixed(2)) ||
                  "-"}
              </Table.Cell>
              <Table.Cell className={styles.cellTotalValue}>
                {(data.content &&
                  data.content
                    .reduce((prev, curr) => prev + curr.sodium, 0)
                    ?.toFixed(2)) ||
                  "-"}
              </Table.Cell>
            </Table.Body>
          </Table>
        </div>
        <div className={styles.container}>
          {data.content &&
            data.content.map(item => {
              return (
                item &&
                item.subRows &&
                item.subRows.map(sub => {
                  return (
                    <>
                      <div className={styles.twoTableWrapepr}>
                        <Table className={styles.blockTable} basic="very">
                          <Table.Header>
                            <Table.Cell
                              colSpan="2"
                              className={styles.firstHeader}
                            >
                              Farm
                            </Table.Cell>
                            <Table.Cell
                              colSpan="5"
                              className={styles.firstValue}
                            >
                              {item.name}
                            </Table.Cell>
                            <Table.Cell
                              colSpan="2"
                              className={styles.firstTableSecondHeader}
                            >
                              Block
                            </Table.Cell>
                            <Table.Cell
                              colSpan="5"
                              className={styles.firstValue}
                            >
                              {sub.name}
                            </Table.Cell>
                          </Table.Header>
                          <Table.Header>
                            <Table.Cell
                              className={styles.secondHeaderFirstColumn}
                            >
                              Nitrogen (N)
                            </Table.Cell>
                            <Table.Cell className={styles.secondHeader}>
                              Phosphorus (P)
                            </Table.Cell>
                            <Table.Cell className={styles.secondHeader}>
                              Potassium (K)
                            </Table.Cell>
                            <Table.Cell className={styles.secondHeader}>
                              Calcium (Ca)
                            </Table.Cell>
                            <Table.Cell className={styles.secondHeader}>
                              Magnesium (Mg)
                            </Table.Cell>
                            <Table.Cell className={styles.secondHeader}>
                              Sulphur (S)
                            </Table.Cell>
                            <Table.Cell className={styles.secondHeader}>
                              Iron (Fe)
                            </Table.Cell>
                            <Table.Cell className={styles.secondHeader}>
                              Boron (Bo)
                            </Table.Cell>
                            <Table.Cell className={styles.secondHeader}>
                              Manganese (Mn)
                            </Table.Cell>
                            <Table.Cell className={styles.secondHeader}>
                              Zinc (Zn)
                            </Table.Cell>
                            <Table.Cell className={styles.secondHeader}>
                              Copper (Cu)
                            </Table.Cell>
                            <Table.Cell className={styles.secondHeader}>
                              Molybdenum (Mo)
                            </Table.Cell>
                            <Table.Cell className={styles.secondHeader}>
                              Chlorine (Cl)
                            </Table.Cell>
                            <Table.Cell className={styles.secondHeader}>
                              Sodium (Na)
                            </Table.Cell>
                          </Table.Header>
                          <Table.Body>
                            <Table.Row className={styles.blockValueRow}>
                              <Table.Cell
                                className={styles.blockValueFirstColumn}
                              >
                                {sub.nitrogen ? sub.nitrogen.toFixed(2) : "-"}
                              </Table.Cell>
                              <Table.Cell className={styles.blockValue}>
                                {sub.phosphorus
                                  ? sub.phosphorus.toFixed(2)
                                  : "-"}
                              </Table.Cell>
                              <Table.Cell className={styles.blockValue}>
                                {sub.potassium ? sub.potassium.toFixed(2) : "-"}
                              </Table.Cell>
                              <Table.Cell className={styles.blockValue}>
                                {sub.calcium ? sub.calcium.toFixed(2) : "-"}
                              </Table.Cell>
                              <Table.Cell className={styles.blockValue}>
                                {sub.magnesium ? sub.magnesium.toFixed(2) : "-"}
                              </Table.Cell>
                              <Table.Cell className={styles.blockValue}>
                                {sub.sulphur ? sub.sulphur.toFixed(2) : "-"}
                              </Table.Cell>
                              <Table.Cell className={styles.blockValue}>
                                {sub.iron ? sub.iron.toFixed(2) : "-"}
                              </Table.Cell>
                              <Table.Cell className={styles.blockValue}>
                                {sub.boron ? sub.boron.toFixed(2) : "-"}
                              </Table.Cell>
                              <Table.Cell className={styles.blockValue}>
                                {sub.manganese ? sub.manganese.toFixed(2) : "-"}
                              </Table.Cell>
                              <Table.Cell className={styles.blockValue}>
                                {sub.zinc ? sub.zinc.toFixed(2) : "-"}
                              </Table.Cell>
                              <Table.Cell className={styles.blockValue}>
                                {sub.copper ? sub.copper.toFixed(2) : "-"}
                              </Table.Cell>
                              <Table.Cell className={styles.blockValue}>
                                {sub.molybdenum
                                  ? sub.molybdenum.toFixed(2)
                                  : "-"}
                              </Table.Cell>
                              <Table.Cell className={styles.blockValue}>
                                {sub.chlorine ? sub.chlorine.toFixed(2) : "-"}
                              </Table.Cell>
                              <Table.Cell className={styles.blockValue}>
                                {sub.sodium ? sub.sodium.toFixed(2) : "-"}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                        {sub.subRows &&
                          sub.subRows.map(patch => {
                            return (
                              <Table
                                className={styles.patchTable}
                                basic="very"
                                columns="14"
                              >
                                <Table.Header>
                                  <Table.Row>
                                    <Table.Cell
                                      colSpan="2"
                                      className={styles.patchTablefirstHeader}
                                    >
                                      Farm
                                    </Table.Cell>
                                    <Table.Cell
                                      colSpan="3"
                                      className={styles.patchTablefirstValue}
                                    >
                                      {item.name}
                                    </Table.Cell>
                                    <Table.Cell
                                      colSpan="2"
                                      className={
                                        styles.patchTablefirstTableSecondHeader
                                      }
                                    >
                                      Block
                                    </Table.Cell>
                                    <Table.Cell
                                      colSpan="3"
                                      className={styles.patchTablefirstValue}
                                    >
                                      {sub.name}
                                    </Table.Cell>
                                    <Table.Cell
                                      colSpan="2"
                                      className={
                                        styles.patchTablefirstTableSecondHeader
                                      }
                                    >
                                      Patch
                                    </Table.Cell>
                                    <Table.Cell
                                      colSpan="3"
                                      className={styles.patchTablefirstValue}
                                    >
                                      {patch.name}
                                    </Table.Cell>
                                  </Table.Row>
                                  <Table.Row
                                    className={styles.patchTablesecondHeaderRow}
                                  >
                                    <Table.Cell
                                      className={
                                        styles.patchTablesecondHeaderFirstColumn
                                      }
                                    >
                                      Nitrogen (N)
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTablesecondHeader}
                                    >
                                      Phosphorus (P)
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTablesecondHeader}
                                    >
                                      Potassium (K)
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTablesecondHeader}
                                    >
                                      Calcium (Ca)
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTablesecondHeader}
                                    >
                                      Magnesium (Mg)
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTablesecondHeader}
                                    >
                                      Sulphur (S)
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTablesecondHeader}
                                    >
                                      Iron (Fe)
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTablesecondHeader}
                                    >
                                      Boron (Bo)
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTablesecondHeader}
                                    >
                                      Manganese (Mn)
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTablesecondHeader}
                                    >
                                      Zinc (Zn)
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTablesecondHeader}
                                    >
                                      Copper (Cu)
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTablesecondHeader}
                                    >
                                      Molybdenum (Mo)
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTablesecondHeader}
                                    >
                                      Chlorine (Cl)
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTablesecondHeader}
                                    >
                                      Sodium (Na)
                                    </Table.Cell>
                                  </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                  <Table.Row>
                                    <Table.Cell
                                      className={
                                        styles.patchTableblockValueFirstColumn
                                      }
                                    >
                                      {patch.nitrogen
                                        ? patch.nitrogen.toFixed(2)
                                        : "-"}
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTableblockValue}
                                    >
                                      {patch.phosphorus
                                        ? patch.phosphorus.toFixed(2)
                                        : "-"}
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTableblockValue}
                                    >
                                      {patch.potassium
                                        ? patch.potassium.toFixed(2)
                                        : "-"}
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTableblockValue}
                                    >
                                      {patch.calcium
                                        ? patch.calcium.toFixed(2)
                                        : "-"}
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTableblockValue}
                                    >
                                      {patch.magnesium
                                        ? patch.magnesium.toFixed(2)
                                        : "-"}
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTableblockValue}
                                    >
                                      {patch.sulphur
                                        ? patch.sulphur.toFixed(2)
                                        : "-"}
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTableblockValue}
                                    >
                                      {patch.iron ? patch.iron.toFixed(2) : "-"}
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTableblockValue}
                                    >
                                      {patch.boron
                                        ? patch.boron.toFixed(2)
                                        : "-"}
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTableblockValue}
                                    >
                                      {patch.manganese
                                        ? patch.manganese.toFixed(2)
                                        : "-"}
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTableblockValue}
                                    >
                                      {patch.zinc ? patch.zinc.toFixed(2) : "-"}
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTableblockValue}
                                    >
                                      {patch.copper
                                        ? patch.copper.toFixed(2)
                                        : "-"}
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTableblockValue}
                                    >
                                      {patch.molybdenum
                                        ? patch.molybdenum.toFixed(2)
                                        : "-"}
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTableblockValue}
                                    >
                                      {patch.chlorine
                                        ? patch.chlorine.toFixed(2)
                                        : "-"}
                                    </Table.Cell>
                                    <Table.Cell
                                      className={styles.patchTableblockValue}
                                    >
                                      {patch.sodium
                                        ? patch.sodium.toFixed(2)
                                        : "-"}
                                    </Table.Cell>
                                  </Table.Row>
                                </Table.Body>
                              </Table>
                            );
                          })}
                      </div>
                    </>
                  );
                })
              );
            })}
        </div>
      </div>
    );
  }
}

PrintReports.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  showExpandedData: PropTypes.bool,
  startDate: PropTypes.any,
  endDate: PropTypes.any
};

export default PrintReports;
