import React, { Component } from "react";
import { Container, Grid, Header } from "semantic-ui-react";
import Logo from "../../../Scouting/ScoutingList/components/ScoutingReport/Logo";
import { getRecommendationTaskReadableId } from "utils/recommendations";
import GeneralInfoSection from "./GeneralInfoSection";
import ApplyToAreasSection from "./ApplyToAreasSection";
import OperationsSection from "./OperationsSection";
import styles from "./RecommendationsPrintout.module.css";

class RecommendationsPrintout extends Component {
  render() {
    const { tasksToPrint, ref } = this.props;

    return (
      <Container fluid className={styles.root} ref={ref}>
        <Container>
          {tasksToPrint?.length &&
            tasksToPrint?.map((task) => (
              <Grid key={task.id} className={styles.page}>
                <Grid.Row
                  columns="equal"
                  className="print-page-header-row"
                  textAlign="right"
                >
                  <Grid.Column floated="left" textAlign="left">
                    <Header style={{color:'#FFF'}}  as="h1" className="bold">
                      Recommendation Task
                    </Header>
                    <Header as="h4" className="bold">
                      Task ID: {getRecommendationTaskReadableId(task?.id)}
                    </Header>
                  </Grid.Column>
                  <Grid.Column floated="right">
                    <Logo />
                  </Grid.Column>
                </Grid.Row>
                <GeneralInfoSection taskData={task} ref={ref} />
                <ApplyToAreasSection taskData={task} ref={ref} />
                <OperationsSection taskData={task} ref={ref} />
              </Grid>
            ))}
        </Container>
      </Container>
    );
  }
}

export default RecommendationsPrintout;
