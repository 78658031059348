import React, { useEffect, useState } from "react";
import { Modal, Button, Confirm, Form } from "semantic-ui-react";
import { Formik, useFormikContext } from "formik";

import { SHED_INITIAL_VALUES } from "constants/Sheds";
import styles from "./HarvestUnitsControlModal.module.css";
import DatePicker from "components/DatePicker/DatePicker";
import moment from "moment";
import HarvestUnitsForm from "./HarvestUnitsForm";
import { useDispatch } from "react-redux";
import {
  fetchHarvestUnits,
  updateHarvestUnits
} from "actions/HarvestUnits/harvestUnits";

const HarvestUnitsModal = ({
  open,
  onClose,
  unit,
  harvestId,
  areas,
  employees,
  activeItem,
  urlList,
  updateTable,
  isUploadingAttachment
}) => {
  const [secondOpen, setSecondOpen] = useState(false);
  const dispatch = useDispatch();
  /*   useEffect(() => {
    dispatch(fetchHarvestUnits());
  }, []); */
  const onSubmit = data => {
    dispatch(updateHarvestUnits(activeItem.id, data)).then(
      onClose(),
      updateTable()
    );
  };
  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      initialValues={unit || {}}
    >
      {({ values, dirty, isValid, setValues, setFieldValue, handleSubmit }) => {
        const dataToSend = {
          areaId:
            (values.patchIds ? values.patchIds : values.blockIds) ||
            values.areaId,
          attachments: values.attachments,
          barcodeId: 142,
          employeeIds: values.employeeBinParts?.map(item => {
            return item.employeeId || item;
          }),
          generalTaskId: values.generalTaskId,
          harvestUnitId: values.harvestUnitIds || values.harvestUnit?.id,
          internalBinId: values.internalBinId,
          price: values.price,
          row: values.row,
          scannedAt: values.scannedAt,
          supervisorIds: values.supervisors?.map(
            item => item.employeeId || item
          )
        };
        return (
          <Form>
            <Modal
              open={open}
              closeOnDimmerClick={false}
              closeOnEscape={false}
              size="large"
              onClick={e => e.stopPropagation()}
              className={styles.root}
            >
              <Modal.Header className={styles.modalHeader}>
                Edit harvest unit ID {harvestId}
              </Modal.Header>

              <Modal.Content scrolling className={styles.modalContentContainer}>
                <HarvestUnitsForm
                  areas={areas}
                  employees={employees}
                  urlList={urlList}
                  activeItem={activeItem}
                  setChangeValue={setFieldValue}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  content="Cancel"
                  type="text"
                  onClick={() => {
                    dirty ? setSecondOpen(true) : onClose();
                  }}
                />
                <Button
                  content={"Update"}
                  primary
                  onClick={() => onSubmit(dataToSend)}
                  disabled={!isValid || !dirty || isUploadingAttachment}
                />
              </Modal.Actions>

              <Confirm
                className={styles.confirmModal}
                content="Are you sure you want to close modal window"
                open={secondOpen}
                onCancel={() => setSecondOpen(false)}
                onConfirm={() => {
                  onClose();
                  setSecondOpen(false);
                }}
              />
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default HarvestUnitsModal;
