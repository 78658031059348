import React from "react";

const Logo = ({ width, height }) => (
  <svg width="137" height="22" viewBox="0 0 137 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M32.8025 15.9617C31.7635 15.9617 30.8516 15.6361 30.0668 14.9889C29.2821 14.316 28.8766 13.4921 28.8523 12.4976H39.111C39.1352 12.2732 39.1614 11.9735 39.1614 11.5744C39.1614 9.50589 38.5784 7.86017 37.4143 6.58966C36.2482 5.31915 34.6282 4.6958 32.5765 4.6958C30.6257 4.6958 29.0057 5.36878 27.7145 6.71473C26.4234 8.06068 25.7637 9.68058 25.7637 11.6003C25.7637 13.5695 26.4476 15.2391 27.7892 16.5592C29.157 17.8814 30.8536 18.5285 32.8569 18.5285C35.2879 18.5285 37.493 17.3811 38.6328 15.3125C38.6328 15.3125 38.8628 14.8857 39.0666 14.3835H36.1494C35.3989 15.4277 34.2833 15.9617 32.8025 15.9617ZM32.5745 7.23683C34.4991 7.23683 35.994 8.38426 36.0949 10.2523H29.0016C29.2821 8.43389 30.7265 7.23683 32.5745 7.23683Z" fill="white"/>
  <path d="M55.787 7.08508V4.96492H52.8496V18.2735H55.8636V11.5199C55.8636 9.20127 57.4372 7.66276 59.2085 7.66276H60.2475V4.9153C59.9752 4.91133 59.842 4.89941 59.5132 4.89941C57.8649 4.89941 56.6242 5.58827 55.787 7.08508ZM58.805 7.63299V4.96492V7.63299Z" fill="white"/>
  <path d="M64.3011 0.805664H61.2871V6.30056V13.9891C61.2871 16.8814 63.01 18.4259 65.9736 18.4259C66.5566 18.4259 67.1134 18.3266 67.5956 18.1519V15.6347C67.2668 15.7102 66.9359 15.734 66.6333 15.734C65.09 15.734 64.3032 15.1107 64.3032 13.5146V7.63459H67.6218V4.96654H64.3032V0.805664H64.3011Z" fill="white"/>
  <path d="M75.9166 4.69245C74.2704 4.69245 72.9026 5.33961 71.8636 6.66173V0.785645H68.8496V18.2749H71.8636V10.8742C71.8636 8.85533 73.0802 7.50939 74.9785 7.50939C76.9798 7.50939 77.9684 8.83151 77.9684 10.8246V18.2749H80.9824V10.0762C80.9824 6.88605 79.2353 4.69245 75.9166 4.69245Z" fill="white"/>
  <path d="M90.4745 4.71826C89.6878 4.71826 88.8546 4.91678 87.9669 5.3158C87.0792 5.691 86.4458 6.2389 86.016 6.93768V0.785645H83V18.275H85.9394V16.2322C86.3711 16.929 87.0288 17.4789 87.9144 17.876C88.8001 18.275 89.6373 18.4755 90.3959 18.4755C92.3467 18.4755 93.9425 17.8025 95.2094 16.4308C96.4764 15.059 97.1078 13.4391 97.1078 11.5691C97.1078 9.79831 96.5006 8.20422 95.2599 6.80865C94.0454 5.41506 92.4496 4.71826 90.4745 4.71826ZM92.9318 14.5885C92.1974 15.3866 91.2089 15.7836 90.0186 15.7836C88.8283 15.7836 87.8418 15.3846 87.0792 14.5885C86.3449 13.7667 85.9656 12.7681 85.9656 11.5969C85.9656 10.4236 86.3449 9.42907 87.0792 8.60522C87.8398 7.78336 88.8283 7.38435 90.0186 7.38435C91.2089 7.38435 92.1974 7.78336 92.9318 8.60522C93.6923 9.42907 94.0716 10.4236 94.0716 11.5969C94.0716 12.7681 93.6903 13.7667 92.9318 14.5885Z" fill="white"/>
  <path d="M116.932 4.69189C115.209 4.69189 113.842 5.39068 112.805 6.76044V4.96585H109.865V18.2744H112.879V10.8737C112.879 8.8548 114.096 7.50885 115.994 7.50885C117.995 7.50885 118.984 8.83098 118.984 10.8241V18.2744H121.998V10.0757C121.998 6.88551 120.251 4.69189 116.932 4.69189Z" fill="white"/>
  <path d="M45.8761 4.69189C43.2232 4.69189 41.0827 6.21055 40.2616 8.38232H43.0961C43.5258 7.76097 44.3005 7.24879 45.7995 7.25673C47.4961 7.26666 48.4584 8.15403 48.4584 9.24984C48.4584 9.99825 48.0287 10.3715 47.1915 10.3715H45.519C43.1385 10.3715 39.8965 11.3442 39.8965 14.4331V14.584C39.8965 15.7294 40.3262 16.6764 41.1876 17.4009C42.0491 18.1236 43.1385 18.4968 44.4559 18.4968C46.2796 18.4968 47.597 17.8238 48.4322 16.454V18.2724H51.3958V9.80172C51.3958 8.8806 51.2445 8.08256 50.9399 7.40959C50.1046 5.58919 48.408 4.69189 45.8761 4.69189ZM48.4342 12.8926C48.4342 14.8857 47.1168 16.1324 45.0651 16.1324C43.774 16.1324 42.9125 15.4098 42.9125 14.312C42.9125 13.7383 43.1909 13.2897 43.7235 12.9661C44.2803 12.6425 44.8876 12.4678 45.5211 12.4678H48.4342V12.8926Z" fill="white"/>
  <path d="M103.118 4.69189C100.465 4.69189 98.3269 6.21055 97.5038 8.38232H100.338C100.768 7.76097 101.543 7.24879 103.042 7.25673C104.738 7.26666 105.701 8.15403 105.701 9.24984C105.701 9.99825 105.269 10.3715 104.434 10.3715H102.761C100.381 10.3715 97.1387 11.3442 97.1387 14.4331V14.584C97.1387 15.7294 97.5704 16.6764 98.4298 17.4009C99.2913 18.1236 100.381 18.4968 101.696 18.4968C103.52 18.4968 104.837 17.8238 105.672 16.454V18.2724H108.636V9.80172C108.636 8.8806 108.485 8.08256 108.18 7.40959C107.349 5.58919 105.652 4.69189 103.118 4.69189ZM105.676 12.8926C105.676 14.8857 104.359 16.1324 102.307 16.1324C101.014 16.1324 100.155 15.4098 100.155 14.312C100.155 13.7383 100.433 13.2897 100.966 12.9661C101.523 12.6425 102.132 12.4678 102.765 12.4678H105.678V12.8926H105.676Z" fill="white"/>
  <path d="M132.79 14.5562C132.056 15.3781 131.069 15.7771 129.877 15.7771C128.687 15.7771 127.7 15.3523 126.94 14.5304C126.181 13.6827 125.8 12.6862 125.8 11.5149C125.8 10.3437 126.181 9.37095 126.915 8.57292C127.674 7.75106 128.662 7.35204 129.853 7.35204C131.043 7.35204 132.029 7.75106 132.766 8.57292C132.945 8.76746 133.101 8.96995 133.236 9.18435H136.579C136.24 8.23345 135.685 7.38182 134.892 6.62944C133.498 5.30931 131.828 4.66016 129.877 4.66016C127.902 4.66016 126.203 5.33313 124.811 6.65326C123.417 7.97538 122.709 9.6191 122.709 11.5646C122.709 13.508 123.393 15.1537 124.785 16.4739C126.179 17.794 127.876 18.467 129.851 18.467C131.852 18.467 133.522 17.794 134.89 16.4739C135.661 15.7314 136.206 14.8917 136.546 13.9626H133.234C133.105 14.1711 132.957 14.3676 132.79 14.5562Z" fill="white"/>
  <path d="M9.81456 9.32211L5.45491 5.03223L3.07031 7.37866L9.81456 14.015V9.32211Z" fill="url(#paint0_radial_20822_5201)"/>
  <path d="M14.5747 3.95764L11.6333 1.06329C11.5909 1.0216 11.5425 0.989834 11.4961 0.954102V6.985L14.5747 3.95764Z" fill="url(#paint1_radial_20822_5201)"/>
  <path d="M9.81592 0.82959C9.70093 0.893114 9.58997 0.968549 9.49111 1.06384L6.64453 3.86685L9.81592 6.98749V0.82959Z" fill="url(#paint2_radial_20822_5201)"/>
  <path d="M19.3338 8.63916L11.4961 16.3515V21.0901C11.5425 21.0563 11.5909 21.0226 11.6333 20.9809L20.6855 12.0755C21.2745 11.4958 21.2745 10.5469 20.6855 9.96922L19.3338 8.63916Z" fill="url(#paint3_radial_20822_5201)"/>
  <path d="M1.88478 8.54736L0.440303 9.96872C-0.146768 10.5484 -0.146768 11.4953 0.440303 12.075L9.49248 20.9803C9.58932 21.0776 9.70229 21.151 9.81729 21.2145V16.3509L1.88478 8.54736Z" fill="url(#paint4_radial_20822_5201)"/>
  <path d="M11.4961 14.0136L18.1455 7.47049L15.7629 5.12402L11.4961 9.32266V14.0136Z" fill="url(#paint5_radial_20822_5201)"/>
  <defs>
  <radialGradient id="paint0_radial_20822_5201" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.5826 21.521) scale(11.7546 11.5665)">
  <stop stop-color="#6EC179"/>
  <stop offset="1" stop-color="#00A8B5"/>
  </radialGradient>
  <radialGradient id="paint1_radial_20822_5201" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.5854 21.5226) scale(11.7547 11.5666)">
  <stop stop-color="#6EC179"/>
  <stop offset="1" stop-color="#00A8B5"/>
  </radialGradient>
  <radialGradient id="paint2_radial_20822_5201" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.584 21.5231) scale(11.7547 11.5666)">
  <stop stop-color="#6EC179"/>
  <stop offset="1" stop-color="#00A8B5"/>
  </radialGradient>
  <radialGradient id="paint3_radial_20822_5201" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.5854 21.521) scale(11.7549 11.5669)">
  <stop stop-color="#6EC179"/>
  <stop offset="1" stop-color="#00A8B5"/>
  </radialGradient>
  <radialGradient id="paint4_radial_20822_5201" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.5833 21.5206) scale(11.7551 11.567)">
  <stop stop-color="#6EC179"/>
  <stop offset="1" stop-color="#00A8B5"/>
  </radialGradient>
  <radialGradient id="paint5_radial_20822_5201" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.5854 21.5217) scale(11.7547 11.5668)">
  <stop stop-color="#6EC179"/>
  <stop offset="1" stop-color="#00A8B5"/>
  </radialGradient>
  </defs>
  </svg>
  
);

Logo.defaultProps = {
  width: 256,
  height: 108
};

export default Logo;
