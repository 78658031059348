import React, { Fragment } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import Logo from "../../../../../../components/Logo";
import classNames from "classnames";
import { Header, Table, TableBody, TableRow } from "semantic-ui-react";
import Numeric from "../../../../../../components/Numeric/Numeric";
import { pickBy } from "lodash";
import {
  minutesToHM,
  dateRangeToString
} from "../../../../../../utils/timeUtils";
import styles from "./PrintReports.module.css";

const headerColumn = {
  areaName: "Area",
  crops: "Crop",
  varieties: "Variety"
};
const secondHeaderColumn = {
  size: "Size (Ha)",
  binUnits: "Box units",
  binUnitsNumber: "No.units",
  weight: "Weight (T)",
  tonnePerHa: "Tonne/Ha (T/Ha)",
  cost: "Cost ($)",
  costPerTonne: "Cost/Tonne ($/T)",
  costPerBin: "Cost/Unit ($/Unit)",
  avgKgPerTree: "Avg. Kg/Tree",
  firstBarcode: "First Harvest",
  lastBarcode: "Last Harvest",
  withholdingPeriod: "Last WHP"
};
class PrintReports extends React.Component {
  render() {
    const {
      tableData,
      data,
      farmIds,
      blockIds,
      patchIds,
      cropIds,
      varietyIds,
      binUnitsIds,
      selectedItem,
      from,
      to,
      farmsList,
      blockList,
      patchList,
      cropsList,
      varietiesList,
      binUnitsList
    } = this.props;

    const headerNewData = pickBy(headerColumn, function (value, key) {
      return (
        selectedItem &&
        selectedItem.find(item => {
          return value === item;
        })
      );
    });
    const secondHeaderNewData = pickBy(secondHeaderColumn, function (
      value,
      key
    ) {
      return (
        selectedItem &&
        selectedItem.find(item => {
          return value === item;
        })
      );
    });
    const totalValue = {
      size: data.content.reduce((prev, curr) => prev + curr.size, 0),
      weight: data.content.reduce((prev, curr) => prev + curr.weight, 0),
      binUnitsNumber: data.content.reduce(
        (prev, curr) => prev + curr.binUnitsNumber,
        0
      ),
      cost: data.content.reduce((prev, curr) => prev + curr.cost, 0)
    };
    const totalPlants = data.content.reduce(
      (prev, curr) => prev + curr.numberOfPlants,
      0
    );
    const tonnePerHa = totalValue.weight / totalValue.size;
    const costPerTonne = totalValue.cost / totalValue.weight;
    const costPerBin = totalValue.cost / totalValue.binUnitsNumber;
    const avgKgPerTree = (totalValue.weight * 1000) / totalPlants;
    const farms = [];
    const block = [];
    const patch = [];
    const crops = [];
    const varieties = [];
    const binUnits = [];
    if (farmsList && farmIds && farmIds.length) {
      const farmIdsList = farmIds && farmIds.map(Number);
      farms.push(
        farmsList.options &&
        farmsList.options
          .filter(({ value }) => farmIdsList.includes(value))
          .map(({ label }) => label)
      );
    }
    if (blockList && blockIds && blockIds.length) {
      const blockIdsList = blockIds && blockIds.map(Number);
      block.push(
        blockList.options &&
        blockList.options
          .filter(({ value }) => blockIdsList.includes(value))
          .map(({ label }) => label)
      );
    }
    if (patchList && patchIds && patchIds.length) {
      const patchIdsList = patchIds && patchIds.map(Number);
      patch.push(
        patchList.options &&
        patchList.options
          .filter(({ value }) => patchIdsList.includes(value))
          .map(({ label }) => label)
      );
    }
    if (cropsList && cropIds && cropIds.length) {
      const cropIdsList = cropIds && cropIds.map(Number);
      crops.push(
        cropsList.options &&
        cropsList.options
          .filter(({ value }) => cropIdsList.includes(value))
          .map(({ label }) => label)
      );
    }
    if (varietiesList && varietyIds && varietyIds.length) {
      const varietyIdsList = varietyIds && varietyIds.map(Number);
      varieties.push(
        varietiesList.options &&
        varietiesList.options
          .filter(({ value }) => varietyIdsList.includes(value))
          .map(({ label }) => label)
      );
    }
    if (binUnitsList && binUnitsIds && binUnitsIds.length) {
      const binUnitsIdsList = binUnitsIds && binUnitsIds.map(Number);
      binUnits.push(
        binUnitsList.options &&
        binUnitsList.options
          .filter(({ value }) => binUnitsIdsList.includes(value))
          .map(({ label }) => label)
      );
    }

    return (
      <div className={styles.reportWrapper}>
        <div>
          <div className={styles.reportHeaderRow}>
            <div className={styles.reportHeader}>
              Pick to pack - Harvest by area
            </div>
            <div className={styles.logoContainet}>
              <Logo width={140} height={60} />
            </div>
          </div>

          <Table basic="very">
            <Table.Header>
              <Table.Row>
                {from && to && (
                  <Table.HeaderCell className={styles.reportSubHeader}>
                    <div className={styles.dateRow}>Date Range</div>
                  </Table.HeaderCell>
                )}
                {!!farms.length && (
                  <Table.HeaderCell className={styles.reportSubHeader}>
                    Farm
                  </Table.HeaderCell>
                )}
                {!!block.length && (
                  <Table.HeaderCell className={styles.reportSubHeader}>
                    Block
                  </Table.HeaderCell>
                )}
                {!!patch.length && (
                  <Table.HeaderCell className={styles.reportSubHeader}>
                    Patch
                  </Table.HeaderCell>
                )}
                {!!crops.length && (
                  <Table.HeaderCell className={styles.reportSubHeader}>
                    Crop
                  </Table.HeaderCell>
                )}
                {!!varieties.length && (
                  <Table.HeaderCell className={styles.reportSubHeader}>
                    Variety
                  </Table.HeaderCell>
                )}
                {!!binUnits.length && (
                  <Table.HeaderCell className={styles.reportSubHeader}>
                    Box unit
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                {from && to && (
                  <Table.Cell className={styles.reportSubBody}>
                    <div className={styles.dateRow}>
                      {from ? `${moment(from).format("DD/MM/YY")}` : ""}-
                      {to ? `${moment(to).format("DD/MM/YY")}` : ""}
                    </div>
                  </Table.Cell>
                )}
                {!!farms.length && (
                  <Table.Cell className={styles.reportSubBody}>
                    {farms.join(",")}
                  </Table.Cell>
                )}
                {!!block.length && (
                  <Table.Cell className={styles.reportSubBody}>
                    {block.join(",")}
                  </Table.Cell>
                )}
                {!!patch.length && (
                  <Table.Cell className={styles.reportSubBody}>
                    {patch.join(",")}
                  </Table.Cell>
                )}
                {!!crops.length && (
                  <Table.Cell className={styles.reportSubBody}>
                    {crops.join(",")}
                  </Table.Cell>
                )}
                {!!varieties.length && (
                  <Table.Cell className={styles.reportSubBody}>
                    {varieties.join(",")}
                  </Table.Cell>
                )}
                {!!binUnits.length && (
                  <Table.Cell className={styles.reportSubBody}>
                    {binUnits.join(",")}
                  </Table.Cell>
                )}
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
        <div className={styles.totalRowTitle}>Total</div>
        <div className={styles.totalRowHeader}>
          {secondHeaderNewData &&
            Object.keys(secondHeaderNewData).map(head => {
              return (
                <div className={styles.cell}>
                  {secondHeaderNewData[`${head}`] !== "Box units" &&
                    secondHeaderNewData[`${head}`] !== "First Harvest" &&
                    secondHeaderNewData[`${head}`] !== "Last Harvest" &&
                    secondHeaderNewData[`${head}`] !== "Last WHP" &&
                    secondHeaderNewData[`${head}`]}
                </div>
              );
            })}
        </div>
        <div className={styles.totalRowBody}>
          {secondHeaderNewData &&
            Object.keys(secondHeaderNewData).map(head => {
              return (
                <div className={styles.cell}>
                  {head == "costPerTonne" ? (
                    <Numeric
                      fractionDigits={costPerTonne > 1000 ? 0 : 2}
                      value={costPerTonne}
                      commaSeparatorOnThousands
                      defaultValue="-"
                    />
                  ) : head == "costPerBin" ? (
                    <Numeric
                      fractionDigits={costPerBin > 1000 ? 0 : 2}
                      value={costPerBin}
                      commaSeparatorOnThousands
                      defaultValue="-"
                    />
                  ) : head == "tonnePerHa" ? (
                    <Numeric
                      fractionDigits={tonnePerHa > 1000 ? 0 : 2}
                      value={tonnePerHa}
                      commaSeparatorOnThousands
                      defaultValue="-"
                    />
                  ) : head == "avgKgPerTree" ? (
                    <Numeric
                      fractionDigits={avgKgPerTree > 1000 ? 0 : 2}
                      value={avgKgPerTree}
                      commaSeparatorOnThousands
                      defaultValue="-"
                    />
                  ) : head != "binUnits" &&
                    head != "firstBarcode" &&
                    head != "lastBarcode" &&
                    head != "withholdingPeriod" ? (
                    <Numeric
                      fractionDigits={totalValue[head] > 1000 ? 0 : 2}
                      value={totalValue[head]}
                      commaSeparatorOnThousands
                      defaultValue="-"
                    />
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
        </div>
        {tableData.length > 0 ? (
          tableData.map(item => (
            <>
              <Table basic="very" fixed={true} className={styles.reportTable}>
                <div className={styles.blockWrapper}>
                  <div className={styles.blockTableHeader}>
                    <Table.Header
                      fullWidth={true}
                      className={styles.blockHeaderTable}
                    >
                      {headerNewData &&
                        Object.keys(headerNewData).map(head => {
                          return (
                            <>
                              <Table.Cell
                                as="td"
                                className={styles.blockHeaderCellTable}
                              >
                                <div className={styles.tableHeader}>
                                  {" "}
                                  {headerNewData[`${head}`]}
                                </div>
                              </Table.Cell>
                              <Table.Cell
                                width={parseInt(
                                  16 / Object.keys(headerNewData).length
                                )}
                                as="td"
                                className={styles.blockHeaderValueCellTable}
                              >
                                <div className={styles.tableValue}>
                                  {head == "areaName" ? (
                                    item[`${head}`]
                                  ) : head == "crops" ? (
                                    item.crops && !!item.crops.length ? (
                                      item.crops.map(item => item).join(",  ")
                                    ) : (
                                      "-"
                                    )
                                  ) : item.varieties &&
                                    !!item.varieties.length ? (
                                    <span>
                                      {item[head].length > 1 &&
                                        item[head].length}
                                      {item[head].map(item => item).join(",  ")}
                                    </span>
                                  ) : (
                                    "-"
                                  )}
                                </div>
                              </Table.Cell>
                            </>
                          );
                        })}
                    </Table.Header>
                  </div>
                  <div className={styles.blockTableHeader}>
                    <Table.Header
                      fullWidth={true}
                      className={styles.blockHeaderTable}
                    >
                      {secondHeaderNewData &&
                        Object.keys(secondHeaderNewData).map(head => {
                          return (
                            <Table.Cell
                              width={1}
                              as="td"
                              className={styles.blockSecondHeaderCellTable}
                            >
                              <div className={styles.tableHeader}>
                                {secondHeaderNewData[`${head}`]}
                              </div>
                            </Table.Cell>
                          );
                        })}
                    </Table.Header>
                    <TableBody className={styles.blockBodyTable}>
                      {secondHeaderNewData &&
                        Object.keys(secondHeaderNewData).map(head => {
                          return (
                            <Table.Cell
                              as="td"
                              width={1}
                              className={styles.blockSecondCellTable}
                            >
                              <div className={styles.tableBody}>
                                {head === "binUnits" ? (
                                  item.binUnits ? (
                                    item.binUnits.length > 1 ? (
                                      `${item.binUnits.length} Types`
                                    ) : (
                                      item.binUnits[0]
                                    )
                                  ) : (
                                    "-"
                                  )
                                ) : head === "firstBarcode" ? (
                                  item.firstBarcode ? (
                                    moment(item.firstBarcode).format(
                                      "DD/MM/YYYY HH:mm"
                                    )
                                  ) : (
                                    "-"
                                  )
                                ) : head === "lastBarcode" ? (
                                  item.lastBarcode ? (
                                    moment(item.lastBarcode).format(
                                      "DD/MM/YYYY HH:mm"
                                    )
                                  ) : (
                                    "-"
                                  )
                                ) : head === "withholdingPeriod" ? (
                                  item.withholdingPeriod ? (
                                    moment(item.withholdingPeriod).format(
                                      "DD/MM/YYYY"
                                    )
                                  ) : (
                                    "-"
                                  )
                                ) : isFinite(item[head]) ? (
                                  <Numeric
                                    fractionDigits={item[head] > 2000 ? 0 : 2}
                                    value={item[head]}
                                    commaSeparatorOnThousands
                                    defaultValue="-"
                                  />
                                ) : (
                                  "-"
                                )}
                              </div>
                            </Table.Cell>
                          );
                        })}
                    </TableBody>
                  </div>
                </div>
              </Table>
              {item.subRows &&
                item.subRows.length > 0 &&
                item.subRows.map(
                  patch =>
                    patch.isSubRows && (
                      <Table
                        basic="very"
                        fixed={true}
                        className={styles.reportTable}
                      >
                        <div className={styles.patchWrapper}>
                          <div className={styles.patchTableHeader}>
                            <Table.Header
                              fullWidth={true}
                              className={styles.patchHeaderTable}
                            >
                              {headerNewData &&
                                Object.keys(headerNewData).map(head => {
                                  return (
                                    <>
                                      <Table.Cell
                                        as="td"
                                        className={styles.patchHeaderCellTable}
                                      >
                                        <div className={styles.tableHeader}>
                                          {" "}
                                          {headerNewData[`${head}`]}
                                        </div>
                                      </Table.Cell>
                                      <Table.Cell
                                        as="td"
                                        width={parseInt(
                                          16 / Object.keys(headerNewData).length
                                        )}
                                        className={
                                          styles.patchHeaderValueCellTable
                                        }
                                      >
                                        <div className={styles.tableValue}>
                                          {head == "areaName" ? (
                                            patch[`${head}`]
                                          ) : head == "crops" ? (
                                            patch.crops &&
                                              !!patch.crops.length ? (
                                              patch.crops
                                                .map(patch => patch)
                                                .join(",  ")
                                            ) : (
                                              "-"
                                            )
                                          ) : patch.varieties &&
                                            !!patch.varieties.length ? (
                                            <span>
                                              {patch[head].length > 1 &&
                                                patch[head].length}
                                              {patch[head]
                                                .map(patch => patch)
                                                .join(",  ")}
                                            </span>
                                          ) : (
                                            "-"
                                          )}
                                        </div>
                                      </Table.Cell>
                                    </>
                                  );
                                })}
                            </Table.Header>
                          </div>
                          <Table.Header
                            fullWidth={true}
                            className={styles.patchHeaderTable}
                          >
                            {secondHeaderNewData &&
                              Object.keys(secondHeaderNewData).map(head => {
                                return (
                                  <Table.Cell
                                    as="td"
                                    width={1}
                                    className={
                                      styles.patchSecondHeaderCellTable
                                    }
                                  >
                                    <div className={styles.tableHeader}>
                                      {secondHeaderNewData[`${head}`]}
                                    </div>
                                  </Table.Cell>
                                );
                              })}
                          </Table.Header>
                          <TableBody className={styles.patchBodyTable}>
                            {secondHeaderNewData &&
                              Object.keys(secondHeaderNewData).map(head => {
                                return (
                                  <Table.Cell
                                    as="td"
                                    width={1}
                                    className={styles.patchSecondCellTable}
                                  >
                                    <div className={styles.tableBody}>
                                      {head === "binUnits" ? (
                                        patch.binUnits ? (
                                          patch.binUnits.length > 1 ? (
                                            `${patch.binUnits.length} Types`
                                          ) : (
                                            patch.binUnits[0]
                                          )
                                        ) : (
                                          "-"
                                        )
                                      ) : head === "firstBarcode" ? (
                                        patch.firstBarcode ? (
                                          moment(patch.firstBarcode).format(
                                            "DD/MM/YYYY HH:mm"
                                          )
                                        ) : (
                                          "-"
                                        )
                                      ) : head === "lastBarcode" ? (
                                        patch.lastBarcode ? (
                                          moment(patch.lastBarcode).format(
                                            "DD/MM/YYYY HH:mm"
                                          )
                                        ) : (
                                          "-"
                                        )
                                      ) : head === "withholdingPeriod" ? (
                                        patch.withholdingPeriod ? (
                                          moment(
                                            patch.withholdingPeriod
                                          ).format("DD/MM/YYYY")
                                        ) : (
                                          "-"
                                        )
                                      ) : isFinite(patch[head]) ? (
                                        <Numeric
                                          fractionDigits={
                                            patch[head] > 2000 ? 0 : 2
                                          }
                                          value={patch[head]}
                                          commaSeparatorOnThousands
                                          defaultValue="-"
                                        />
                                      ) : (
                                        "-"
                                      )}
                                    </div>
                                  </Table.Cell>
                                );
                              })}
                          </TableBody>
                        </div>
                      </Table>
                    )
                )}
            </>
          ))
        ) : (
          <Header as="h4">Nothing found</Header>
        )}
      </div>
    );
  }
}

PrintReports.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object.isRequired,
  showExpandedData: PropTypes.bool,
  startDate: PropTypes.any,
  endDate: PropTypes.any
};

export default PrintReports;
