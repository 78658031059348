import React from "react";
import filterTypes from "components/Table/filterTypes";
import BasicCell from "components/Table/components/BasicCell";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import { areaFilterOptions, varietiesOptionSelector } from "selectors/areas";
import { Link } from "react-router-dom";
import moment from "moment";
import { Chip } from "routes/Reports/Harvest/components/Chip";
import styles from "../routes/Tasks/Tasks.module.css";
import { Checkbox, Dropdown, Icon, Popup } from "semantic-ui-react";
import { priorityIcon } from "components/Table/helpers";
import { employeeViewer } from "routes/Tasks/components/helper";
import { farmsOptionsFilterSelector } from "selectors/farms";
import {
  tasksPriorityFilter,
  generalTasksPaymentMethod,
  generalTasksAssignTo,
  supervisorsFilterOptions
} from "selectors/generalTasks";
import { cropsOptionsSelector } from "selectors/crops";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";
export const getGeneralTasksTableColumns = (
  onRowClick,
  handleTaskIdClick,
  filters,
  onTaskPrint,
  onDeleteClick,
  isArchivedView,
  activeTab,
  onArchiveClick,
  employeeList,
  areasList,
  isFetching,
  selectMode,
  handleSelectChange,
  selectedItemsIds,
  pageSize,
  handleSelectAllItem,
  navigate
) => {
  const columnsConfig = {
    percent: {
      Cell: ({ _, row }) => {
        const localTime = moment().format("YYYY-MM-DDTHH:mm:ss");
        const allAreas = row.original.areas;
        const completedAreas = allAreas.filter(item => item.completed);
        const progressPrecent = Math.ceil(
          (completedAreas.length * 100) / allAreas.length
        );
        return (
          <div
            style={{
              width: "50px",
              paddingLeft: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor:
                activeTab === "OPEN" && row.original.expireAt > localTime
                  ? "#9DACBA"
                  : activeTab === "IN_PROGRESS" &&
                    row.original.expireAt > localTime
                  ? "#F0AC47"
                  : activeTab === "COMPLETED"
                  ? "#59B655"
                  : "#D92D20",
              height: "100%",
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
              color: "white",
              fontSize: "12px",
              fontFamily: "Roboto"
            }}
          >
            {selectMode ? (
              <Checkbox
                className={styles.lineCheckbox}
                checked={selectedItemsIds.includes(row.original.id)}
                onChange={() => handleSelectChange(row.original.id)}
              />
            ) : (activeTab === "OPEN" && row.original.expireAt < localTime) ||
              (activeTab === "IN_PROGRESS" &&
                row.original.expireAt < localTime) ? (
              "Exp"
            ) : (
              `${progressPrecent}%`
            )}
          </div>
        );
      },
      withSort: false,
      type: "string",
      id: "percent",
      title: () => {
        return (
          selectMode &&
          (!!selectedItemsIds.length && selectedItemsIds.length !== pageSize ? (
            <div
              className={styles.lineCheckboxMinus}
              onClick={() => handleSelectAllItem()}
            >
              <Icon className={styles.minusIcon} name="minus" />
            </div>
          ) : (
            <Checkbox
              className={styles.lineAllCheckbox}
              checked={selectedItemsIds.length === pageSize}
              onChange={() => handleSelectAllItem()}
            />
          ))
        );
      },
      filterId: "date",
      filter: {
        title: "Date",
        type: filterTypes.DateRangeSelect,
        selector: <DateRangeSelect loading={isFetching} title="Date" />
      },
      withSort: false,
      cellWidth: 1
    },
    nameId: {
      accessor: "id",
      Cell: ({ _, row }) => {
        return (
          <span style={{ height: "50px" }}>
            {row.original.name}
            <p onClick={() => handleTaskIdClick(row.original.id)}>
              <Link to={`/tasks/slashing/${row.original.id}`}>
                GT-{row.original.id}
              </Link>
            </p>
          </span>
        );
      },
      withSort: true,
      type: "string",
      id: "name",
      title: "Name, ID",
      filterId: "seasons",
      filter: {
        title: "Season",
        type: filterTypes.SingleSelect,
        selector: seasonsOptionsSelector
      },

      cellWidth: 2
    },
    /*   show: {
      withSort: false,
      accessor: "seenstatus",
      Cell: ({ _, row }) => {
        return (
          <span
            style={{
              cursor: "pointer",
              display: "flex",
              height: "25px",
              width: "25px",
              borderRadius: "100%",
              backgroundColor: 5 > 4 ? "#F0AC47" : "#9DACBA",
              paddingTop: "4px",
              justifyContent: "center",
              color: "white"
            }}
          >
            <Icon
              onClick={() => {}}
              style={{ fontSize: "15px" }}
              name={5 > 4 ? "eye" : "eye slash"}
            />
          </span>
        );
      },
      type: "string",
      id: "show",
      title: "",
      cellWidth: 1
    }, */
    method: {
      withSort: false,
      id: "method",
      type: "string",
      title: "Method",
      accessor: ({ paymentMethod }) =>
        (
          paymentMethod
            ?.toLowerCase()
            .charAt(0)
            .toUpperCase() + paymentMethod?.toLowerCase().slice(1)
        ).replace(/_/g, " ") || "-",
      filterId: "paymentMethod",
      filter: {
        title: "Method",
        type: filterTypes.SingleSelect,
        selector: generalTasksPaymentMethod
      },
      cellWidth: 1
    },
    empty: {
      accessor: "",
      withSort: false,
      id: "",
      title: "",
      Cell: ({ _, row }) => {
        return <></>;
      },
      filterId: "areaId",
      filter: {
        title: "Area",
        type: filterTypes.MultiSelect,
        selector: areaFilterOptions
      }
    },
    date: {
      accessor: "startAt",
      withSort: true,
      type: "date",
      id: "compositeEnd",
      title: "Date",
      Cell: ({ _, row }) => {
        return `${moment(row.original.startAt).format("DD/MM/YYYY")}-${moment(
          row.original.endAt
        ).format("DD/MM/YYYY")}`;
      },
      filterId: "farmId",
      filter: {
        title: "Farm",
        type: filterTypes.MultiSelect,
        selector: farmsOptionsFilterSelector
      },
      cellWidth: 3
    },
    farm: {
      withSort: false,
      type: "string",
      id: "farmId",
      title: "Farm",
      accessor: ({ areas }) => {
        const filtredArea = areasList.filter(item =>
          areas.some(area => area.areaId === item.id)
        );
        const filtredFarm = filtredArea.map(item => item.farm);
        const uniqueArray = Array.from(
          new Set(filtredFarm.map(obj => obj.id))
        ).map(id => filtredFarm.find(item => item.id === id));

        return uniqueArray.length > 1 ? (
          <Chip
            color="green"
            label="Farms"
            items={uniqueArray.map(area => area.name)}
          />
        ) : (
          uniqueArray[0]?.name || "-"
        );
      },
      filterId: "priority",
      filter: {
        title: "Priority",
        type: filterTypes.SingleSelect,
        selector: tasksPriorityFilter
      },
      cellWidth: 2
    },
    areas: {
      withSort: false,
      type: "string",
      id: "areaId",
      title: "Area",
      accessor: ({ areas }) => (
        <Chip
          color="green"
          label="Areas"
          items={areasList
            .filter(item => areas.map(i => i.areaId).includes(item.id))
            .map(area => area.name)}
        />
      ),
      filterId: "supervisorIds",
      filter: {
        title: "Supervisor",
        type: filterTypes.MultiSelect,
        selector: supervisorsFilterOptions
      },
      cellWidth: 1
    },
    prioritet: {
      withSort: false,
      type: "string",
      id: "prioritet",
      title: "Priority",
      accessor: "priority",
      Cell: ({ _, value }) => {
        return priorityIcon(value);
      },
      filterId: "assignedEmployeeId",
      filter: {
        title: "Assignees",
        type: filterTypes.MultiSelect,
        selector: generalTasksAssignTo
      },
      cellWidth: 1
    },
    supervisors: {
      withSort: false,
      type: "string",
      id: "supervisors",
      title: "Supervisors",
      accessor: ({ supervisors }) => employeeViewer(supervisors, employeeList),
      filterId: "cropIds",
      filter: {
        title: "Crop",
        type: filterTypes.MultiSelect,
        selector: cropsOptionsSelector
      },
      cellWidth: 2
    },
    assignees: {
      withSort: false,
      type: "string",
      id: "assignees",
      title: "Assignees",
      accessor: ({ assignedTo }) => employeeViewer(assignedTo, employeeList),
      filterId: "varietyIds",
      filter: {
        title: "Variety",
        type: filterTypes.MultiSelect,
        selector: state => varietiesOptionSelector(filters)(state)
      },
      cellWidth: 2
    },
    setting: {
      withSort: false,
      type: "string",
      id: "setting",
      title: "",
      accessor: "id",
      Cell: ({ _, value }) => {
        return (
          <Dropdown
            style={{
              width: "30px",
              height: "30px",
              backgroundColor: "#F7F7F7",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              paddingBottom: "5px",
              paddingLeft: "7px",
              borderRadius: "100%",
              color: "#0E9354"
            }}
            icon="ellipsis horizontal"
            iconposition="right"
            floating
          >
            <Dropdown.Menu direction="left">
              <Dropdown.Item
                onClick={async () => (
                  await onRowClick(value), navigate("/tasks/add")
                )}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onTaskPrint(value)}>
                Print
              </Dropdown.Item>
              <Dropdown.Item onClick={() => onArchiveClick(value)}>
                {!isArchivedView ? "Archive" : "Restore"}
              </Dropdown.Item>
              {isArchivedView && (
                <Dropdown.Item onClick={() => onDeleteClick(value)}>
                  Delete
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
      filterId: "expiryDate",
      filter: {
        title: "Expiry date",
        type: filterTypes.DateRangeSelect,
        selector: <DateRangeSelect title="Expiry date" />
      },
      cellWidth: 1
    }
  };

  return Object.entries(columnsConfig).map(([id, targetColumn]) => ({
    id,
    ...targetColumn,
    accessor: targetColumn.accessor || id,
    Header: targetColumn.title,
    Cell: targetColumn.Cell || BasicCell,
    ...(targetColumn.sortFields ? { sortFields: targetColumn.sortFields } : {}),
    ...(targetColumn.filter ? { filter: targetColumn.filter } : {})
  }));
};
