import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Message, Modal, Segment } from "semantic-ui-react";

import SelectChemicalsForm from "./SelectChemicalsForm";
import AddChemicalModal from "../AddChemicalModal/AddChemicalModal";
import {
  addChemicalItem,
  declineEditingChemicalItem,
  deleteChemicalItem,
  onEditChemicalItem,
  setAddChemicalSelection,
  setChemicalFromList,
  setChemicalItemToAdd
} from "../../../../../actions/SprayDiary/chemicals";

import styles from "./SelectChemicals.module.css";
import PropTypes from "prop-types";
import { setSprayDiaryField } from "../../../../../actions/SprayDiary/common";
import { selectedChemicalsSelector } from "../../../../../selectors/sprayDiary";

class SelectChemicals extends React.Component {
  state = {
    showAddChemicalModal: false,
    chemicalIdToDelete: null,
    showDeleteModal: false
  };

  onSelectChemical = (id, value) => {
    const { actions } = this.props;
    if (value >= 0) {
      return actions.setChemicalFromList(id, value);
    }
    this.toggleModal();
  };

  toggleModal = () => {
    this.setState({ showAddChemicalModal: !this.state.showAddChemicalModal });
  };

  deleteChemicalItem = chemicalIdToDelete => {
    this.setState({
      chemicalIdToDelete,
      showDeleteModal: true
    });
  };

  hideDeleteModal = () => {
    this.setState({
      chemicalIdToDelete: null,
      showDeleteModal: false
    });
  };

  handleDeleteChemical = () => {
    const { actions } = this.props;
    const { chemicalIdToDelete } = this.state;
    actions.deleteChemicalItem(chemicalIdToDelete);
    this.hideDeleteModal();
  };

  render() {
    const { selectedChemicals, status, plannedDate } = this.props;
    const { showDeleteModal, showAddChemicalModal } = this.state;
    const gotEmptyBatchNum = selectedChemicals.find(
      chemical => chemical.batchRequired && !chemical.batchNumbers?.length
    );

    return (
      <React.Fragment>
        <AddChemicalModal
          open={showAddChemicalModal}
          onClose={this.toggleModal}
          onSelectChemical={this.onSelectChemical}
        />
        <Modal
          size="mini"
          open={showDeleteModal}
          onClose={this.hideDeleteModal}
        >
          <Modal.Header>Delete Chemical</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete this Chemical?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.hideDeleteModal}>No</Button>
            <Button positive onClick={this.handleDeleteChemical}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
        <SelectChemicalsForm
          {...this.props}
          showArchived={this.props.isPreviouslyCreatedTask}
          plannedDate={plannedDate}
          deleteChemicalItem={this.deleteChemicalItem}
          onSelectChemical={this.onSelectChemical}
        />
        {gotEmptyBatchNum && status === "COMPLETED" && (
          <Segment basic className={styles.messageHolder}>
            <Message color="red">
              You can&lsquo;t set task to Complete before add required Batch No.
              in chemical: {gotEmptyBatchNum.name}
            </Message>
          </Segment>
        )}
      </React.Fragment>
    );
  }
}

SelectChemicals.propTypes = {
  actions: PropTypes.object,
  validations: PropTypes.object,
  selectedChemicals: PropTypes.array,
  status: PropTypes.string,
  isPreviouslyCreatedTask: PropTypes.bool
};

const mapStateToProps = state => {
  const {
    sprayDiary: {
      selectedMachinery: { id },
      plannedDate,
      chemicalToAdd,
      status,
      addChemicalSelection,
      validations,
      selectedAreas,
      rowsToSpray,
      widthPerRow,
      literPerHectare,
      conc
    }
  } = state;
  return {
    selectedMachinery: id,
    selectedChemicals: selectedChemicalsSelector(state),
    plannedDate,
    chemicalToAdd,
    status,
    addChemicalSelection,
    validations,
    selectedAreas,
    rowsToSpray,
    widthPerRow,
    literPerHectare,
    conc
  };
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setChemicalItemToAdd,
      deleteChemicalItem,
      addChemicalItem,
      onEditChemicalItem,
      declineEditingChemicalItem,
      setChemicalFromList,
      setAddChemicalSelection,
      setSprayDiaryField
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectChemicals);
