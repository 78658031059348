import React, { useState } from "react";
import {
  Segment,
  Grid,
  Icon,
  Header,
  Message,
  Container,
  Button,
  TextArea,
  Popup
} from "semantic-ui-react";
import { get } from "lodash";
import classNames from "classnames";
import { FastField, Formik } from "formik";
import styles from "./RecommendationSidebar.module.css";

const AdditionalInfoTab = ({ currentTask, onDeleteAttachment, onEditTask }) => {
  const [isCommentEditing, setIsCommentEditing] = useState(false);
  const { attachments } = currentTask;
  const attachmentsShown = !!get(attachments, "length");

  const onSubmit = values => {
    onEditTask(values).then(() => {
      setIsCommentEditing(false);
    });
  };

  return (
    <>
      {attachmentsShown && (
        <Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header
                  size="big"
                  className={classNames("bold", styles.editableHeader)}
                >
                  Attachments
                </Header>

                {attachments.map(attachment => (
                  <Message
                    as={Segment}
                    clearing
                    className={styles.attachmentCard}
                    key={attachment.id}
                  >
                    <Header
                      as="a"
                      color="green"
                      href={attachment.presignedUrl}
                      download
                      target="_blank"
                      size="small"
                      floated="left"
                    >
                      {attachment.name}
                    </Header>

                    <Header floated="right" size="small">
                      <Icon
                        name="close"
                        color="green"
                        className={styles.actionIcon}
                        onClick={() => onDeleteAttachment(attachment.id)}
                      />
                    </Header>
                  </Message>
                ))}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      )}

      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Container fluid className={styles.editableHeader}>
                <Header size="big" className="bold" style={{ marginBottom: 0 }}>
                  Comments
                </Header>
                {!isCommentEditing && (
                    <Popup
                    inverted
                    position="bottom center"
                    content={"Edit"}
                    size="tiny"
                    trigger={
                      <div className={styles.editButtonWrapper}>
                        <Button
                          onClick={() => {
                            setIsCommentEditing(true);
                          }}
                          className={styles.editButton}
                          floated="right"
                          icon={<Icon name="edit" />}
                          size="small"
                        />
                      </div>
                    }
                  />
                )}
              </Container>

              {isCommentEditing ? (
                <Formik initialValues={currentTask} onSubmit={onSubmit}>
                  {props => (
                    <>
                      <Grid.Row className="py-10" verticalAlign="middle">
                        <FastField
                          fluid
                          as={TextArea}
                          name="comment"
                          className={styles.textarea}
                        />
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Button
                            onClick={() => {
                              setIsCommentEditing(false);
                            }}
                            disabled={props.isSubmitting}
                            className={styles.cancelBtn}
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={
                              !props.dirty ||
                              !props.isValid ||
                              props.isSubmitting
                            }
                            onClick={props.handleSubmit}
                            primary
                          >
                            Save
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </>
                  )}
                </Formik>
              ) : (
                <Header size="small">{currentTask.comment}</Header>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  );
};

export default AdditionalInfoTab;
