import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef
} from "react";
import SignaturePad from "signature_pad";
import styles from "./SignatureGenerator.module.css";
import { useDispatch } from "react-redux";

const SignaturePadComponent = forwardRef(
  ({ signatureUrl, signatureDelete, onSignatureChange, activeTab }, ref) => {
    const canvasRef = useRef(null);
    const signaturePadRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
      const canvas = canvasRef.current;
      const signaturePad = new SignaturePad(canvas);
      signaturePadRef.current = signaturePad;
    }, []);

    useEffect(() => {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      if (signatureUrl) {
        const image = new Image();
        image.crossOrigin = "anonymous";
        image.onload = () => {
          context.clearRect(0, 0, canvas.width, canvas.height);
          const scaleX = canvas.width / image.width;
          const scaleY = canvas.height / image.height;
          const scale = Math.min(scaleX, scaleY);
          const x = (canvas.width - image.width * scale) / 2;
          const y = (canvas.height - image.height * scale) / 2;
          context.drawImage(
            image,
            x,
            y,
            image.width * scale,
            image.height * scale
          );
        };
        image.src = signatureUrl;
      }
    }, [signatureUrl, activeTab]);

    const clearSignature = () => {
      if (signaturePadRef.current) {
        signaturePadRef.current.clear();
        dispatch(signatureDelete());
        if (onSignatureChange) {
          onSignatureChange(true);
        }
      }
    };

    const getSignatureData = () => {
      if (signaturePadRef.current) {
        return signaturePadRef.current.toDataURL();
      }
      return null;
    };

    useImperativeHandle(ref, () => ({
      clearSignature,
      getSignatureData
    }));

    return (
      <div className={styles.canvasWrapper}>
        <span onClick={() => onSignatureChange(false)}>
          <canvas
            ref={canvasRef}
            className={styles.pad}
            width={398}
            height={200}
          ></canvas>
        </span>
        <div className={styles.padClearButtonWrapper}>
          <button className={styles.padClearButton} onClick={clearSignature}>
            Clear
          </button>
        </div>
      </div>
    );
  }
);

export default SignaturePadComponent;
