import React from "react";
import { Icon, Table as SemanticTable, Popup } from "semantic-ui-react";
import classNames from "classnames";
import styles from "./Table.module.css";
import logo from "assets/img/symbol_eb.svg";
const TableLine = ({
  activeTab,
  basic,
  withoutSort,
  className,
  fixed,
  onRowClick,
  rowProps,
  striped,
  tableInstance,
  singleLine
}) => {
  const { getTableBodyProps, headerGroups, prepareRow, rows } = tableInstance;
  const status =
    activeTab === "OPEN"
      ? "open"
      : activeTab === "IN_PROGRESS"
      ? "in progress"
      : activeTab === "COMPLETED"
      ? " completed"
      : "expired";
  return (
    <>
      {!!rows.length ? (
        <SemanticTable
          basic={basic}
          fixed={fixed}
          singleLine={singleLine}
          className={classNames(className, styles.taskTable)}
          striped={striped}
        >
          <SemanticTable.Header>
            {headerGroups.map((headerGroup, headerGroupIndex) => (
              <>
                <SemanticTable.Row
                  key={`headerGroup_${headerGroupIndex}`}
                  className={classNames(className, styles.taskHeaderRow)}
                  verticalAlign="middle"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column, columnIndex) => {
                    return !withoutSort && column.withSort ? (
                      <Popup
                        key={columnIndex}
                        inverted
                        trigger={
                          <SemanticTable.HeaderCell
                            className={styles.taskHeaderCell}
                            {...(column.disableSortBy
                              ? column.getHeaderProps()
                              : column.getHeaderProps(
                                  column.getSortByToggleProps()
                                ))}
                            title={""}
                          >
                            <div
                              className={classNames({
                                [styles.sorted]: column.isSorted,
                                [styles.sortableHeader]: !column.disableSortBy
                              })}
                            >
                              {column.onlyTable ? "" : column.render("Header")}
                              {!column.disableSortBy && (
                                <span className={styles.sortingIcon}>
                                  {!column.disableSortBy &&
                                    (!column.isSorted ? (
                                      <Icon name="arrow up" />
                                    ) : column.isSortedDesc ? (
                                      <Icon name="arrow up" />
                                    ) : (
                                      <Icon name="arrow down" />
                                    ))}
                                </span>
                              )}
                            </div>
                          </SemanticTable.HeaderCell>
                        }
                        content={
                          !column.isSorted
                            ? "Sort  A - Z"
                            : column.isSortedDesc
                            ? "Sorted Z - A "
                            : "Sorted A - Z "
                        }
                        position="top left"
                      />
                    ) : (
                      <SemanticTable.HeaderCell
                        key={columnIndex}
                        className={styles.taskHeaderCell}
                      >
                        <div
                          className={classNames(
                            withoutSort && styles.withoutSort,
                            {
                              [styles.sorted]: column.isSorted,
                              [styles.sortableHeader]: !column.disableSortBy
                            }
                          )}
                        >
                          {column.onlyTable ? null : column.render("Header")}
                        </div>
                      </SemanticTable.HeaderCell>
                    );
                  })}
                </SemanticTable.Row>
              </>
            ))}
          </SemanticTable.Header>
          <SemanticTable.Body {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row);
              return (
                <SemanticTable.Row
                  key={rowIndex}
                  verticalAlign="middle"
                  className={styles.taskTableBodyRow}
                  onClick={event => {
                    onRowClick(event, row);
                  }}
                  {...row.getRowProps()}
                  {...rowProps}
                >
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <SemanticTable.Cell
                        className={styles.taskTableBodyCell}
                        key={`cell_${cellIndex}`}
                      >
                        {cell.render("Cell")}
                      </SemanticTable.Cell>
                    );
                  })}
                </SemanticTable.Row>
              );
            })}
          </SemanticTable.Body>
        </SemanticTable>
      ) : (
        <div className={styles.emptyTableWrapper}>
          <img src={logo} />
          <span className={styles.emptyTableText}>
            {` You have no tasks ${status} now.`}
          </span>
        </div>
      )}
    </>
  );
};

export default TableLine;
