import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { valueToString } from "utils/tasksUtils";
import SelectChemicalDropdown from "./SelectChemicalDropdown";
import {
  ChemicalTargetDropdown,
  InterventionTypeDropdown
} from "routes/Recommendations/components/FormElements";
import { Button, Icon, Input, Message } from "semantic-ui-react";
import { formatInputFloat } from "utils/validationRules";
import { matterToUnits } from "utils/constToUnits";
import { RATE_TYPES } from "constants/shared";
import Numeric from "components/Numeric";
import DatePicker from "components/DatePicker";
import { setSprayDiaryField } from "actions/SprayDiary/common";
import {
  addChemicalItem,
  declineEditingChemicalItem,
  setAddChemicalSelection,
  setChemicalItemToAdd
} from "actions/SprayDiary/chemicals";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import styles from "./SelectChemicalsForm.module.css";

const AddTableItem = ({
  item,
  onSelectChemical,
  addButtonAttentionAnimationActivation,
  validation,
  actions: {
    addChemicalItem,
    setChemicalItemToAdd,
    declineEditingChemicalItem,
    setAddButtonAttentionAnimationActivation,
    setAddChemicalSelection
  }
}) => {
  const { values, setFieldValue } = useFormikContext();
  const { machineryId: machinery } = values;

  const gotRate =
    item.rate.rate >= 0 ||
    (item.rate.minRate >= 0 && !(item.rate.maxRate >= 0)) ||
    (item.rate.minRate >= 0 &&
      item.rate.maxRate >= 0 &&
      item.rate.minRate === item.rate.maxRate);
  const disabledButton =
    !item.name || !gotRate || !valueToString(item.rate.rate);
  const id = item.new ? "new" : item.id;

  return (
    <>
      <tr className={item.new ? styles.newItem : styles.editItem}>
        <td className={styles.addTitle}>Add Chemical to the Task</td>
        <td className={styles.chemicalCell}>
          <div className={styles.title}>Name</div>
          <div>
            <SelectChemicalDropdown
              id={id}
              value={item.id}
              onChange={onSelectChemical}
            />
          </div>
        </td>
        <td className={styles.chemicalTypeCell}>
          <div className={styles.title}>Type</div>
          <div>{item.type ? item.type.type : "—"}</div>
        </td>
        <td className={styles.cropAndPurposeCell}>
          <div className={styles.title}>Target</div>
          <div>
            <ChemicalTargetDropdown
              error={!validation && !item.rate.id}
              chemical={item}
              onChange={rate => {
                setChemicalItemToAdd(id, { ...item, rate });
              }}
            />
          </div>
        </td>
        <td className={styles.cropAndPurposeCell}>
          <div className={styles.title}>Intervention Type</div>
          <InterventionTypeDropdown
            value={values.interventionType}
            onChange={value => setFieldValue("interventionType", value)}
            disabled={!item.name}
          />
        </td>
        <td className={styles.dilutionCell}>
          <div className={styles.title}>Rate</div>
          <div>
            <Input
              fluid
              disabled={!(item.rate && item.rate.id)}
              onChange={e =>
                setChemicalItemToAdd(id, {
                  ...item,
                  rate: {
                    ...item.rate,
                    rate: formatInputFloat(e.target.value)
                  }
                })
              }
              value={item.rate.rate || ""}
              label={{
                content: matterToUnits(item.matter),
                inverted: "true"
              }}
              labelPosition="right"
              className={styles.inputRate}
              error={!validation && !valueToString(item.rate.rate)}
            />
          </div>
        </td>
        <td>
          <div className={styles.title}>Rate Type</div>
          {RATE_TYPES[item.rate.rateType] || "—"}
        </td>
        <td>
          <div className={styles.title}>
            {machinery ? "Qty Per Full Tank" : "Full Qty"}
          </div>
          <Numeric value={item.fullTanks} units={matterToUnits(item.matter)} />
        </td>
        {machinery && (
          <td>
            <div className={styles.title}>Qty Per Part Tank</div>
            <Numeric
              value={item.partTanks}
              units={matterToUnits(item.matter)}
            />
          </td>
        )}
        <td className={styles.batchNumberCell}>
          <div className={styles.title}>Batch No.</div>
          <div>
            <Input
              fluid
              disabled={!item.name}
              value={item.batchNumber}
              onChange={e =>
                setChemicalItemToAdd(id, {
                  ...item,
                  batchNumber: e.target.value
                })
              }
            />
          </div>
        </td>
        <td className={styles.dateOfManufactureCell}>
          <div className={styles.title}>Date of Manufacture</div>
          <DatePicker
            value={item.dateOfManufacture}
            onChange={date =>
              setChemicalItemToAdd(id, {
                ...item,
                dateOfManufacture: date
              })
            }
            size="small"
          />
        </td>
        <td>
          {item.new ? (
            <div className={styles.buttonsNew}>
              <Button
                disabled={disabledButton}
                primary
                className={
                  !disabledButton && addButtonAttentionAnimationActivation
                    ? styles.addChemicalValidation
                    : null
                }
                onAnimationEnd={() => {
                  setAddButtonAttentionAnimationActivation(false);
                }}
                onClick={() => {
                  addChemicalItem(id);
                  setAddChemicalSelection(false);
                }}
              >
                + Add
              </Button>
              <Button className={styles.cancelBtn} onClick={() => declineEditingChemicalItem(id)}>
                Cancel
              </Button>
            </div>
          ) : (
            <div className={styles.buttonsNew}>
              <Button
                onClick={() => (disabledButton ? null : addChemicalItem(id))}
                disabled={disabledButton}
                color={disabledButton ? "grey" : "green"}
              >
                Update
              </Button>
              <Button
                onClick={() => declineEditingChemicalItem(id)}
                className={styles.buttonCancel}
              >
                Cancel
              </Button>
            </div>
          )}
        </td>
      </tr>
      {item && item.id && (
        <tr className={styles.message}>
          <td colSpan={9}>
            <Message color="blue">
              <Icon name="warning" /> The chemicals you have selected have{" "}
              <strong>{item.reentry} day(s)</strong> of Re-Entry{" "}
              {item.rate && item.rate.withholdingPeriod >= 0 && (
                <span>
                  and {<strong>{item.rate.withholdingPeriod} day(s)</strong>} of
                  Withholding
                </span>
              )}
              Period
            </Message>
          </td>
        </tr>
      )}
    </>
  );
};

AddTableItem.propTypes = {
  addChemicalSelection: PropTypes.bool,
  selectedChemicals: PropTypes.array,
  validation: PropTypes.bool,
  addButtonAttentionAnimationActivation: PropTypes.bool,
  actions: PropTypes.object,
  item: PropTypes.object,
  onSelectChemical: PropTypes.func
};

const mapStateToProps = ({
  sprayDiary: {
    addChemicalSelection,
    selectedChemicals,
    validations: {
      selectedChemicals: selectedChemicalsValidation,
      addChemicalSelection: addChemicalSelectionValidation
    },
    addButtonAttentionAnimationActivation
  }
}) => ({
  addChemicalSelection,
  selectedChemicals,
  validation: selectedChemicalsValidation && addChemicalSelectionValidation,
  addButtonAttentionAnimationActivation
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addChemicalItem,
      setChemicalItemToAdd,
      declineEditingChemicalItem,
      setAddChemicalSelection,
      setAddButtonAttentionAnimationActivation: active =>
        setSprayDiaryField({
          fieldName: "addButtonAttentionAnimationActivation",
          fieldValue: active
        })
    },
    dispatch
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTableItem);
