import React, { useState } from "react";
import {
  Segment,
  Grid,
  Header,
  Container,
  Button,
  Modal
} from "semantic-ui-react";
import classNames from "classnames";
import { getChemicalsInformationData } from "../../../utils";
import { Formik } from "formik";
import ProductsInformationSegment from "./ProductsInformationSegment";
import { get } from "lodash/object";
import styles from "./RecommendationSidebar.module.css";

const ProductsInformationForm = ({ currentTask, onEditTask }) => {
  const [editingChemicalIndex, setEditingChemicalIndex] = useState(null);
  const [chemicalToDeleteIndex, setChemicalToDeleteIndex] = useState(null);
  const chemicalsInformationData = getChemicalsInformationData(currentTask);
  const chemicalToDeleteName = get(
    currentTask,
    `chemicals[${chemicalToDeleteIndex}].chemical.name`
  );
  const onDeleteChemicalConfirm = () => {
    onEditTask({
      ...currentTask,
      chemicals: currentTask.chemicals.filter(
        (_, index) => index !== chemicalToDeleteIndex
      )
    });
  };

  return (
    <>
      <Modal size="mini" open={!!chemicalToDeleteName}>
        <Modal.Header>
          Are you sure you want to delete {chemicalToDeleteName} from the task?
        </Modal.Header>
        <Modal.Content>
          <p>
            After deleting {chemicalToDeleteName} there won’t be a possibility
            to restore it.
          </p>
          <Container fluid className={styles.buttonsContainer}>
            <Button className={styles.cancelBtn} onClick={() => setChemicalToDeleteIndex(null)}>
              No
            </Button>
            <Button primary onClick={onDeleteChemicalConfirm}>
              Yes
            </Button>
          </Container>
        </Modal.Content>
      </Modal>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header
                size="big"
                className={classNames("bold", styles.editableHeader)}
              >
                Products
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Formik
            enableReinitialize
            initialValues={currentTask}
            onSubmit={values => {
              onEditTask(values).then(() => {
                setEditingChemicalIndex(null);
              });
            }}
          >
            <Grid.Row columns={1}>
              <Grid.Column>
                {chemicalsInformationData.map((chemicalData, chemicalIndex) => {
                  return (
                    <ProductsInformationSegment
                      chemicalData={chemicalData}
                      chemicalIndex={chemicalIndex}
                      isEditing={chemicalIndex === editingChemicalIndex}
                      setEditingChemicalIndex={setEditingChemicalIndex}
                      setChemicalToDeleteIndex={setChemicalToDeleteIndex}
                    />
                  );
                })}
              </Grid.Column>
            </Grid.Row>
          </Formik>
        </Grid>
      </Segment>
    </>
  );
};

export default ProductsInformationForm;
