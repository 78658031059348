import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";
import QRCode from "qrcode.react";
import Logo from "./Logo";
import classNames from "classnames";

const TagBarcode = ({ barcode, minimized, fullHeight }) => {
  const { numberPart, ...labels } = barcode;

  return (
    <table
      className={classNames(styles.barcode, {
        [styles.minimized]: minimized,
        [styles.fullHeight]: fullHeight
      })}
      style={{ marginBottom: 10 }}
    >
      <tr style={{ width: 70 }}>
        <td
          style={{
            width: "50%",
            textAlign: "center",
            paddingTop: 0,
            paddingBottom: 0
          }}
          rowSpan="3"
        >
          <QRCode
            style={{ width: 130, height: 130 }}
            renderAs="svg"
            value={numberPart}
          />
        </td>
        <td style={{ textAlign: "center", background:'#004F5D' }}>
          <Logo />
        </td>
      </tr>
      <tr style={{ height: 20 }}>
        <td>{labels.dateLabel}</td>
      </tr>
      <tr style={{ height: 20 }}>
        <td>{numberPart}</td>
      </tr>
      <tr style={{ height: 70 }}>
        <td colSpan="2">{labels.comment}</td>
      </tr>
    </table>
  );
};

TagBarcode.propTypes = {
  barcode: PropTypes.object.isRequired,
  minimized: PropTypes.bool,
  fullHeight: PropTypes.bool
};

export default TagBarcode;
