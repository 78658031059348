import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Checkbox,
  Input,
  Popup,
  Icon,
  Modal
} from "semantic-ui-react";
import { Formik, Field } from "formik";
import styles from "./Parametrs.module.css";
import { useDispatch, connect, useSelector } from "react-redux";
import { getParametrsList, updateQC } from "actions/QualityControl";
import Loader from "components/Loader";
import { parametrsFiltersOptions } from "selectors/qualityControl";

const EditParameter = ({
  activeItem,
  setCheckEdit,
  activeRounds,
  isFetching
}) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(false);
  const parametrsOptions = useSelector(parametrsFiltersOptions);
  const onSubmit = data => {
    dispatch(updateQC(activeItem.id, data)).then(() => setCheckEdit(false));
  };
  useEffect(() => {
    activeItem?.qcRounds.length <= 1 &&
      !parametrsOptions.options.length &&
      dispatch(getParametrsList());
  }, []);
  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <Formik
          enableReinitialize
          initialValues={activeRounds}
          validate={values => {
            const errors = {};
            values.defectReports.forEach(item => {
              const totalAmount = +item.majorAmount + +item.minorAmount;
              if (totalAmount < +item.minorAmount) {
                errors[
                  item.parameter
                ] = `Value must be at least ${+item.minorAmount}`;
              }
            });
            return errors;
          }}
        >
          {({
            values,
            dirty,
            isValid,
            errors,
            setFieldError,
            setFieldValue
          }) => {
            const dataToSend = {
              barcodeId: activeItem?.barcodeId,
              createdAt: activeItem?.createdAt,
              id: activeItem?.id,
              name: activeItem?.name,
              unitToCheckPerRound: activeItem?.unitToCheckPerRound,
              qcRounds: activeItem?.qcRounds.map(item =>
                item.roundNumber === activeRounds?.roundNumber ? values : item
              ),
              rounds: activeItem?.rounds
            };
            const newOptions = parametrsOptions.options.filter(item =>
              values.defectReports.every(i => item.key !== i.parameter)
            );
            return (
              <>
                <div className={styles.checkboxWrapper}>
                  <span className={styles.checkboxLabel}>
                    Specify the minor / major
                  </span>
                  <Checkbox
                    toggle
                    className={styles.checkbox}
                    checked={active}
                    onChange={(_, data) => setActive(!active)}
                  />
                </div>
                <Form>
                  {values.defectReports.map((item, index) => {
                    const parameterName = item.parameter
                      .replace(/_/g, " ")
                      .replace(
                        /^(.)(.*)$/,
                        (_, first, rest) =>
                          first.toUpperCase() + rest.toLowerCase()
                      );
                    return (
                      <>
                        {active ? (
                          <div className={styles.rowsWrapper}>
                            {activeItem?.qcRounds.length > 1 ? (
                              <>
                                <div className={styles.editNameWithDetails}>
                                  {parameterName}
                                  <span
                                    className={styles.totalFieldWithDetails}
                                  >
                                    Total :{" "}
                                    {+item.majorAmount + +item.minorAmount}
                                  </span>
                                </div>
                                <div className={styles.formFieldWrapper}>
                                  <Form.Field className={styles.formField}>
                                    <label className={styles.label}>
                                      Minor
                                    </label>
                                    <Field
                                      as={Input}
                                      value={
                                        values.defectReports.find(
                                          i => i.parameter === item.parameter
                                        )?.minorAmount
                                      }
                                      fluid
                                      className={styles.totalField}
                                      required
                                      name="minor"
                                      onChange={e => {
                                        const { value } = e.target;
                                        setFieldValue(
                                          "defectReports",
                                          values.defectReports.map(i =>
                                            i.parameter === item.parameter
                                              ? { ...i, minorAmount: value }
                                              : i
                                          )
                                        );
                                      }}
                                    />
                                  </Form.Field>
                                  <Form.Field className={styles.formField}>
                                    <label className={styles.label}>
                                      Major
                                    </label>
                                    <Field
                                      as={Input}
                                      value={
                                        values.defectReports.find(
                                          i => i.parameter === item.parameter
                                        )?.majorAmount
                                      }
                                      className={styles.totalField}
                                      fluid
                                      required
                                      name="major"
                                      onChange={e => {
                                        const { value } = e.target;
                                        setFieldValue(
                                          "defectReports",
                                          values.defectReports.map(i =>
                                            i.parameter === item.parameter
                                              ? { ...i, majorAmount: value }
                                              : i
                                          )
                                        );
                                      }}
                                    />
                                  </Form.Field>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className={styles.editNameWithDetails}>
                                  <span
                                    className={styles.totalFieldWithDetails}
                                  >
                                    Parameter {index + 1}
                                  </span>
                                  <span
                                    className={styles.totalFieldWithDetails}
                                  >
                                    <Popup
                                      inverted
                                      position="bottom center"
                                      content={"Delete"}
                                      size="tiny"
                                      trigger={
                                        <div
                                          onClick={() =>
                                            setFieldValue(
                                              "defectReports",
                                              values.defectReports.filter(p => {
                                                return (
                                                  p.parameter !== item.parameter
                                                );
                                              })
                                            )
                                          }
                                          className={styles.deleteButton}
                                        >
                                          <Icon name="trash alternate" />
                                        </div>
                                      }
                                    />
                                  </span>
                                </div>
                                <div className={styles.formFieldWrapper}>
                                  <Form.Field
                                    width={7}
                                    className={styles.formField}
                                  >
                                    <label className={styles.label}>
                                      Parameter type
                                    </label>
                                    <Form.Dropdown
                                      selection
                                      search
                                      name="parameters"
                                      text={parameterName}
                                      options={newOptions || []}
                                      value={item.parameter}
                                      onChange={(e, { value }) => {
                                        setFieldValue(
                                          "defectReports",
                                          values.defectReports.map(i =>
                                            i.parameter === item.parameter
                                              ? { ...i, parameter: value }
                                              : i
                                          )
                                        );
                                      }}
                                    />
                                  </Form.Field>
                                  <Form.Field
                                    width={3}
                                    className={styles.formField}
                                  >
                                    <label className={styles.label}>
                                      Total number
                                    </label>
                                    <Field
                                      as={Input}
                                      value={
                                        +values.defectReports.find(
                                          i => i.parameter === item.parameter
                                        )?.majorAmount +
                                        +values.defectReports.find(
                                          i => i.parameter === item.parameter
                                        )?.minorAmount
                                      }
                                      fluid
                                      className={styles.totalField}
                                      required
                                      name="totalAmount"
                                      onChange={e => {
                                        const { value } = e.target;
                                        if (
                                          value <
                                          values.defectReports.find(
                                            i => i.parameter === item.parameter
                                          )?.minorAmount
                                        ) {
                                          values.defectReports.map(
                                            i =>
                                              i.parameter === item.parameter &&
                                              setFieldError(
                                                `${item.parameter}`,
                                                `Value must be at least ${i?.minorAmount}`
                                              )
                                          );
                                        } else {
                                          setFieldError(
                                            `${item.parameter}`,
                                            undefined
                                          );
                                        }
                                        setFieldValue(
                                          "defectReports",
                                          values.defectReports.map(i =>
                                            i.parameter === item.parameter
                                              ? {
                                                ...i,
                                                majorAmount:
                                                  value - i.minorAmount
                                              }
                                              : i
                                          )
                                        );
                                      }}
                                    />
                                  </Form.Field>
                                  <Form.Field
                                    width={3}
                                    className={styles.formField}
                                  >
                                    <label className={styles.label}>
                                      Minor
                                    </label>
                                    <Field
                                      as={Input}
                                      value={
                                        values.defectReports.find(
                                          i => i.parameter === item.parameter
                                        )?.minorAmount
                                      }
                                      fluid
                                      className={styles.totalField}
                                      required
                                      name="minor"
                                      onChange={e => {
                                        const { value } = e.target;
                                        setFieldValue(
                                          "defectReports",
                                          values.defectReports.map(i =>
                                            i.parameter === item.parameter
                                              ? { ...i, minorAmount: value }
                                              : i
                                          )
                                        );
                                      }}
                                    />
                                  </Form.Field>
                                  <Form.Field
                                    width={3}
                                    className={styles.formField}
                                  >
                                    <label className={styles.label}>
                                      Major
                                    </label>
                                    <Field
                                      as={Input}
                                      value={
                                        values.defectReports.find(
                                          i => i.parameter === item.parameter
                                        )?.majorAmount
                                      }
                                      className={styles.totalField}
                                      fluid
                                      required
                                      name="major"
                                      onChange={e => {
                                        const { value } = e.target;
                                        setFieldValue(
                                          "defectReports",
                                          values.defectReports.map(i =>
                                            i.parameter === item.parameter
                                              ? { ...i, majorAmount: value }
                                              : i
                                          )
                                        );
                                      }}
                                    />
                                  </Form.Field>
                                </div>
                                {errors[`${item.parameter}`] && (
                                  <div
                                    style={{
                                      width: "100%",
                                      marginTop: "16px",
                                      color: "red"
                                    }}
                                  >
                                    {errors[`${item.parameter}`]}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        ) : activeItem?.qcRounds.length > 1 ? (
                          <div className={styles.rowWrapper}>
                            <Form.Field className={styles.editName}>
                              {parameterName}
                            </Form.Field>
                            <Form.Field className={styles.formField}>
                              <label className={styles.label}>
                                Total amount
                              </label>
                              <Field
                                as={Input}
                                className={styles.totalField}
                                required
                                fluid
                                value={
                                  +values.defectReports.find(
                                    i => i.parameter === item.parameter
                                  )?.majorAmount +
                                  +values.defectReports.find(
                                    i => i.parameter === item.parameter
                                  )?.minorAmount
                                }
                                name="totalAmount"
                                onChange={e => {
                                  const { value } = e.target;
                                  if (
                                    value <
                                    values.defectReports.find(
                                      i => i.parameter === item.parameter
                                    )?.minorAmount
                                  ) {
                                    values.defectReports.map(
                                      i =>
                                        i.parameter === item.parameter &&
                                        setFieldError(
                                          `${item.parameter}`,
                                          `Value must be at least ${i?.minorAmount}`
                                        )
                                    );
                                  } else {
                                    setFieldError(
                                      `${item.parameter}`,
                                      undefined
                                    );
                                  }
                                  setFieldValue(
                                    "defectReports",
                                    values.defectReports.map(i =>
                                      i.parameter === item.parameter
                                        ? {
                                          ...i,
                                          majorAmount: value - i.minorAmount
                                        }
                                        : i
                                    )
                                  );
                                }}
                              />
                              {errors[`${item.parameter}`] && (
                                <div
                                  style={{
                                    width: "100%",
                                    marginTop: "16px",
                                    color: "red"
                                  }}
                                >
                                  {errors[`${item.parameter}`]}
                                </div>
                              )}
                            </Form.Field>
                          </div>
                        ) : (
                          <div className={styles.rowsWrapper}>
                            <div className={styles.parameterNumber}>
                              <span className={styles.totalFieldWithDetails}>
                                Parameter {index + 1}
                              </span>
                              {values.defectReports.length > 1 && (
                                <span className={styles.totalFieldWithDetails}>
                                  <Popup
                                    inverted
                                    position="bottom center"
                                    content={"Delete"}
                                    size="tiny"
                                    trigger={
                                      <div
                                        onClick={() =>
                                          setFieldValue(
                                            "defectReports",
                                            values.defectReports.filter(p => {
                                              return (
                                                p.parameter !== item.parameter
                                              );
                                            })
                                          )
                                        }
                                        className={styles.deleteButton}
                                      >
                                        <Icon name="trash alternate" />
                                      </div>
                                    }
                                  />
                                </span>
                              )}
                            </div>
                            <div className={styles.rowWrapperWithError}>
                              <Form.Field
                                width={8}
                                className={styles.formField}
                              >
                                <label className={styles.label}>
                                  Parameter type
                                </label>
                                <Form.Dropdown
                                  selection
                                  search
                                  name="parameters"
                                  text={parameterName}
                                  options={newOptions || []}
                                  value={item.parameter}
                                  onChange={(e, { value }) => {
                                    setFieldValue(
                                      "defectReports",
                                      values.defectReports.map(i =>
                                        i.parameter === item.parameter
                                          ? { ...i, parameter: value }
                                          : i
                                      )
                                    );
                                  }}
                                />
                              </Form.Field>
                              <Form.Field
                                width={7}
                                className={styles.formField}
                              >
                                <label className={styles.label}>
                                  Total amount
                                </label>
                                <Field
                                  as={Input}
                                  className={styles.totalField}
                                  required
                                  fluid
                                  value={
                                    +values.defectReports.find(
                                      i => i.parameter === item.parameter
                                    )?.majorAmount +
                                    +values.defectReports.find(
                                      i => i.parameter === item.parameter
                                    )?.minorAmount
                                  }
                                  name="totalAmount"
                                  onChange={e => {
                                    const { value } = e.target;
                                    if (
                                      value <
                                      values.defectReports.find(
                                        i => i.parameter === item.parameter
                                      )?.minorAmount
                                    ) {
                                      values.defectReports.map(
                                        i =>
                                          i.parameter === item.parameter &&
                                          setFieldError(
                                            `${item.parameter}`,
                                            `Value must be at least ${i?.minorAmount}`
                                          )
                                      );
                                    } else {
                                      setFieldError(
                                        `${item.parameter}`,
                                        undefined
                                      );
                                    }
                                    setFieldValue(
                                      "defectReports",
                                      values.defectReports.map(i =>
                                        i.parameter === item.parameter
                                          ? {
                                            ...i,
                                            majorAmount: value - i.minorAmount
                                          }
                                          : i
                                      )
                                    );
                                  }}
                                />
                              </Form.Field>
                            </div>
                            {errors[`${item.parameter}`] && (
                              <div
                                style={{
                                  width: "100%",
                                  marginTop: "16px",
                                  color: "red"
                                }}
                              >
                                {errors[`${item.parameter}`]}
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    );
                  })}

                  <div className={styles.btnWrapper}>
                    <Button
                      content="Cancel"
                      type="text"
                      onClick={() => {
                        setCheckEdit(false);
                      }}
                    />
                    <Button
                      content={"Save"}
                      primary
                      onClick={() => {
                        return onSubmit(dataToSend), setCheckEdit(false);
                      }}
                      disabled={!isValid || !dirty}
                    />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default EditParameter;
