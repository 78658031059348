import React, { useEffect, useMemo } from "react";
import {
  Button,
  Header,
  Icon,
  Message,
  Segment,
  Table,
  TableCell
} from "semantic-ui-react";
import rateTypes from "./rateTypes";
import { matterToUnits } from "../../../../../utils/constToUnits";
import styles from "./SelectChemicalsForm.module.css";
import Numeric from "../../../../../components/Numeric";
import PropTypes from "prop-types";
import AddTableItem from "./AddTableItem";
import { calculateChemicalQuantity } from "utils/chemicalBreakdownUtils";
import { calcTotalHectare } from "utils/tasksUtils";
import { connect, useDispatch, useSelector } from "react-redux";
import { unitsSelector } from "selectors/chemicals";
import { getBatchNumbersLastYear } from "actions/Spray/tasks";
import { Chip } from "routes/Reports/Harvest/components/Chip";
import moment from "moment";
import { overlapsChecker } from "actions/SprayDiary/common";

const ShowTableItem = ({
  selectedMachinery,
  totalTanks,
  checker,
  pickingAt,
  item,
  unitFormater,
  multiplier,
  calculateChemicalTotal,
  onEdit,
  onDelete
}) => {
  const chemicalTotal = calculateChemicalTotal(item);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(overlapsChecker(checker));
  }, [checker]);
  return (
    <>
      <tr className="show">
        <td className={styles.chemicalCell}>
          <div className={styles.title}>Name</div>
          {item.name}
        </td>
        <td className={styles.chemicalTypeCell}>
          <div className={styles.title}>Type</div>
          {item.type.type}
        </td>
        <td className={styles.cropAndPurposeCell}>
          <div className={styles.title}>Target</div>
          {[
            item.rate && item.rate.crop && item.rate.crop.name
              ? item.rate.crop.name
              : "Generic",
            item.rate && item.rate.purpose
          ]
            .filter(item => item)
            .join(" - ")}
        </td>
        <td className={styles.dilutionCell}>
          <div className={styles.title}>Rate</div>
          {item.rate
            ? item.rate.rate * multiplier(item.rate.unit) ||
              item.rate.minRate * multiplier(item.rate.unit)
            : ""}{" "}
          {unitFormater(item.rate.unit)}
        </td>
        <td>
          <div className={styles.title}>Rate Type</div>
          {rateTypes[item.rate.rateType]}
        </td>
        <td>
          <div className={styles.title}>Total Qty</div>
          <Numeric
            value={chemicalTotal.quantity * multiplier(item.rate.unit)}
            fractionDigits={3}
            units={unitFormater(item.rate.unit)}
          />
        </td>
        <td>
          <div className={styles.title}>
            {selectedMachinery ? "Qty Per Full Tank" : "Full Qty"}
          </div>
          <Numeric
            value={
              totalTanks < 1 ? 0 : item.fullTanks * multiplier(item.rate.unit)
            }
            fractionDigits={totalTanks < 1 ? 0 : 3}
            units={unitFormater(item.rate.unit)}
          />
        </td>
        {selectedMachinery && (
          <td>
            <div className={styles.title}>Qty Per Part Tank</div>
            <Numeric
              value={item.partTanks * multiplier(item.rate.unit)}
              units={unitFormater(item.rate.unit)}
            />
          </td>
        )}
        <td className={styles.batchNumberCell}>
          <div className={styles.title}>Batch No.</div>
          {item.batchNumbers?.length ? (
            Array.isArray(item.batchNumbers) && item.batchNumbers.length > 1 ? (
              <Chip items={item.batchNumbers} label="Batch #" />
            ) : (
              item.batchNumbers[0]
            )
          ) : (
            "-"
          )}
        </td>
        <td className={styles.dateOfManufactureCell}>
          <div className={styles.title}>Date of manufacture</div>
          {item.dateOfManufacture}
        </td>
        <td className={styles.actions}>
          <Icon
            className="tuf-pen"
            onClick={() => {
              return onEdit(item.id);
            }}
          />
          <Icon className="tuf-trash-alt" onClick={() => onDelete(item.id)} />
        </td>
      </tr>
      <tr></tr>
    </>
  );
};
const SelectChemicalsForm = ({
  showArchived,
  plannedDate,
  selectedMachinery,
  onSelectChemical,
  selectedChemicals,
  batchNumbers,
  selectedAreas,
  rowsToSpray,
  widthPerRow,
  literPerHectare,
  conc,
  chemicalToAdd,
  totalTanks,
  actions: { onEditChemicalItem, setAddChemicalSelection },
  deleteChemicalItem,
  addChemicalSelection
}) => {
  const calculateChemicalTotal = item => {
    const totalHectares = calcTotalHectare(selectedAreas);
    return calculateChemicalQuantity(
      item,
      totalHectares,
      rowsToSpray,
      widthPerRow,
      literPerHectare,
      conc
    );
  };

  const unitFormater = item => {
    let unit;
    if (item === "liter") {
      unit = "L";
    } else if (item === "kilogram") {
      unit = "kg";
    } else if (item === "milliliter") {
      unit = "mL";
    } else {
      unit = "g";
    }
    return unit;
  };
  const { content, isFetching } = useSelector(unitsSelector);
  const multiplier = item => {
    const foundElement = content.find(({ id }) => id === item);

    if (foundElement) {
      return 1 / foundElement.multiplicationFactor;
    }

    return 1;
  };
  const pickingAt = selectedAreas
    .map(item => item.varieties)
    .flat()
    .map(v => v?.pickingAt);

  return (
    <>
      <Header as="h3">Select Products</Header>
      <Table className={`chemicals sprayTable ${styles.chemicalsTable}`}>
        <Table.Header>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Target</th>
            <th>Rate</th>
            <th>Rate Type</th>
            <th>Total Qty</th>
            <th>{selectedMachinery ? "Qty Per Full Tank" : "Full Qty"}</th>
            {selectedMachinery && <th>Qty Per Part Tank</th>}
            <th>Batch No.</th>
            <th>Date of Manufacture</th>
            <th>
              <div className={styles.buttonsHolder} />
            </th>
          </tr>
        </Table.Header>
        <Table.Body>
          {[...selectedChemicals, chemicalToAdd].map((item, index) => {
            const whp = item.rate.withholdingPeriod;
            const date = new Date(plannedDate);
            date.setDate(date.getDate() + whp);
            const formattedDate = moment(date).format("YYYY-MM-DD");
            const checker =
              formattedDate !== "Invalid date"
                ? pickingAt.some(item => {
                    return item < formattedDate;
                  })
                : false;
            return (
              <>
                {item.toAdd ? (
                  <AddTableItem
                    key={item.new ? "new" : item.id}
                    item={item}
                    index={index}
                    selectedChemicals={selectedChemicals}
                    batchNumbers={batchNumbers}
                    multiplier={multiplier}
                    unitFormater={unitFormater}
                    calculateChemicalTotal={calculateChemicalTotal}
                    onSelectChemical={onSelectChemical}
                  />
                ) : (
                  <ShowTableItem
                    key={item.id}
                    pickingAt={pickingAt}
                    checker={checker}
                    selectedChemicals={selectedChemicals}
                    item={item}
                    totalTanks={totalTanks}
                    unitFormater={unitFormater}
                    multiplier={multiplier}
                    selectedMachinery={selectedMachinery}
                    calculateChemicalTotal={calculateChemicalTotal}
                    onEdit={onEditChemicalItem}
                    onDelete={deleteChemicalItem}
                  />
                )}
                {checker && (
                  <Table.Row>
                    <TableCell className={styles.messageContainer} colSpan="10">
                      <Message className={styles.messageCell} color="red">
                        Withholding period overlaps the date of picking:
                        {item.name}
                      </Message>
                    </TableCell>
                  </Table.Row>
                )}
              </>
            );
          })}
        </Table.Body>
      </Table>
      {!addChemicalSelection && !(selectedChemicals.length === 0) && (
        <div className={styles.buttonAdd}>
          <Button
            className="color-green"
            onClick={() => setAddChemicalSelection(true)}
          >
            Add
          </Button>
        </div>
      )}
    </>
  );
};

SelectChemicalsForm.propTypes = {
  selectedMachinery: PropTypes.number,
  onSelectChemical: PropTypes.func,
  selectedChemicals: PropTypes.array,
  chemicalToAdd: PropTypes.object,
  actions: PropTypes.object,
  deleteChemicalItem: PropTypes.func,
  addChemicalSelection: PropTypes.bool,
  totalTanks: PropTypes.number,
  showArchived: PropTypes.bool
};

ShowTableItem.propTypes = {
  selectedMachinery: PropTypes.number,
  item: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  totalTanks: PropTypes.number
};

const mapStateToProps = ({ sprayDiary: { totalTanks }, spray }) => ({
  totalTanks
});

export default connect(mapStateToProps, {})(SelectChemicalsForm);
