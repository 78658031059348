import { actionType } from "../../constants/SprayDiary";
import { setAreaDoneState } from "./areas";
import { history } from "../../store";
import genericAttachmentUploader from "../../utils/genericAttachmentUploader";
import { axiosProxy } from "../../utils/axiosProxy";
import { fetchUnits } from "actions/Chemicals";

const parseAreas = (areas, allAreasFromStorage) => [
  ...areas.map(
    ({ areaId, done, actualTanks, plannedTanks, plannedLiters, hectares }) => ({
      ...(allAreasFromStorage
        ? allAreasFromStorage.find(({ id }) => id === areaId)
        : { areaId }),
      key: areaId,
      type: "block",
      size: hectares,
      plannedLiters,
      tanks: plannedTanks,
      actualTanks: actualTanks >= 0 ? actualTanks : plannedTanks,
      done
    })
  )
];

export const parseResponseValue = (
  {
    actualRowsToSpray,
    assignees,
    attachments,
    signature,
    areas,
    chemicals,
    comment,
    conc,
    creator,
    id,
    _id,
    literPerHectare,
    machinery,
    machinerySizeUnit,
    machinerySize,
    ppeWorn,
    machinerySpeed,
    nozzle,
    nozzlesPerSide,
    gear,
    rpm,
    pressure,
    interventionType,
    shedId,
    applicationType,
    pto,
    rowWidth: widthPerRow,
    rowsToSpray,
    plannedDate,
    expirationDate,
    status,
    supervisors,
    temperature,
    growthStage,
    deltaT,
    windDirection,
    windStrength,
    workingTimes,
    taskCreatedAt,
    taskName,
    seen,
    seenAt,
    completedAt,
    inProgressAt,
    actualTotalTanks
  },
  allAreasFromStorage
) => ({
  taskCreatedAt,
  taskName,
  seen,
  seenAt,
  completedAt,
  inProgressAt,
  chemicals,
  actualRowsToSpray: actualRowsToSpray || rowsToSpray,
  actualRowsToSprayChecked:
    status !== "COMPLETED" || rowsToSpray === actualRowsToSpray,
  assignees,
  ppeWorn,
  creator,
  supervisors,
  attachments: attachments || [],
  signature: signature || {},
  plannedDate: plannedDate || new Date(),
  expirationDate,
  comment: comment || "",
  selectedMachinery: {
    ...machinery,
    size: machinerySize,
    sizeUnit: machinerySizeUnit
  },
  conc,
  machinerySpeed,
  nozzle,
  nozzlesPerSide,
  gear,
  rpm,
  pressure,
  pto,
  id: id || null,
  ...(_id ? { _id } : {}),
  literPerHectare,
  interventionType,
  shedId,
  applicationType,
  rowsToSpray,
  temperature,
  deltaT,
  growthStage,
  windDirection,
  windStrength,
  workingTimes: workingTimes || [],
  status,
  widthPerRow,
  selectedAreas: parseAreas(areas, allAreasFromStorage),
  selectedChemicals: chemicals.map(ch => ({
    ...ch.chemical,
    rate: {
      ...ch.chemicalRate,
      rate: ch.dilutionRate,
      rateType: ch.dilutionRateType,
      withholdingPeriod: ch.withholdingPeriod
    },
    dateOfManufacture: ch.dateOfManufacture,
    batchRequired: ch.chemical.batchRequired,
    reentry: ch.reentry,
    fullTanks: ch.qtyPerFullVat,
    partTanks: ch.qtyPerPartTank,
    batchNumber: ch.batchNumber,
    batchNumbers: ch.batchNumbers,
    target: ch.target
  })),
  actualTotalTanks
});

const duplicateTask = (
  {
    assignees,
    attachments,
    signature,
    areas,
    chemicals,
    comment,
    conc,
    literPerHectare,
    machinery,
    machinerySizeUnit,
    machinerySize,
    machinerySpeed,
    nozzle,
    nozzlesPerSide,
    gear,
    rpm,
    pressure,
    interventionType,
    shedId,
    applicationType,
    pto,
    rowWidth: widthPerRow,
    rowsToSpray
  },
  parts
) => {
  let personalPart = {};
  let locationPart = {};
  let machineryPart = {};
  let chemicalsPart = {};

  if (parts.includes("Personal")) {
    personalPart = {
      interventionType,
      shedId,
      applicationType,
      attachments: attachments || [],
      signature: signature || [],
      comment: comment || ""
    };
  }

  if (parts.includes("Locations")) {
    locationPart = {
      selectedAreas: [
        ...areas.map(({ area, plannedTanks, hectares }) => ({
          ...area,
          key: area.id,
          type: "block",
          size: hectares,
          tanks: plannedTanks,
          actualTanks: null,
          done: false
        }))
      ],
      literPerHectare,
      rowsToSpray,
      widthPerRow
    };
  }

  if (parts.includes("Machinery")) {
    machineryPart = {
      selectedMachinery: {
        ...machinery,
        size: machinerySize,
        sizeUnit: machinerySizeUnit
      },
      conc,
      machinerySpeed,
      nozzle,
      nozzlesPerSide,
      gear,
      rpm,
      pressure,
      pto
    };
  }

  if (parts.includes("Chemicals")) {
    chemicalsPart = {
      selectedChemicals: chemicals.map(ch => ({
        ...ch.chemical,
        rate: {
          ...ch.chemicalRate,
          rate: ch.dilutionRate,
          rateType: ch.dilutionRateType,
          withholdingPeriod: ch.withholdingPeriod
        },
        batchRequired: ch.chemical.batchRequired,
        reentry: ch.reentry,
        target: ch.target
      }))
    };
  }

  return {
    ...personalPart,
    ...locationPart,
    ...machineryPart,
    ...chemicalsPart,
    workingTimes: []
  };
};

export const getTaskById = (id, options = {}) => async (dispatch, getState) => {
  const url = `/spray_tasks_v2/${id}`;

  const {
    spray: {
      tasks: { data, dataOffline }
    },
    chemical: { units },
    user: { user },
    areas
  } = getState();
  if (!units.content.length) {
    await dispatch(fetchUnits());
  }
  const findTask = content => {
    return content.find(t => t.id === Number(id) || t._id === id);
  };
  const task = findTask(data.content) || findTask(dataOffline.content);

  if (task) {
    if (options.status) {
      await setStatus(options.status)(dispatch);
    }
    if (options.done) {
      await setAreaDoneState(Number(options.done))(dispatch);
    }
  }

  if ((task && task.id) || !task) {
    return dispatch({
      type: actionType.GET_TASK_BY_ID,
      meta: {
        callFrom: history.location.pathname,
        user,
        offline: {
          // the network action to execute:
          effect: {
            url,
            method: "GET",
            headers: {
              "Content-Type": "application/json"
            },
            payloadConstructor: fetchedTask =>
              parseResponseValue(fetchedTask, areas.list.content)
          },
          commit: {
            type: actionType.GET_TASK_BY_ID_SUCCESS,
            meta: { fetched: true }
          },
          rollback: {
            type: actionType.GET_TASK_BY_ID_ERROR,
            meta: {}
          }
        }
      }
    });
  }
};

export const setSprayDiaryField = ({ fieldName, fieldValue }) => {
  return {
    type: actionType.SET_FIELD,
    payload: { fieldName, fieldValue }
  };
};
export const setSprayNestedField = ({ fieldName, fieldValue, parent }) => {
  return {
    type: actionType.SET_NESTED_FIELD,
    payload: { fieldName, fieldValue, parent }
  };
};

export const setStatus = status => dispatch => {
  dispatch({
    type: actionType.SET_STATUS,
    payload: status
  });
};

export const setRowsToSpray = maner => dispatch => {
  dispatch({
    type: actionType.SET_ROWS_TO_SPRAY,
    payload: maner
  });
};

export const setActualRowsToSprayCheckState = checked => dispatch => {
  dispatch({
    type: actionType.SET_ACTUALL_ROWS_TO_SPRAY_CHECK_STATE,
    payload: checked
  });
};
export const setActualRowsToSpray = maner => dispatch => {
  dispatch({
    type: actionType.SET_ACTUALL_ROWS_TO_SPRAY,
    payload: maner
  });
};

export const setDate = (field, date) => dispatch => {
  dispatch({
    type: actionType.SET_SPRAY_DATE,
    payload: { field, date }
  });
};
export const setExpiryDate = (field, expirationDate) => dispatch => {
  dispatch({
    type: actionType.SET_SPRAY_EXPIRY_DATE,
    payload: { field, expirationDate }
  });
};

export const setWorkingTimes = workingTimes => dispatch => {
  dispatch({
    type: actionType.SET_WORKING_TIMES,
    payload: workingTimes
  });
};

export const setWindSpeed = speed => dispatch => {
  dispatch({
    type: actionType.SET_WIND_SPEED,
    payload: speed
  });
};

export const setTemperature = temperature => dispatch => {
  dispatch({
    type: actionType.SET_TEMPERATURE,
    payload: temperature
  });
};

export const setDeltaT = deltaT => dispatch => {
  dispatch({
    type: actionType.SET_DELTA_T,
    payload: deltaT
  });
};

export const setGrowthStage = growthStage => dispatch => {
  dispatch({
    type: actionType.SET_GROWTH_STAGE,
    payload: growthStage
  });
};

export const setWindDirection = direction => dispatch => {
  dispatch({
    type: actionType.SET_WIND_DIRECTION,
    payload: direction
  });
};

export const setInterventionType = id => dispatch => {
  dispatch({
    type: actionType.SET_INTERVENTION_TYPE,
    payload: id
  });
};
export const setSheds = id => dispatch => {
  dispatch({
    type: actionType.SET_SHED,
    payload: id
  });
};

export const setApplicationType = type => dispatch => {
  dispatch({
    type: actionType.SET_APPLICATION_TYPE,
    payload: type
  });
};

export const setTaskName = name => dispatch => {
  dispatch({
    type: actionType.SET_TASK_NAME,
    payload: name
  });
};

export const getTaskNames = () => async dispatch => {
  try {
    dispatch({
      type: actionType.GET_TASK_NAME_LIST_START
    });

    const response = await axiosProxy({
      method: "GET",
      url: "/spray_tasks/names?unpaged=true"
    });

    dispatch({
      type: actionType.GET_TASK_NAME_LIST_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    return dispatch({
      type: actionType.GET_TASK_NAME_LIST_FAIL,
      payload: error
    });
  }
};

export const setComments = comment => ({
  type: actionType.SET_COMMENTS,
  payload: comment
});

export const setPPEWorn = ppeWorn => ({
  type: actionType.SET_PPE_WORN,
  payload: ppeWorn
});

export const setValidations = validations => ({
  type: actionType.SET_VALIDATIONS,
  payload: validations
});

export const createTask = ({
  id,
  data,
  offlineMode,
  _id,
  reqId,
  attachmentsToWatch
}) => async (dispatch, getState) => {
  const {
    offline: { online },
    user: { user },
    areas,
    sprayDiary: { applicationType }
  } = getState();
  if (applicationType !== "SPRAY") {
    data.literPerHectare = 0;
    data.conc = "";
    data.gear = "";
    data.machinerySpeed = "";
    data.nozzle = "";
    data.nozzlesPerSide = "";
    data.pressure = "";
    data.pto = "";
    data.rpm = "";
  }

  if (!(id || _id)) return;
  const url = `/spray_tasks${id ? "/partial" : ""}${id ? `/${id}` : ""}`;
  return dispatch({
    type: actionType.CREATE_TASK_START,
    payload: { id, _id },
    meta: {
      reqId: reqId || id || _id,
      online: offlineMode ? false : online,
      user,
      offline: {
        // the network action to execute:
        effect: {
          url,
          method: id ? "PUT" : "POST",
          headers: {
            "Content-Type": "application/json"
          },
          data,
          attachmentsToWatch,
          payloadConstructor: fetchedTask =>
            parseResponseValue(fetchedTask, areas.list.content)
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: actionType.CREATE_TASK_SUCCESS,
          meta: { online: offlineMode ? false : online, _id }
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: actionType.CREATE_TASK_ERROR,
          meta: { online: offlineMode ? false : online, _id }
        }
      }
    }
  });
};

export const changeDuplicateSelection = selection => dispatch => {
  dispatch({
    type: actionType.CHANGE_DUPLICATE_SELECTION,
    payload: selection
  });
};

export const clearAndDuplicate = (task, parts) => dispatch => {
  dispatch({
    type: actionType.CLEAR_AND_DUPLICATE,
    payload: duplicateTask(task, parts)
  });
};

export const clearCreator = () => dispatch => {
  dispatch({
    type: actionType.CLEAR_CREATOR
  });
};

export const prefillCreator = task => dispatch => {
  dispatch({
    type: actionType.PREFILL_CREATOR,
    payload: task
  });
};

export const setAreasToAdd = areas => dispatch => {
  dispatch({
    type: actionType.SET_AREAS_TO_ADD,
    payload: areas
  });
};

export const setAttachments = ({ attachments, parentId }) => dispatch => {
  return dispatch({
    type: actionType.SET_ATTACHMENTS,
    payload: { attachments, parentId }
  });
};

export const restoreBackup = () => dispatch => {
  dispatch({
    type: actionType.RESTORE_BACKUP
  });
};

export const clearError = () => dispatch => {
  dispatch({
    type: actionType.CLEAR_ERROR
  });
};

export const uploadAttachment = ({
  file,
  callBack,
  idReplace,
  serviceWorkerAction,
  data,
  parentId
}) =>
  genericAttachmentUploader({
    url: "/spray_tasks/attachments/presign_put_url",
    file,
    startAction: actionType.UPLOAD_TASK_ATTACHMENT_START,
    successAction: actionType.UPLOAD_TASK_ATTACHMENT,
    errorAction: actionType.UPLOAD_TASK_ATTACHMENT_ERROR,
    callBack,
    serviceWorkerAction,
    idReplace,
    data,
    parentId
  });

export const uploadSignature = ({
  file,
  callBack,
  idReplace,
  serviceWorkerAction,
  data,
  parentId
}) =>
  genericAttachmentUploader({
    url: "/employees/signatures/presign_put_url",
    file,
    startAction: actionType.UPLOAD_SIGNATURE_START,
    successAction: actionType.UPLOAD_SIGNATURE,
    errorAction: actionType.UPLOAD_SIGNATURE_ERROR,
    callBack,
    serviceWorkerAction,
    idReplace,
    data,
    parentId
  });

export const fetchTaskById = async taskId => {
  const response = await axiosProxy({
    method: "GET",
    url: `/spray_tasks/${taskId}`
  });

  return response.data;
};

export const signatureDelete = () => dispatch => {
  dispatch({
    type: actionType.CLEAR_SIGNATURE
  });
};

export const overlapsChecker = status => dispatch => {
  dispatch({
    type: actionType.OVERLAPS_CHECKER,
    payload: status
  });
};
