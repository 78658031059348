import React, { useState } from "react";
import classNames from "classnames";
import { Segment, Header, Grid, Icon, Popup, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { recommendationTaskShape } from "constants/Recommendations/types";
import SprayInformationForm from "./SprayInformationForm";
import styles from "./RecommendationSidebar.module.css";
import SelectedAreasTable from "../../SelectedAreasTable";

const LocationTab = ({ currentTask = [], onEditTask }) => {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <>
      <Segment>
        <SelectedAreasTable recommendationTask={currentTask} />
      </Segment>
      <Segment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header
                size="big"
                className={classNames("bold", styles.editableHeader)}
              >
                Spray information
                {!isEditing && (
                  <Popup
                    inverted
                    position="bottom center"
                    content={"Edit"}
                    size="tiny"
                    trigger={
                      <div className={styles.editButtonWrapper}>
                        <Button
                          onClick={() => {
                            setIsEditing(true);
                          }}
                          className={styles.editButton}
                          floated="right"
                          icon={<Icon name="edit" />}
                          size="small"
                        />
                      </div>
                    }
                  />
                )}
              </Header>
            </Grid.Column>
          </Grid.Row>
          <SprayInformationForm
            isEditing={isEditing}
            currentTask={currentTask}
            onEditTask={onEditTask}
            onCancel={() => setIsEditing(false)}
          />
        </Grid>
      </Segment>
    </>
  );
};

LocationTab.propTypes = {
  currentTask: recommendationTaskShape,
  onEditTask: PropTypes.func
};

export default LocationTab;
