import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  Segment,
  Header,
  Popup,
  Icon
} from "semantic-ui-react";
import PropTypes from "prop-types";
import { Field, Formik, FieldArray } from "formik";
import { get, map } from "lodash";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { shedsListSelector } from "selectors/sheds";
import { batchNumberSelector } from "selectors/purchaseHistory";
import { chemicalsOptionsSelector } from "selectors/chemicals";
import DatePicker from "components/DatePicker/DatePicker";
import styles from "./NewProduct.module.css";
import { matterToUnits } from "utils/constToUnits";
const NewProductForm = ({
  quantity,
  setDirty,
  setValidation,
  setCheckForm,
  addButtonCheck,
  quantitiesListCheck,
  chemicalId,
  productsListCheck,
  deleteButtonCheck,
  deleteAddingForm,
  onSubmit
}) => {
  const { options: batchNumberOptions, isFetching } = useSelector(
    batchNumberSelector
  );
  const shownOptions = map(batchNumberOptions, ({ label, ...rest }) => ({
    ...rest,
    text: label
  }));
  const [searchQuery, setSearchQuery] = useState();
  const [resetRequired, setResetRequired] = useState(false);
  const [expirationDate, setExpirationDate] = useState();
  const [manufacturerDate, setManufactureDate] = useState();
  const shedsList = useSelector(shedsListSelector);
  const productList = useSelector(chemicalsOptionsSelector);

  const validationSchema = () => {
    return Yup.object().shape({
      batchNumber: Yup.string().required("quantity is required"),
      unitsNumber: Yup.number().required("quantity is required"),
      unitSize: Yup.number().required("quantity is required"),
      shedId: Yup.string().required("quantity is required")
    });
  };
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={values => {
        onSubmit(values);
        setResetRequired(true);
      }}
      initialValues={quantity || {}}
    >
      {({ values, dirty, isValid, setValues, handleSubmit }) => {
        setValidation(isValid);
        setDirty(dirty);
        return (
          <Form onSubmit={handleSubmit}>
            <>
              <Form.Group>
                <Form.Field required width={10}>
                  <label>Batch number</label>
                  <FieldArray
                    name="batchNumber"
                    render={() => (
                      <div className={styles.batchNumberWrapper}>
                        {values.batchNumber && (
                          <Segment className={styles.batchNumberSection}>
                            <Header
                              floated="left"
                              className={styles.batchNumberHeader}
                              size="small"
                            >
                              {values.batchNumber}
                            </Header>
                            <Popup
                              content="Delete batch number"
                              position="bottom center"
                              inverted
                              trigger={
                                <Icon
                                  name="trash alternate"
                                  className={styles.removeBatchNumber}
                                  onClick={() => {
                                    return setValues({
                                      ...values,
                                      batchNumber: ""
                                    });
                                  }}
                                />
                              }
                            />
                          </Segment>
                        )}
                        {!values.batchNumber && (
                          <Field
                            fluid
                            as={Form.Select}
                            allowAdditions
                            search
                            searchQuery={searchQuery}
                            onSearchChange={(_, { searchQuery }) => {
                              setSearchQuery(searchQuery);
                            }}
                            // loading={isFetching}
                            name="batchNumber"
                            onChange={(e, { value }) => {
                              return setValues({
                                ...values,
                                batchNumber: value
                              });
                            }}
                            options={shownOptions}
                          />
                        )}
                      </div>
                    )}
                  />
                </Form.Field>
                <Form.Field required width={10}>
                  <label>No. of units</label>
                  <Field
                    as={Input}
                    name="unitsNumber"
                    onKeyPress={event => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={values.unitsNumber}
                  />
                </Form.Field>
                <Form.Field required width={10}>
                  <label>Unit size</label>
                  <Field
                    as={Input}
                    name="unitSize"
                    title=" "
                    labelPosition="right"
                    label={
                      chemicalId
                        ? matterToUnits(
                            get(
                              productList.find(
                                item => item.key == chemicalId && item
                              ),
                              "matter"
                            )
                          )
                        : "Kg"
                    }
                    onKeyPress={event => {
                      if (!/^[0-9.]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={values.unitSize}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field width={10}>
                  <label>Manufacture date</label>
                  <DatePicker
                    name="manufacturerDate"
                    trigger={false}
                    focused={false}
                    value={values.manufacturerDate}
                    onChange={manufacturerDate => {
                      return (
                        setManufactureDate(manufacturerDate),
                        setValues({
                          ...values,
                          manufacturerDate
                        })
                      );
                    }}
                  />
                </Form.Field>
                <Form.Field width={10}>
                  <label>Expiration date</label>
                  <DatePicker
                    name="expirationDate"
                    trigger={false}
                    focused={false}
                    value={values.expirationDate}
                    onChange={expirationDate => {
                      return (
                        setExpirationDate(expirationDate),
                        setValues({
                          ...values,
                          expirationDate
                        })
                      );
                    }}
                    disableClear
                  />
                </Form.Field>
                <Form.Field width={10} required>
                  <Form.Dropdown
                    selection
                    search
                    name="shedId"
                    label="Shed"
                    required
                    options={shedsList}
                    value={get(values, "shedId")}
                    onChange={(e, { value }) => {
                      return setValues({
                        ...values,
                        shedId: value
                      });
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <div className={styles.buttonContainer}>
                {(deleteButtonCheck && productsListCheck) || quantity ? (
                  <Button
                    type="button"
                    className={styles.cancelBtn}
                    onClick={deleteAddingForm}
                  >
                    Cancel
                  </Button>
                ) : null}
                <Button
                  type="button"
                  className={
                    !dirty || !isValid ? styles.disabledBtn : styles.saveButton
                  }
                  disabled={!dirty || !isValid}
                  onClick={() => {
                    onSubmit(values);
                  }}
                >
                  Save
                </Button>
              </div>
            </>
          </Form>
        );
      }}
    </Formik>
  );
};

NewProductForm.propTypes = {
  quantity: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func
};

export default NewProductForm;
