import { diff } from "deep-object-diff";
import {
  get,
  transform,
  isUndefined,
  isEqual,
  isObject,
  isArray
} from "lodash";
import {
  deleteTempIdFromWorkingTimes,
  prepareTaskData
} from "./prepareTaskData";

const deepObjectDiff = (object, base) => {
  function changes(object, base) {
    return transform(object, function(result, value, key) {
      if (base ? isUndefined(base[key]) : isUndefined([][key])) {
        result[key] = value;
      } else {
        if (!isEqual(value, base[key])) {
          result[key] =
            isObject(value) || isArray(value)
              ? changes(value, base[key])
              : value;
        }
      }
    });
  }
  return changes(object, base);
};

function difference(origObj, newObj) {
  function changes(newObj, origObj) {
    let arrayIndexCounter = 0;
    return transform(newObj, function(result, value, key) {
      if (!isEqual(value, origObj[key])) {
        let resultKey = isArray(origObj) ? arrayIndexCounter++ : key;
        result[resultKey] =
          isObject(value) && isObject(origObj[key])
            ? changes(value, origObj[key])
            : value;
      }
    });
  }
  return changes(newObj, origObj);
}

const getListWithActions = (originalArray, updatedArray, keyToCheck) => {
  originalArray = originalArray ? originalArray : [];
  const originalIds = originalArray.map(item => get(item, keyToCheck));
  const updatedIds = updatedArray.map(item => get(item, keyToCheck));
  const itemsDeleted = originalArray
    .filter(item => !updatedIds.includes(get(item, keyToCheck)))
    .map(item => ({ ...item, action: "DELETE" }));
  const itemsNew = updatedArray
    .filter(item => !originalIds.includes(get(item, keyToCheck)))
    .map(item => ({ ...item, action: "CREATE" }));
  const itemsUpdated = updatedArray
    .filter(item => {
      const originalIndex = originalIds.indexOf(get(item, keyToCheck));
      return (
        originalIds.includes(get(item, keyToCheck)) &&
        (originalIndex >= 0
          ? Object.keys(diff(item, originalArray[originalIndex])).length > 0
          : true)
      );
    })
    .map(item => {
      const reqField = keyToCheck.split(".")[0];
      const dataToSend =
        reqField === "chemical"
          ? item
          : diff(
              originalArray[originalIds.indexOf(get(item, keyToCheck))],
              item
            );
      return {
        ...dataToSend,
        [reqField]: item[reqField],
        action: "UPDATE"
      };
    });

  return [...itemsDeleted, ...itemsUpdated, ...itemsNew];
};

export const prepareTaskDataToSend = ({ sprayDiary, fetchedTask }) => {
  const dataUpdated = prepareTaskData({ sprayDiary });
  const dataOriginal = prepareTaskData({
    sprayDiary: fetchedTask
  });

  const changedValues = diff(dataOriginal, dataUpdated);

  if (changedValues.signature) {
    changedValues.signature = dataUpdated.signature;
  }
  if (changedValues.areas) {
    changedValues.areas = getListWithActions(
      dataOriginal.areas,
      dataUpdated.areas,
      "area.id"
    );
  }

  if (changedValues.chemicals) {
    changedValues.chemicals = getListWithActions(
      dataOriginal.chemicals,
      dataUpdated.chemicals,
      "chemical.id"
    );
  }

  if (changedValues.attachments) {
    changedValues.attachments = getListWithActions(
      dataOriginal.attachments,
      dataUpdated.attachments,
      "id"
    );
  }

  if (changedValues.supervisors) {
    changedValues.supervisors = getListWithActions(
      dataOriginal.supervisors,
      dataUpdated.supervisors,
      "employee.id"
    );
  }

  if (changedValues.assignees) {
    changedValues.assignees = getListWithActions(
      dataOriginal.assignees,
      dataUpdated.assignees,
      "employee.id"
    );
  }

  if (changedValues.workingTimes) {
    const updatedWorkingTimes = deleteTempIdFromWorkingTimes(
      dataUpdated.workingTimes
    );
    changedValues.workingTimes = getListWithActions(
      dataOriginal.workingTimes,
      updatedWorkingTimes,
      "id"
    );
  }

  return changedValues;
};
