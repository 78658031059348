import React, { useState } from "react";
import PropTypes from "prop-types";
import { Accordion, Icon } from "semantic-ui-react";

/*
  @deprecated, use CollapsibleBlock instead
 */
const CollapseBlock = ({
  title,
  icon,
  children,
  fontSize = 20,
  paddingBottom = 20,
  className = "",
  defaultClosed
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultClosed ? -1 : 0);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;
    setActiveIndex(newIndex);
  };

  return (
    <Accordion className={className} style={{ paddingBottom }}>
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        style={{
          fontSize,
          fontFamily: "Roboto,sans-serif",
          height: "45px",
          padding: "15px",
          paddingLeft: "0px",
          color:'#149BA5'
        }}
        onClick={handleClick}
      >
        <Icon name="dropdown" />
        {icon && (
          <Icon
            style={{ marginRight: 12 }}
            {...(icon.indexOf("tuf-") === 0
              ? { className: icon }
              : { name: icon })}
          />
        )}
        {title}
      </Accordion.Title>
      <Accordion.Content
        style={{ paddingTop: "0px" }}
        active={activeIndex === 0}
      >
        {children}
      </Accordion.Content>
    </Accordion>
  );
};

CollapseBlock.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  icon: PropTypes.any,
  defaultClosed: PropTypes.bool,
  className: PropTypes.string,
  fontSize: PropTypes.number,
  paddingBottom: PropTypes.number
};

export default CollapseBlock;
