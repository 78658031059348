import React, { useState, useRef, useEffect } from "react";
import drawIcon from "assets/img/drawIcon.svg";
import activeDrawIcon from "assets/img/activeDrawIcon.svg";
import { Modal, Button, Menu, Icon } from "semantic-ui-react";
import styles from "./Signature.module.css";
import SignaturePadComponent from "./components/Pad";
import ObjectId from "bson-objectid";
import { connect, useDispatch } from "react-redux";
import { uploadSignature, signatureDelete } from "actions/SprayDiary/common";
import SignatureGenerator from "./components/SignatureGenerator";

function SignatureModal({
  open,
  signatureUrl,
  onClose,
  submitAndClose,
  isUploadingAttachment
}) {
  const [activeTab, setactiveTab] = useState("Type");
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const signaturePadRef = useRef(null);
  const dispatch = useDispatch();

  const handleItemClick = (e, { name }) => {
    setactiveTab(name);
  };

  const handleSignatureChange = isEmpty => {
    setIsSignatureEmpty(isEmpty);
  };
  useEffect(() => {
    if (signatureUrl) {
      setIsSignatureEmpty(false);
    } else {
      setIsSignatureEmpty(true);
    }
  }, [signatureUrl, activeTab]);

  const dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const handleSubmit = async () => {
    if (signaturePadRef.current) {
      const dataUrl = signaturePadRef.current.getSignatureData();
      if (dataUrl) {
        const signatureFile = dataURLtoFile(dataUrl, "signature.png");
        const idReplace = ObjectId().toHexString();
        onClose();
        try {
          await dispatch(
            uploadSignature({
              file: signatureFile,
              idReplace,
              serviceWorkerAction: {
                url: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/spray_tasks/partial/`
              }
            })
          );

          submitAndClose();
        } catch (error) {
          console.error("Error uploading signature:", error);
        }
      }
    }
  };

  return (
    <Modal
      size={"tiny"}
      open={open}
      onClose={onClose}
      className={styles.signatureModal}
    >
      <Modal.Header className={styles.signatureModal_header}>
        Add a digital signature
        <div className={styles.tabSection}>
          <Menu pointing secondary className={styles.tabsMenu}>
            <Menu.Item
              name="Type"
              active={activeTab === "Type"}
              onClick={handleItemClick}
            >
              <Icon className={styles.typeIcon} name="keyboard outline" />
              Type
            </Menu.Item>
            <Menu.Item
              name="Draw"
              active={activeTab === "Draw"}
              onClick={handleItemClick}
            >
              <img
                className={styles.drawIcon}
                src={activeTab === "Draw" ? activeDrawIcon : drawIcon}
                alt="Draw icon"
              />
              Draw
            </Menu.Item>
          </Menu>
        </div>
      </Modal.Header>
      <Modal.Content className={styles.content}>
        {activeTab === "Draw" ? (
          <SignaturePadComponent
            signatureDelete={signatureDelete}
            ref={signaturePadRef}
            signatureUrl={signatureUrl}
            onSignatureChange={handleSignatureChange}
            activeTab={activeTab}
          />
        ) : (
          <SignatureGenerator
            signatureDelete={signatureDelete}
            ref={signaturePadRef}
            signatureUrl={signatureUrl}
            onSignatureChange={handleSignatureChange}
            activeTab={activeTab}
          />
        )}
      </Modal.Content>
      <Modal.Actions className={styles.signatureModal_footer}>
        <Button
          className={styles.cancelButton}
          content="Cancel"
          onClick={() => onClose()}
        />
        <Button
          loading={isUploadingAttachment}
          disabled={isSignatureEmpty}
          className={styles.saveButton}
          content="Save"
          onClick={handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default connect(state => ({
  signatureUrl: state.sprayDiary.signature?.presignedUrl,
  isUploadingAttachment: state.sprayDiary.isUploadingAttachment
}))(SignatureModal);
