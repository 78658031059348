import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formsy-semantic-ui-react';
import { Button, Icon, Table } from 'semantic-ui-react';

import styles from './BarcodesTable.module.css';
import { validationErrors } from '../../../../../../../utils/validationRules';
import ErrorLabel from '../../../../../../../components/ErrorLabel';
import { validateBarcode } from '../../../../../../../utils/employeesBarcodes';

const BarcodesTable = ({ barcodes, addBarcode, removeBarcode }) => {
  const [newBarcode, setNewBarcode] = useState('');
  const formRef = useRef(null);
  const firstErrorIndex = useMemo(() => barcodes.findIndex(barcode => barcode.error), [barcodes]);

  const scrollToBarcodeRef = useCallback(node => {
    if (node !== null) {
      node.scrollIntoView();
    }
  }, []);

  const onValidSubmit = useCallback(() => {
    addBarcode(newBarcode);
    formRef.current.reset();
  }, [addBarcode, newBarcode]);

  return (
    <Form autoComplete="off" onValidSubmit={onValidSubmit} loading={false} ref={formRef}>
      <Table unstackable compact basic="very" className={`${styles.barcodesTable}`}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Barcodes</Table.HeaderCell>
            <Table.HeaderCell>Total scanned: {barcodes.length}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {barcodes.map((barcode, index) => (
            <Table.Row key={barcode.barcode} className={barcode.error ? styles.barcodesRow : null}>
              <Table.Cell>
                <span
                  {...(index !== (firstErrorIndex !== -1 ? firstErrorIndex : barcodes.length - 1)
                    ? {}
                    : { ref: scrollToBarcodeRef })}
                >
                  {barcode.barcode}
                </span>
              </Table.Cell>
              <Table.Cell>
                <Button
                  icon={<Icon className="tuf-trash-alt" />}
                  className="button-text"
                  onClick={() => removeBarcode(barcode)}
                  type="button"
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Form.Input
                name="newBarcode"
                validations={'validBarcode'}
                validationErrors={validationErrors()}
                errorLabel={ErrorLabel}
                value={newBarcode}
                placeholder="Enter or scan barcode"
                fluid
                className={styles.newBarcode}
                icon={
                  <Icon
                    name="delete"
                    link
                    onClick={() => {
                      formRef.current.reset();
                      setNewBarcode('');
                    }}
                  />
                }
                onChange={async e => {
                  if (newBarcode === '' && validateBarcode(e.target.value)) {
                    addBarcode(e.target.value);
                    formRef.current.reset();
                  } else {
                    setNewBarcode(e.target.value);
                  }
                }}
              />
            </Table.Cell>
            <Table.Cell>
              <Button onClick={() => formRef.current.submit()} primary type="button" positive={true}>
                Add
              </Button>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Form>
  );
};

BarcodesTable.propTypes = {
  barcodes: PropTypes.array.isRequired,
  addBarcode: PropTypes.func.isRequired,
  removeBarcode: PropTypes.func.isRequired,
};

BarcodesTable.defaultProps = {
  addBarcode: () => {},
  removeBarcode: () => {},
};

export default BarcodesTable;
