import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Popup
} from "semantic-ui-react";
import GeneralInfo from "../components/GeneralInfo";
import RoundsInfo from "../components/RoundsInfo";
import { connect, useDispatch } from "react-redux";
import Loader from "components/Loader";
import { Formik } from "formik";
import GeneralAdd from "./GeneralAdd";
import TabsAdd from "./Tabs/TabsAdd";
import styles from "./AddSideBar.module.css";
import { addQC } from "actions/QualityControl";

function AddSideBar({ activeItem, onClose, employees, isFetching }) {
  const dispatch = useDispatch();
  const [openParametrAdd, setOpenParametrAdd] = useState(false);
  const [openWorkerAdd, setOpenWorkerAdd] = useState(false);
  const [openSecondSideBar, setOpenSecondSideBar] = useState(false);
  const [activeRoundsNumber, setActiveRoundsNumber] = useState(null);

  useEffect(() => {
    if (activeItem === null) {
      setOpenSecondSideBar(false);
      setOpenWorkerAdd(false);
      setOpenParametrAdd(false);
      setActiveRoundsNumber(null);
    }
  }, [activeItem]);

  const onsubmit = data => {
    dispatch(addQC(data));
    onClose();
  };
  return (
    <Container fluid className={styles.root}>
      <Formik
        enableReinitialize
        validateOnChange={false}
        initialValues={{
          name: "",
          barcodeId: "",
          rounds: "",
          unitToCheckPerRound: "",
          qcRounds: [
            {
              roundNumber: 1,
              defectReports: [],
              employeeResults: [],
              pictures: []
            }
          ]
        }}
      >
        {({ values, setFieldValue }) => {
          const validation =
            !values.name ||
            !values.barcodeId ||
            !values.rounds ||
            !values.unitToCheckPerRound ||
            !values.qcRounds.every(item => {
              return item.defectReports.filter(i => i.parameter).length;
            });
          return (
            <Form className={styles.contentWrapper}>
              {!openSecondSideBar ? (
                <>
                  <Grid className={styles.sidebarHeader}>
                    <Grid.Row columns={"equal"} verticalAlign="middle">
                      <Grid.Column className={styles.headerColumn}>
                        <Header className={styles.name} as="h1">
                          Add Quality Control task
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={1} floated="right">
                        <Popup
                          inverted
                          position="bottom center"
                          content={"Close"}
                          size="tiny"
                          trigger={
                            <Button
                              onClick={onClose}
                              className={styles.closeButton}
                              floated="right"
                              icon={<Icon className="tuf-times" />}
                              size="small"
                            />
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Grid.Row className={styles.overAllSummaryWrapper}>
                    <Header className={styles.name} as="h2">
                      General information
                    </Header>
                    <GeneralAdd />
                  </Grid.Row>
                  <Grid.Row className={styles.roundsInfoWrapper}>
                    {isFetching ? (
                      <Loader />
                    ) : (
                      <RoundsInfo
                        setActiveRoundsNumber={setActiveRoundsNumber}
                        setOpenSecondSideBar={setOpenSecondSideBar}
                        activeItem={values}
                        employees={employees}
                        header={values?.name}
                      />
                    )}
                    <div className={styles.addRoundBtnWrapper}>
                      <Button
                        className={styles.addBtn}
                        disabled={values.qcRounds.some(
                          i => !i.defectReports.length
                        )}
                        content="+ Add a round"
                        color="green"
                        onClick={() => {
                          setOpenSecondSideBar(true);
                          setActiveRoundsNumber(values.qcRounds.length);
                          setFieldValue("qcRounds", [
                            ...values.qcRounds,
                            {
                              roundNumber: +values.qcRounds.length + 1,
                              defectReports: [],
                              employeeResults: [],
                              pictures: []
                            }
                          ]);
                        }}
                      />
                    </div>
                  </Grid.Row>
                  {openSecondSideBar && (
                    <Grid className={styles.sidebarHeader}>
                      <Grid.Row columns={"equal"} verticalAlign="middle">
                        <Grid.Column className={styles.headerColumn}>
                          <Header className={styles.name} as="h1">
                            {activeItem?.name}
                          </Header>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  <Grid className={styles.sidebarHeader}>
                    <Grid.Row columns={"equal"} verticalAlign="middle">
                      <Grid.Column className={styles.subSideBarheader}>
                        <Popup
                          inverted
                          position="bottom center"
                          content={"Back"}
                          size="tiny"
                          trigger={
                            <Button
                              onClick={() => (
                                setOpenSecondSideBar(false),
                                setOpenWorkerAdd(false),
                                setOpenParametrAdd(false),
                                setActiveRoundsNumber(null)
                              )}
                              className={styles.backButton}
                              floated="right"
                              icon={<Icon className="angle left" />}
                              size="small"
                            />
                          }
                        />
                        <Header className={styles.roundsName} as="h1">
                          {`Round ${activeRoundsNumber + 1}`}
                        </Header>
                      </Grid.Column>
                      <Grid.Column width={1} floated="right">
                        <Popup
                          inverted
                          position="bottom center"
                          content={"Close"}
                          size="tiny"
                          trigger={
                            <Button
                              onClick={onClose}
                              className={styles.closeButton}
                              floated="right"
                              icon={<Icon className="tuf-times" />}
                              size="small"
                            />
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <GeneralInfo activeItem={values} />
                  <TabsAdd
                    activeItem={values}
                    activeRoundsNumber={activeRoundsNumber}
                    setFieldValue={setFieldValue}
                    setOpenParametrAdd={setOpenParametrAdd}
                    openParametrAdd={openParametrAdd}
                    setOpenWorkerAdd={setOpenWorkerAdd}
                    openWorkerAdd={openWorkerAdd}
                  />
                </>
              )}
              <div className={styles.buttonContainer}>
                <Button
                  content="Cancel"
                  type="text"
                  onClick={() => onClose()}
                />
                <Button
                  content={"Save"}
                  primary
                  onClick={() => onsubmit(values)}
                  disabled={validation}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
}
export default connect((state, props) => {
  return {
    isFetching: state.qualityControl.data.isFetching
  };
})(AddSideBar);
