import axios from "axios";
import { axiosProxy } from "./axiosProxy";

export default ({
  url,
  file,
  startAction,
  successAction,
  errorAction,
  progressAction,
  createUploadRequestCancelTokenAction,
  callBack,
  serviceWorkerAction,
  idReplace,
  presignedUrl,
  data,
  parentId
}) => async (dispatch, getState) => {
  const {
    user: { user },
    offline: { online }
  } = getState();
  const cancelTokenSource = axios.CancelToken.source();
  if (cancelTokenSource && createUploadRequestCancelTokenAction) {
    dispatch({
      type: createUploadRequestCancelTokenAction,
      payload: cancelTokenSource
    });
  }

  const nextStep = {
    url: "presignedUrl",
    method: "PUT",
    data: file,
    type: "FILE",
    externalApi: true,
    skipResponse: true,
    headers: {
      "Content-Type": "application/octet-stream"
    },
    dataToAdd: {
      name: file.name,
      online,
      idReplace,
      data,
      parentId
    },
    callBack,
    serviceWorkerAction,
    onUploadProgress: progressEvent => {
      if (progressAction) {
        dispatch({
          type: progressAction,
          payload: Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
        });
      }
    },
    cancelToken: cancelTokenSource.token
  };

  return dispatch({
    type: startAction,
    payload: {
      ...(successAction === "SPRAYDIARY/UPLOAD_SIGNATURE" && { presignedUrl }),
      name: file.name,
      idReplace,
      data
    },
    meta: {
      forceSW: true,
      reqId: idReplace,
      online,
      user,
      offline: {
        // the network action to execute:
        effect: {
          url,
          method: "GET",
          nextStep
        },
        commit: {
          type: successAction,
          meta: {}
        },
        rollback: {
          type: errorAction,
          meta: { idReplace, name: file.name, parentId }
        }
      }
    }
  });
};

export const genericAttachmentUploaderWithoutSW = ({
  url,
  file,
  startAction,
  successAction,
  errorAction,
  idReplace,
  data
}) => async dispatch => {
  dispatch({
    type: startAction,
    payload: { name: file.name, idReplace, data }
  });

  try {
    const res = await axiosProxy({ method: "GET", url: `${url}` });

    await axiosProxy({
      method: "PUT",
      url: res.data.presignedUrl,
      data: file,
      type: "FILE",
      externalApi: true,
      headers: { "Content-Type": "application/octet-stream" }
    });

    dispatch({
      type: successAction,
      payload: {
        idReplace,
        responseData: { name: file.name, ...res.data },
        localData: { name: file.name, id: idReplace, data }
      }
    });

    return { name: file.name, ...res.data };
  } catch (e) {
    dispatch({ type: errorAction, payload: e });
  }
};
