import React from "react";
import { Grid, Input, Select, Button } from "semantic-ui-react";
import { Formik } from "formik";
import { getSprayInformationData } from "../../../utils";
import styles from "./RecommendationSidebar.module.css";

const SprayInformationForm = ({
  isEditing,
  currentTask,
  onEditTask,
  onCancel
}) => {
  const sprayInformationList = getSprayInformationData(currentTask);

  const onSubmit = async values => {
    await onEditTask(values);
    onCancel();
  };
  return !isEditing ? (
    <>
      {sprayInformationList.map(({ title, value, key, options, ...input }) => (
        <Grid.Row columns="equal" className="py-10" verticalAlign="middle">
          <Grid.Column className="bold">{title}</Grid.Column>
          <Grid.Column>{value || "—"}</Grid.Column>
        </Grid.Row>
      ))}
    </>
  ) : (
    <Formik initialValues={currentTask} onSubmit={onSubmit}>
      {props => (
        <>
          {sprayInformationList.map(
            ({ title, value, key, options, ...input }) => (
              <Grid.Row
                columns="equal"
                className="py-10"
                verticalAlign="middle"
              >
                <Grid.Column className="bold">{title}</Grid.Column>
                <Grid.Column>
                  {!options ? (
                    <Input
                      fluid
                      min={input.min}
                      max={input.max}
                      type={input.type}
                      label={input.label}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values[key]}
                      name={key}
                      {...(input.label && { labelPosition: "right" })}
                    />
                  ) : (
                    <Select
                      fluid
                      options={options}
                      onChange={(_, { value }) => {
                        props.setFieldValue(key, value);
                      }}
                      onBlur={props.handleBlur}
                      value={props.values[key]}
                      name={key}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            )
          )}
          <Grid.Row className={styles.btnWrapper}>
              <Button className={styles.cancelBtn} onClick={onCancel} disabled={props.isSubmitting}>
                Cancel
              </Button>
              <Button
                disabled={!props.dirty || props.isSubmitting}
                onClick={props.handleSubmit}
                primary
              >
                Save
              </Button>
          </Grid.Row>
        </>
      )}
    </Formik>
  );
};

export default SprayInformationForm;
