import PropTypes from "prop-types";
import React from "react";
import styles from "./styles.module.css";
import QRCode from "qrcode.react";
import Logo from "./Logo";
import classNames from "classnames";

const GeneralBarcode = ({
  barcode,
  minimized,
  fullHeight,
  qrWidth,
  qrHeight
}) => {
  const { numberPart, ...labels } = barcode;

  return (
    <table
      className={classNames("barcode", styles.barcode, {
        [styles.minimized]: minimized,
        [styles.fullHeight]: fullHeight
      })}
      style={{ marginBottom: 10 }}
    >
      <tr style={{ width: 70 }}>
        <td
          style={{
            width: "50%",
            textAlign: "center",
            paddingTop: 0,
            paddingBottom: 0
          }}
          rowSpan="3"
        >
          <QRCode
            className={"qr-code"}
            style={{ width: qrWidth, height: qrHeight }}
            renderAs="svg"
            value={numberPart}
          />
        </td>
        <td style={{ textAlign: "center", background:'#004F5D' }}>
          <Logo />
        </td>
      </tr>
      <tr style={{ height: 36 }}>
        <td>{labels.farmLabel}</td>
      </tr>
      <tr style={{ height: 20 }}>
        <td>{labels.dateLabel}</td>
      </tr>
      <tr style={{ height: 20 }}>
        <td rowSpan="2">{numberPart}</td>
        <td>{labels.blockLabel}</td>
      </tr>
      <tr style={{ height: 20 }}>
        <td>{labels.varietyLabel}</td>
      </tr>
      <tr className={"comments-cell"} style={{ height: 70 }}>
        <td colSpan="2">{labels.comment}</td>
      </tr>
    </table>
  );
};

GeneralBarcode.propTypes = {
  barcode: PropTypes.object.isRequired,
  fullHeight: PropTypes.bool,
  minimized: PropTypes.bool,
  qrHeight: PropTypes.number,
  qrWidth: PropTypes.number
};

GeneralBarcode.defaultProps = {
  qrHeight: 130,
  qrWidth: 130
};

export default GeneralBarcode;
