import React, { useState } from "react";
import { Grid, Button, Segment } from "semantic-ui-react";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import SeasonsRow from "./SeasonsRow";
import SeasonsForm from "./SeasonsForm";
import { createSettings } from "actions/Settings";
import styles from "../Setting.module.css";

const Seasons = ({ settings }) => {
  const dispatch = useDispatch();
  const [addButtonCheck, setAddButtonCheck] = useState(true);
  const onSubmit = async (formValues, index, isEditing, isDeleting) => {
    const newSeason = settings.seasons?.map((item, ind) =>
      ind === index ? formValues : item
    );
    const filtredSeason = settings.seasons?.filter(
      (item, ind) => ind !== index
    );
    const dataToSend = isEditing
      ? {
          ...settings,
          seasons: newSeason
        }
      : isDeleting
      ? {
          ...settings,
          seasons: filtredSeason
        }
      : {
          ...settings,
          seasons: settings.seasons
            ? [
                ...settings.seasons,
                {
                  seasonName: formValues.seasonName,
                  startAt: formValues.startAt,
                  endAt: formValues.endAt
                }
              ]
            : [
                {
                  seasonName: formValues.seasonName,
                  startAt: formValues.startAt,
                  endAt: formValues.endAt
                }
              ]
        };
    dispatch(createSettings(dataToSend)).then(setAddButtonCheck(true));
  };

  const addProduct = () => {
    setAddButtonCheck(false);
  };
  const deleteAddingForm = () => {
    setAddButtonCheck(true);
  };

  return (
    <>
      {!isEmpty(settings.seasons) && (
        <Grid className={styles.rowContainer} columns="equal">
          {settings.seasons &&
            settings.seasons.map((item, index) => (
              <SeasonsRow
                onSubmit={onSubmit}
                deleteAddingForm={deleteAddingForm}
                item={item}
                index={index}
                settings={settings}
              />
            ))}
        </Grid>
      )}
      <Grid.Row className={styles.addButtonWrapper}>
        {addButtonCheck ? (
          <div className={styles.addButtonContainer}>
            <Button
              type="submit"
              className={styles.addSeasonBtn}
              onClick={addProduct}
            >
              + Add season
            </Button>
          </div>
        ) : null}
      </Grid.Row>

      {!addButtonCheck && (
        <>
          <Segment className={styles.seasonsSegment}>
            <SeasonsForm
              settings={settings}
              deleteAddingForm={deleteAddingForm}
              onSubmit={onSubmit}
            />
          </Segment>
        </>
      )}
    </>
  );
};
export default Seasons;
