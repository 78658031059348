import React, { useState } from "react";
import { Button, Grid, Header, Icon, Input, Popup } from "semantic-ui-react";
import { getGeneralInfoData } from "../../../utils";
import { Field, Formik } from "formik";
import classNames from "classnames";
import styles from "./RecommendationSidebar.module.css";
import { validateRecommendationForm } from "../../../../../utils/recommendations";

const SidebarGeneralInformation = ({ currentTask, onEditTask }) => {
  const [isEditing, setIsEditing] = useState(false);
  const generalInfoData = currentTask ? getGeneralInfoData(currentTask) : [];
  const onSubmit = async values => {
    await onEditTask(values);
    setIsEditing(false);
  };

  return (
    <Grid.Row className="py-10">
      <Grid padded>
        <Grid.Row>
          <Grid.Column>
            <Header
              size="big"
              className={classNames("bold", styles.editableHeader)}
            >
              General information
              {!isEditing && (
                <Popup
                  inverted
                  position="bottom center"
                  content={"Edit"}
                  size="tiny"
                  trigger={
                    <div className={styles.editButtonWrapper}>
                      <Button
                        onClick={() => {
                          setIsEditing(true);
                        }}
                        className={styles.editButton}
                        floated="right"
                        icon={<Icon name="edit" />}
                        size="small"
                      />
                    </div>
                  }
                />
              )}
            </Header>
          </Grid.Column>
        </Grid.Row>
        {!isEditing ? (
          <>
            {generalInfoData.map(({ title, value }) => (
              <Grid.Row className="py-10" columns="equal">
                <Grid.Column>{title}</Grid.Column>
                <Grid.Column className="bold">{value || "—"}</Grid.Column>
              </Grid.Row>
            ))}
          </>
        ) : (
          <Formik
            validate={validateRecommendationForm}
            initialValues={currentTask}
            onSubmit={onSubmit}
          >
            {props => (
              <>
                {generalInfoData.map(
                  ({ title, value, key, options, ...rest }) => (
                    <Grid.Row
                      className="py-10"
                      verticalAlign="middle"
                      key={title}
                    >
                      <Grid.Column width={4}>{title}</Grid.Column>
                      <Grid.Column width={12}>
                        {rest.editable ? (
                          <Field
                            fluid
                            as={rest.as || Input}
                            action={rest.action}
                            type={rest.type}
                            name={key}
                            disabled={rest.disabled}
                          />
                        ) : (
                          <span className="bold">{value || "—"}</span>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  )
                )}
                <Grid.Row className={styles.btnWrapper}>
                    <Button
                      onClick={() => {
                        setIsEditing(false);
                      }}
                      disabled={props.isSubmitting}
                      className={styles.cancelBtn}
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        !props.dirty || !props.isValid || props.isSubmitting
                      }
                      onClick={props.handleSubmit}
                      primary
                    >
                      Save
                    </Button>
                </Grid.Row>
              </>
            )}
          </Formik>
        )}
      </Grid>
    </Grid.Row>
  );
};

export default SidebarGeneralInformation;
