import React, { useEffect, useState } from "react";
import { Message, Grid, Transition } from "semantic-ui-react";
import styles from "./ActionMessage.module.css";

const DURATION = 5000;

export const ActionMessage = ({ onClose, errorMessages }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (visible) {
      const tmt = setTimeout(() => {
        setVisible(false);
      }, DURATION);
    }
  }, [visible]);

  return (
    <div className={styles.deletedMessage}>
      <div className={styles.deletedMessageIn}>
        <Transition
          visible={visible}
          animation="fade"
          duration={1000}
          onHide={onClose}
        >
          <Message
            size="small"
            style={{
              paddingBlock: 0,
              background: "#FFF4F6",
              color: "#D92D20",
              boxShadow: "none"
            }}
          >
            <Grid style={{ padding: 0 }} verticalAlign="middle">
              <Grid.Column floated="left" width={10}>
                {errorMessages}
              </Grid.Column>
              <Grid.Column floated="right" width={6} textAlign={"right"}>
                <button className={styles.undoButton} onClick={onClose}>
                  Got it
                </button>{" "}
              </Grid.Column>
            </Grid>
          </Message>
        </Transition>
      </div>
    </div>
  );
};
