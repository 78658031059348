import React from "react";
import { Button, Container, Header, Icon, Table,Popup } from "semantic-ui-react";
import { RATE_TYPES } from "constants/shared";
import { matterToUnits } from "utils/constToUnits";
import Numeric from "components/Numeric";
import PropTypes from "prop-types";
import AddTableItem from "./AddTableItem";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { get } from "lodash";
import { INTERVENTION_TYPES } from "constants/Recommendations/types";
import { calculateChemicalsValues } from "utils/recommendations";
import styles from "./SelectChemicalsForm.module.css";

const ShowTableItem = ({
  machineryId,
  totalTanks,
  item,
  onEdit,
  onDelete,
  interventionType
}) => {
  const { qtyPerFullVat, qtyPerPartTank } = item;
  const chemical = item;
  const cropName = get(chemical, "rate.crop.name", "Generic");
  const dilutionRate = get(chemical, "rate.rate");
  const target = [cropName, get(chemical, "rate.purpose")]
    .filter(Boolean)
    .join(" - ");

  return (
    <tr className="show">
      <td className={styles.chemicalCell}>
        <div className={styles.title}>Name</div>
        {chemical.name}
      </td>
      <td className={styles.chemicalTypeCell}>
        <div className={styles.title}>Type</div>
        {chemical.type.type}
      </td>
      <td className={styles.cropAndPurposeCell}>
        <div className={styles.title}>Target</div>
        {target}
      </td>
      <td className={styles.cropAndPurposeCell}>
        <div className={styles.title}>Intervention type</div>
        {INTERVENTION_TYPES[interventionType]}
      </td>
      <td className={styles.dilutionCell}>
        <div className={styles.title}>Rate</div>
        {dilutionRate ? dilutionRate || chemical.rate.minRate : ""}{" "}
        {matterToUnits(chemical.matter)}
      </td>
      <td>
        <div className={styles.title}>Rate Type</div>
        {RATE_TYPES[chemical.rate.rateType]}
      </td>
      <td>
        <div className={styles.title}>
          {machineryId ? "Qty Per Full Tank" : "Full Qty"}
        </div>
        <Numeric
          value={totalTanks < 1 ? 0 : qtyPerFullVat}
          fractionDigits={totalTanks < 1 ? 0 : 3}
          units={matterToUnits(chemical.matter)}
        />
      </td>
      {machineryId && (
        <td>
          <div className={styles.title}>Qty Per Part Tank</div>
          <Numeric
            value={qtyPerPartTank}
            units={matterToUnits(chemical.matter)}
          />
        </td>
      )}
      <td className={styles.batchNumberCell}>
        <div className={styles.title}>Batch No.</div>
        {item.batchNumber}
      </td>
      <td className={styles.dateOfManufactureCell}>
        <div className={styles.title}>Date of manufacture</div>
        {item.dateOfManufacture}
      </td>
      <td className={styles.btnContainer}>
        <Popup
          inverted
          position="bottom center"
          content={"Edit"}
          size="tiny"
          trigger={
            <div className={styles.editButtonWrapper}>
              <Button
                onClick={() => onEdit(chemical.id)}
                className={styles.editButton}
                floated="right"
                icon={<Icon name="edit" />}
                size="small"
              />
            </div>
          }
        />
        <Popup
          inverted
          position="bottom center"
          content={"Delete"}
          size="tiny"
          trigger={
            <div className={styles.deleteButton}>
              <Icon
                name="trash alternate"
                onClick={() => onDelete(chemical.id)}
              />
            </div>
          }
        />
      </td>
    </tr>
  );
};

const SelectChemicalsForm = ({
  onSelectChemical,
  selectedChemicals,
  chemicalToAdd,
  actions: { onEditChemicalItem, setAddChemicalSelection },
  deleteChemicalItem,
  addChemicalSelection
}) => {
  const { values } = useFormikContext();
  const machineryList = useSelector(state => state.sprayDiary.machineryList);
  const areasList = useSelector(state =>
    get(state.sprayDiary.areasList, "content", [])
  );
  const { machineryId: selectedMachinery } = values;
  const { chemicals, totalTanks } = calculateChemicalsValues(
    values,
    machineryList,
    areasList
  );
  const shownChemicals = chemicals.map(({ chemical, ...rest }) => ({
    ...chemical,
    ...rest
  }));

  return (
    <>
      <Header as="h3">Select Products</Header>
      <Container fluid className={styles.tableWrapper}>
        <Table className={`chemicals sprayTable ${styles.chemicalsTable}`}>
          <Table.Header>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Target</th>
              <th>Intervention type</th>
              <th>Rate</th>
              <th>Rate Type</th>
              <th>{selectedMachinery ? "Qty Per Full Tank" : "Full Qty"}</th>
              {selectedMachinery && <th>Qty Per Part Tank</th>}
              <th>Batch No.</th>
              <th>Date of Manufacture</th>
              <th>
                <div className={styles.buttonsHolder} />
              </th>
            </tr>
          </Table.Header>
          <Table.Body>
            {[...shownChemicals, chemicalToAdd].map(item => {
              return item.toAdd ? (
                <AddTableItem
                  key={item.new ? "new" : item.id}
                  item={item}
                  onSelectChemical={onSelectChemical}
                />
              ) : (
                <ShowTableItem
                  key={item.id}
                  item={item}
                  totalTanks={totalTanks}
                  machineryId={selectedMachinery}
                  onEdit={onEditChemicalItem}
                  onDelete={deleteChemicalItem}
                  interventionType={values.interventionType}
                />
              );
            })}
          </Table.Body>
        </Table>
      </Container>
      {!addChemicalSelection && !(selectedChemicals.length === 0) && (
        <div className={styles.buttonAdd}>
          <Button
            className="color-green"
            onClick={() => setAddChemicalSelection(true)}
          >
            Add
          </Button>
        </div>
      )}
    </>
  );
};

SelectChemicalsForm.propTypes = {
  onSelectChemical: PropTypes.func,
  selectedChemicals: PropTypes.array,
  chemicalToAdd: PropTypes.object,
  actions: PropTypes.object,
  deleteChemicalItem: PropTypes.func,
  addChemicalSelection: PropTypes.bool,
  totalTanks: PropTypes.number,
  showArchived: PropTypes.bool
};

ShowTableItem.propTypes = {
  selectedMachinery: PropTypes.number,
  item: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  totalTanks: PropTypes.number
};

export default SelectChemicalsForm;
